import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CartItem from "../../components/Cart/CartItem";
import { getItemAvailability } from "../../lib/hours";
import {
  addItemWithOptions,
  addItem,
  removeItem,
  setOrderTime,
} from "../../redux/actions/order";

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { venueId },
    },
    id: itemId,
  } = ownProps;

  const {
    venues: {
      data: { [venueId]: venue },
    },
    order: { location },
  } = state;

  const item = venue.menu.items[itemId];

  const itemAvailability = getItemAvailability(
    venue.openingHours,
    item,
    false,
    location,
    moment.tz(venue.openingHours.timeZone).format("HHmm")
  );
  return {
    trackingVenueId: venueId,
    trackingVenueName: venue.strings[venue.defaultLocale][venueId],
    trackingItemName: venue.strings[venue.defaultLocale][ownProps.id],
    currency: venue.currency,
    itemAvailability,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    match: {
      params: { venueId },
    },
    id: itemId,
    rawOrderOptions: orderOptions,
    orderPositionId: cartItemId,
    note,
  } = ownProps;

  return {
    increaseAmount: () => {
      if (orderOptions) {
        dispatch(addItemWithOptions(venueId, itemId, orderOptions, note));
      } else {
        dispatch(addItem(venueId, itemId, note));
      }
    },
    decreaseAmount: () => {
      dispatch(removeItem(cartItemId));
      dispatch(setOrderTime(""));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CartItem)
);
