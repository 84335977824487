import React from "react";
import { THEME } from "../theme";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Header from "./Header";
import { GoogleMaps, ArrowLeft } from "mdi-material-ui";
import BurgerMenu from "./BurgerMenu";
import OrderLocationSwitcherBar from "../containers/OrderLocationSwitcherBar";
import { IconButton } from "@material-ui/core";
import routes from "../routes";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

const styles = {
  root: {
    minHeight: 200,
    maxHeight: 200,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative",
  },
  iconButton: {
    color: THEME.PRIMARY_BUTTON_ICON,
    backgroundColor: "rgba(44, 47, 58, 0.3)",
    "&:hover": {
      backgroundColor: "rgba(44, 47, 58, 0.7)",
    },
    borderRadius: 0,
    fontSize: "clamp(0.7rem, 3vmin, 1rem)",
  },
  locationSwitcherBarContainer: {
    position: "absolute",
    bottom: 0,
    marginBottom: 5,
  },
  venueAddress: {
    fontSize: 12,
    fontFamily: "Open Sans",
    color: THEME.WHITE_TEXT_COLOR,
    marginTop: 5,
  },
  mapLink: {
    color: THEME.WHITE_TEXT_COLOR,
    borderRadius: 0,
    zIndex: 2,
    fontSize: "clamp(0.7rem, 3vmin, 1rem)",
    display: "flex",
    flexDirection: "row",
    textDecoration: "none",
  },
};

const VenueMenuHeader = ({
  classes,
  venueId,
  tableId,
  venueCoordinates,
  heroImage,
  venueAddress,
  startScreen,
  strings,
}) => {
  let googleMapUrl = "";
  if (venueCoordinates) {
    const { latitude, longitude } = venueCoordinates;
    googleMapUrl = `https://www.google.com/maps/search/?api=1&query=${`${latitude},${longitude}`}&place=${venueAddress}`;
  } else if (venueAddress) {
    googleMapUrl = `https://www.google.com/maps/search/?api=1&query=${venueAddress}`;
  }

  const heroBackground = heroImage
    ? `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6) 40%), center/cover url(${heroImage})`
    : THEME.BUTTON_BACKGROUND_COLOR;
  return (
    <div className={classes.root} style={{ background: heroBackground }}>
      {startScreen && tableId && (
        <div style={{ position: "absolute", top: 0, left: 0 }}>
          <IconButton
            className={classes.iconButton}
            component={Link}
            to={routes.start.template(venueId)}
          >
            <ArrowLeft fontSize="small" />
          </IconButton>
        </div>
      )}
      <div style={{ position: "absolute", top: 0, right: 0 }}>
        <BurgerMenu venueId={venueId} tableId={tableId} />
      </div>
      <div>
        <Header title={strings[venueId]} />
        {!!googleMapUrl && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            className={classes.mapLink}
            href={googleMapUrl}
          >
            <GoogleMaps fontSize="small" />
            <Typography variant="body2" className={classes.venueAddress}>
              {venueAddress}
            </Typography>
          </a>
        )}
      </div>
      <div className={classes.locationSwitcherBarContainer}>
        <OrderLocationSwitcherBar />
      </div>
    </div>
  );
};

VenueMenuHeader.propTypes = {
  strings: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  venueId: PropTypes.string.isRequired,
  heroImage: PropTypes.string,
  venueAddress: PropTypes.string.isRequired,
  venueCoordinates: PropTypes.any.isRequired,
  tableId: PropTypes.string,
  startScreen: PropTypes.object,
};

VenueMenuHeader.defaultProps = {
  tableId: null,
  heroImage: "",
  startScreen: null,
};

export default withStyles(styles)(VenueMenuHeader);
