import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import InHouseCheckoutRedirect from "../../components/InHousePayment/InHouseCheckoutRedirect";
import { clearAdyenPaymentSession } from "../../redux/actions/payments";
import { posMessage as posMessageAction } from "../../redux/actions/venues";

const mapStateToProps = () => {
  return (state, ownProps) => {
    const {
      match: {
        params: { venueId },
      },
    } = ownProps;

    const { order, adyenPayment } = state;

    return {
      order,
      venueId,
      adyenPaymentState: adyenPayment,
    };
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearAdyenPaymentSession: () => dispatch(clearAdyenPaymentSession()),
    posMessage: (venueId, order) => dispatch(posMessageAction(venueId, order)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InHouseCheckoutRedirect)
);
