import { createSelector } from "reselect";
import { transformItemConfigurationToOrderOptions } from "../../lib/item-configuration";
import { calculatePrice } from "../../lib/price";
import {
  expandBenefits,
  getUsableBenefits,
} from "@orda/shared-functions/benefits";

/**
 * Memoizes the call to transform item configuration to the final order option
 * shape. The final function parameters are documented here.
 *
 * @param {object} venue
 * @param {itemId} string
 * @param {array} entries
 * @param {object} minimumOptions
 */
export const configuredOptionsSelector = createSelector(
  (venue) => venue,
  (_, itemId) => itemId,
  (_, __, entries) => entries,
  (_, __, ___, minimumOptions) => minimumOptions,
  (venue, itemId, entries, minimumOptions) =>
    transformItemConfigurationToOrderOptions(
      venue,
      itemId,
      entries,
      minimumOptions
    )
);

/**
 * Collects "used benefits" before applying configuration for the current item.
 * The final function parameters are documented here.
 *
 * @param {object} venue
 * @param {array} orderItems
 * @param {array} benefits
 * @param {array} expandableBenefits
 * @param {number} orderLocation
 */
export const preConfigurationUsedBenefitsSelectorCreator = () =>
  createSelector(
    (venue) => venue,
    (_, order) => order.items,
    (_, order) => order.feesBenefits.benefits,
    (_, order) => order.feesBenefits.expandableBenefits,
    (_, order) => order.location,
    (venue, orderItems, benefits, expandableBenefits, orderLocation) =>
      calculatePrice(
        orderItems,
        venue.menu.items,
        venue,
        orderLocation,
        null,
        benefits,
        null,
        expandableBenefits
      ).usedBenefits
  );
export const preConfigurationUsedBenefitsSelector =
  preConfigurationUsedBenefitsSelectorCreator();

/**
 * Collects "used benefits" after applying configuration for the current item.
 * The final function parameters are documented here.
 *
 * @param {object} venue
 * @param {itemId} string
 * @param {array} orderItems
 * @param {array} benefits
 * @param {array} expandableBenefits
 * @param {number} orderLocation
 */
export const postConfigurationUsedBenefitsSelector = createSelector(
  (venue) => venue,
  (_, itemId) => itemId,
  (_, __, configuredOptions) => configuredOptions,
  (_, __, ___, order) => order.items,
  (_, __, ___, order) => order.feesBenefits.benefits,
  (_, __, ___, order) => order.feesBenefits.expandableBenefits,
  (_, __, ___, order) => order.location,
  (
    venue,
    itemId,
    configuredOptions,
    orderItems,
    benefits,
    expandableBenefits,
    orderLocation
  ) =>
    calculatePrice(
      orderItems.concat([
        {
          id: "TEMP",
          itemId,
          orderOptions: configuredOptions,
        },
      ]),
      venue.menu.items,
      venue,
      orderLocation,
      null,
      benefits,
      null,
      expandableBenefits,
      null,
      true
    ).usedBenefits
);

/**
 * Collects the benefits available (which can be used) without taking the current item
 * configuration in consideration. The final function parameters are documented here.
 *
 * NOTE: the current item is added to expandedBenefits: this is for the special case of
 * expandable benefits like Dean & David where the benefit of a free box when salad is selected
 * needs the current item (the salad) with an expandable benefit to be taken into account.
 *
 * @param {itemId} string
 * @param {object} configuredOptions
 * @param {object} preConfigurationUsedBenefits
 * @param {array} orderItems
 * @param {array} benefits
 * @param {array} expandableBenefits
 * @param {number} orderLocation
 */
export const preConfigurationBenefitsSelector = createSelector(
  (itemId) => itemId,
  (_, configuredOptions) => configuredOptions,
  (_, __, preConfigurationUsedBenefits) => preConfigurationUsedBenefits,
  (_, __, ___, order) => order.items,
  (_, __, ___, order) => order.feesBenefits.benefits,
  (_, __, ___, order) => order.feesBenefits.expandableBenefits,
  (
    itemId,
    configuredOptions,
    preConfigurationUsedBenefits,
    orderItems,
    benefits,
    expandableBenefits
  ) => {
    const expandedBenefits = expandBenefits(
      expandableBenefits,
      orderItems.concat([
        {
          id: "TEMP",
          itemId,
          configuredOptions,
        },
      ])
    );
    return getUsableBenefits(
      [...benefits, ...expandedBenefits],
      preConfigurationUsedBenefits
    );
  }
);

/**
 * Collects the benefits available (which can be used) after applying the current item's
 * configured options. The final function parameters are documented here
 *
 * @param {string} itemId
 * @param {object} configuredOptions
 * @param {object} postConfigurationUsedBenefits
 * @param {array} orderItems
 * @param {array} benefits
 * @param {array} expandableBenefits
 */
export const postConfigurationBenefitsSelector = createSelector(
  (itemId) => itemId,
  (_, configuredOptions) => configuredOptions,
  (_, __, postConfigurationUsedBenefits) => postConfigurationUsedBenefits,
  (_, __, ___, order) => order.items,
  (_, __, ___, order) => order.feesBenefits.benefits,
  (_, __, ___, order) => order.feesBenefits.expandableBenefits,
  (
    itemId,
    configuredOptions,
    postConfigurationUsedBenefits,
    orderItems,
    benefits,
    expandableBenefits
  ) => {
    const expandedBenefits = expandBenefits(
      expandableBenefits,
      orderItems.concat([
        {
          id: "TEMP",
          itemId,
          orderOptions: configuredOptions,
        },
      ])
    );
    return getUsableBenefits(
      benefits.concat(expandedBenefits),
      postConfigurationUsedBenefits
    );
  }
);
