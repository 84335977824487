import {
  expandBenefits,
  getUsableBenefits,
} from "@orda/shared-functions/benefits";

import { transformItemConfigurationToOrderOptions } from "../../lib/item-configuration";
import { calculatePrice } from "../../lib/price";
import { createSelector } from "reselect";

export function createBenefitsSelector(dontConcatUsedBenefits) {
  const orderOptionsSelector = createSelector(
    (venue) => venue,
    (_, itemId) => itemId,
    (_, __, state) => state.itemConfiguration.entries,
    (_, __, state) => state.itemConfiguration.minimumOptions,
    (_, __, ___, path) => path,
    (venue, itemId, entries, minimumOptions, skipPath) =>
      transformItemConfigurationToOrderOptions(
        venue,
        itemId,
        entries,
        minimumOptions,
        skipPath
      )
  );

  const usedOrderBenefitsSelector = createSelector(
    (venue) => venue,
    (_, itemId) => itemId,
    (_, __, state) => state.order.items,
    (_, __, state) => state.order.feesBenefits.benefits,
    (_, __, state) => state.order.feesBenefits.expandableBenefits,
    (_, __, state) => state.order.location,
    orderOptionsSelector,
    (
      venue,
      itemId,
      orderItems,
      benefits,
      expandableBenefits,
      orderLocation,
      orderOptions
    ) =>
      calculatePrice(
        dontConcatUsedBenefits
          ? orderItems
          : orderItems.concat([
              {
                id: "TEMP",
                itemId,
                orderOptions,
              },
            ]),
        venue.menu.items,
        venue,
        orderLocation,
        null,
        benefits,
        null,
        expandableBenefits,
        null,
        true
      ).usedBenefits
  );

  return createSelector(
    (venue) => venue,
    (_, itemId) => itemId,
    (_, __, state) => state.order.items,
    (_, __, state) => state.order.feesBenefits.benefits,
    (_, __, state) => state.order.feesBenefits.expandableBenefits,
    orderOptionsSelector,
    usedOrderBenefitsSelector,
    (
      _,
      itemId,
      orderItems,
      benefits,
      expandableBenefits,
      orderOptions,
      usedOrderBenefits
    ) => {
      const expandedBenefits = expandBenefits(
        expandableBenefits,
        orderItems.concat([
          {
            id: "TEMP",
            itemId,
            orderOptions,
          },
        ])
      );
      return getUsableBenefits(
        benefits.concat(expandedBenefits),
        usedOrderBenefits
      );
    }
  );
}
