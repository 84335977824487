import { getDistance } from "geolib";

export const loadScript = (url, callback) => {
  const script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = () => {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  script.id = "google-maps-ip";
  document.getElementsByTagName("head")[0].appendChild(script);
};

export function handleScriptLoad(
  autoCompleteInitial,
  updateQuery,
  autoCompleteRef,
  address,
  handlePlaceSelect,
  countryCode = "de"
) {
  // eslint-disable-next-line no-param-reassign
  autoCompleteInitial = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    {
      componentRestrictions: { country: countryCode.toLowerCase() },
      fields: [
        "address_components",
        "geometry",
        "icon",
        "name",
        "formatted_address",
      ],
      types: ["address"],
    }
  );
  autoCompleteInitial.setFields(["address_components", "formatted_address"]);
  autoCompleteInitial.addListener("place_changed", () =>
    handlePlaceSelect(autoCompleteInitial.getPlace(), updateQuery, address)
  );
}

export function checkDeliveryAddressAvailability(
  address,
  successHandle,
  errorHandle
) {
  let isWithinRange = true;

  const geocoder = new window.google.maps.Geocoder();
  const deliveryAddress = `${address.deliveryStreetName}, ${address.deliveryStreetNumber}, ${address.deliveryPostalCode}, ${address.deliveryCity}`;
  geocoder.geocode(
    {
      address: deliveryAddress,
    },
    (results) => {
      if (results && results.length > 0) {
        const coordinates = {
          latitude: results[0].geometry.location.lat(),
          longitude: results[0].geometry.location.lng(),
        };
        const distance = getDistance(address.venueCoordinates, coordinates);
        if (
          address.postalCodesWhitelist &&
          address.postalCodesWhitelist.length > 0
        ) {
          isWithinRange = Boolean(
            address.postalCodesWhitelist.find(
              (postalCodeConfig) =>
                postalCodeConfig.postalCode === address.deliveryPostalCode
            )
          );
        }
        if (!isWithinRange || distance > address.deliveryRange) {
          errorHandle();
        } else {
          successHandle();
        }
      } else {
        errorHandle();
      }
    }
  );
}

export function checkCoordinatesAvailability(
  handlePlaceSelect,
  updateQuery,
  address,
  errorHandle
) {
  navigator.geolocation.getCurrentPosition((position) => {
    const latlng = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: latlng }, (results) => {
      if (results && results.length > 0) {
        handlePlaceSelect(results[0], updateQuery, address);
      } else {
        errorHandle();
      }
    });
  });
}
