import { combineReducers } from "redux";
import auth, { initialState as authState } from "./auth";
import errors, { initialState as errorsState } from "./errors";
import venues, { initialState as venuesState } from "./venues";
import order, { initialState as orderState } from "./order";
import user, { initialState as userState } from "./user";
import itemConfiguration, {
  initialState as itemConfigurationState,
} from "./item-configuration";
import ui, { initialState as uiState } from "./ui";
import adyenPayment, { initialState as adyenPaymentState } from "./adyen";
import { connectRouter } from "connected-react-router";
import history from "../../history";

export const initialState = {
  auth: authState,
  venues: venuesState,
  order: orderState,
  itemConfiguration: itemConfigurationState,
  errors: errorsState,
  user: userState,
  ui: uiState,
  adyenPayment: adyenPaymentState,
};

const rootReducer = combineReducers({
  router: connectRouter(history),
  auth,
  venues,
  order,
  itemConfiguration,
  errors,
  user,
  ui,
  adyenPayment,
});

export default rootReducer;
