import React from "react";
import PropTypes from "prop-types";
import { THEME } from "../../theme";

const CardOnDelivery = ({ fill }) => (
  <svg height="100%" width="100%" fill={fill} viewBox="0 0 128 128">
    <g fill={fill}>
      <path d="m13.574 37.043 20.172-29.09-5.8164-4.0742c-0.77734-0.58203-1.9414-0.38672-2.7188 0.58203l-18.035 25.602c-0.58203 0.96875-0.38672 2.1328 0.58203 2.9062z" />
      <path d="m52.945 56.629c-0.77734 0.38672-1.1641 1.1641-0.77734 2.1328 0.38672 0.77734 1.3594 1.1641 2.1328 0.77734l8.9219-4.0742c0.38672-0.19531 0.77734-0.58203 0.77734-0.96875l-1.9375-1.3594-1.9414 0.19531z" />
      <path d="m41.309 51.586c0.38672 0.38672 0.96875 0.38672 1.5508 0.38672 29.672-4.6562 25.211-4.0742 25.406-4.0742 2.3281-0.58203 3.2969-1.7461 3.2969-3.6836s-1.7461-3.2969-3.2969-3.4922l-20.168-1.7461c-1.1641 0-1.9375-0.96875-1.7461-2.1328 0.19531-1.1641 0.96875-1.9375 2.1328-1.7461l0.96875 0.19531 14.352-20.559-1.3594-2.5195c-0.38672-0.77734-1.1641-1.1641-1.9375-1.1641l-25.016 1.5547-16.484 23.469z" />
      <path d="m68.656 51.977-0.96875 0.19531 31.031 21.723c0.96875 0.58203 2.1328 0.38672 2.7148-0.38672l11.055-15.711-49.652-34.91-8.9219 12.801 14.738 1.3594c3.6836 0.38672 6.5938 3.4922 6.7891 7.1758 0.19531 4.2617-2.5195 6.7852-6.7852 7.7539zm23.852 6.0117c0.58203-0.96875 1.9375-1.1641 2.7148-0.38672l4.4609 3.1016c0.96875 0.58203 1.1641 1.9375 0.58203 2.7148-0.58203 0.96875-1.9375 1.1641-2.7148 0.58203l-4.4609-3.2969c-0.96875-0.58203-1.1641-1.9414-0.58203-2.7148zm-11.828-8.3398c0.58203-0.96875 1.9375-1.1641 2.7148-0.38672l4.4609 3.1016c0.96875 0.58203 1.1641 1.9375 0.58203 2.7148-0.58203 0.96875-1.7461 1.1641-2.7148 0.58203l-4.4609-3.2969c-0.97266-0.58203-1.1641-1.7461-0.58203-2.7148z" />
      <path d="m117.14 50.812-49.648-34.715-2.3281 3.4922 49.648 34.715c0-0.19531 2.5195-3.6875 2.3281-3.4922z" />
      <path d="m120.24 46.352c0.58203-0.96875 0.38672-2.1328-0.58203-2.7148l-46.352-32.387c-0.96875-0.58203-2.1328-0.38672-2.7148 0.58203l-0.77734 1.1602 49.648 34.715z" />
      <path d="m125.29 87.078c-1.9375-3.4922-6.0117-5.043-9.8906-3.8789l-21.723 6.5938c-2.1328 4.2656-6.207 8.7266-13.574 9.8906-7.3711 1.5508-16.289 3.8789-17.648 5.043-1.5508 1.9375-4.6562-0.58203-2.9102-2.5195 1.7461-2.1328 11.832-4.4609 19.977-6.207 4.8477-0.58203 7.7578-3.1016 9.5039-5.625-3.8789-3.8789-12.023-3.6836-12.992-3.6836-10.668 0.19531-21.336-3.2969-21.336-3.2969-10.668-3.2969-19.977 3.8789-20.363 4.2656l-0.19531 0.19531c-4.4609 4.6562-23.66 11.832-30.836 14.352-0.77734 0-1.1641 0.58203-1.3594 1.3555-0.19531 0.58203 0 1.3594 0.58203 1.9375l19.977 18.617c0.38672 0.38672 0.77734 0.58203 1.3594 0.58203s0.96875-0.19531 1.3594-0.58203c9.6953-10.277 42.859-7.7578 52.75-6.7891 8.1445 0.77734 11.055-3.8789 44.605-20.945 3.2969-1.9336 4.4609-6.0078 2.7148-9.3047z" />
    </g>
  </svg>
);

CardOnDelivery.propTypes = {
  fill: PropTypes.string,
};
CardOnDelivery.defaultProps = {
  fill: THEME.WHITE_TEXT_COLOR,
};

export default CardOnDelivery;
