import {
  getUsableBenefits,
  updateUsedBenefits,
} from "@orda/shared-functions/benefits";
import { VENUE_LOCATION_TO_GO } from "@orda/shared-constants/order-locations";
import Price from "../components/Price";
import { buildMinimumOptions } from "../lib/options";
import { calculateItemBenefits } from "@orda/shared-functions-js/lib/price";
import { calculatePriceRaw as calculateOptionsPrice } from "@orda/shared-functions-js/lib/options";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { withRouter } from "react-router-dom";
import { preConfigurationUsedBenefitsSelectorCreator } from "../redux/selectors/item-configuration";

const mapStateToProps = () => {
  const preConfigurationUsedBenefitsSelector =
    preConfigurationUsedBenefitsSelectorCreator();

  const usableBenefitsSelector = createSelector(
    (benefits) => benefits,
    (_, preConfigUsedBenefits) => preConfigUsedBenefits,
    (benefits, preConfigUsedBenefits) =>
      getUsableBenefits(benefits, preConfigUsedBenefits)
  );

  const minimumOptionsPriceBeforeBenefitsSelector = createSelector(
    (venue) => venue,
    (_, itemId) => itemId,
    (_, __, orderLocation) => orderLocation,
    (venue, itemId, orderLocation) => {
      const minimumOptions = buildMinimumOptions(venue, itemId);
      if (!minimumOptions) {
        return {
          minimumOptions,
          price: 0,
        };
      }

      return {
        minimumOptions,
        price: calculateOptionsPrice(
          minimumOptions,
          venue.menu.items[itemId].options,
          venue.menu.options,
          venue.menu.items,
          [],
          {},
          orderLocation
        ),
      };
    }
  );

  return (state, ownProps) => {
    const {
      match: {
        params: { venueId },
      },
      itemId,
      benefits,
      usedBenefits: propsUsedBenefits,
    } = ownProps;

    const {
      venues: {
        data: { [venueId]: venue },
      },
      order,
    } = state;

    const item =
      venue.menu &&
      ((venue.menu.options && venue.menu.options[itemId]) ||
        (venue.menu.items && venue.menu.items[itemId]));
    if (!item) {
      return {
        currency: venue.currency,
      };
    }
    const priceBeforeBenefits =
      (order.orderLocation === VENUE_LOCATION_TO_GO
        ? item.priceToGo || item.price
        : item.price) || 0;

    let usableBenefits = benefits;
    if (!usableBenefits) {
      const preConfigurationUsedBenefits = preConfigurationUsedBenefitsSelector(
        venue,
        order
      );
      usableBenefits = usableBenefitsSelector(
        order.feesBenefits.benefits,
        preConfigurationUsedBenefits
      );
    }

    const usedBenefits = propsUsedBenefits || {};

    const { minimumOptions, price: minimumOptionsPriceBeforeBenefits } =
      minimumOptionsPriceBeforeBenefitsSelector(venue, itemId, order.location);

    let minimumOptionsPriceAfterBenefits = 0;
    if (minimumOptionsPriceBeforeBenefits > 0) {
      minimumOptionsPriceAfterBenefits = calculateOptionsPrice(
        minimumOptions,
        item.options,
        venue.menu.options,
        venue.menu.items,
        getUsableBenefits(usableBenefits, usedBenefits),
        usedBenefits,
        order.location
      );
    }

    let benefit;
    let calculatedAmount = 0;
    const amount = ownProps.amount || 1;
    for (let i = 0; i < amount; i++) {
      usableBenefits = getUsableBenefits(usableBenefits, usedBenefits);

      const calculatedResult = calculateItemBenefits(
        ownProps.itemId,
        priceBeforeBenefits,
        usableBenefits
      );
      benefit = calculatedResult.benefit;
      calculatedAmount = calculatedResult.amount;

      if (ownProps.usedBenefits) {
        updateUsedBenefits(usedBenefits, benefit);
      }
    }

    const priceAfterBenefitsRaw = Math.max(
      Math.max(priceBeforeBenefits, 0) - Math.max(calculatedAmount, 0),
      0
    );

    return {
      currency: venue.currency,
      priceBeforeBenefits,
      minimumOptionsPriceBeforeBenefits,
      minimumOptionsPriceAfterBenefits,
      priceAfterBenefitsRaw,
      usedBenefit: benefit,
    };
  };
};

export default withRouter(connect(mapStateToProps)(Price));
