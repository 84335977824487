import { CLIENTVERSION } from "../config";
import i18next, { getUsableLocale } from "./i18next";
import { getSessionId } from "./analytics";

export async function transport(params, token = null) {
  const options = {
    method: params.method,
    body: params.body,
    headers: {
      "Accept-Language": getUsableLocale(),
      "Content-Type": "application/json",
      clientversion: CLIENTVERSION,
      "Amplitude-Session": getSessionId(),
    },
  };

  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }

  let response;
  try {
    response = await global.fetch(params.url, options);
  } catch (error) {
    throw new Error(i18next.t("errors:network"));
  }

  if (!response.ok) {
    let error;
    try {
      error = await response.json();
    } catch (innerError) {
      throw new Error(i18next.t("errors:network"));
    }
    throw error;
  }

  if (response.status !== 204) {
    try {
      const result = await response.json();
      return result;
    } catch (error) {
      throw new Error(i18next.t("errors:network"));
    }
  }

  return response;
}
