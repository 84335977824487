export default {
  ADD_CREDIT_CARD: {
    REQUEST: "ADD_CREDIT_CARD.REQUEST",
    SUCCESS: "ADD_CREDIT_CARD.SUCCESS",
    FAILURE: "ADD_CREDIT_CARD.FAILURE",
  },
  ADD_PAYMENT_OPTION: {
    REQUEST: "ADD_PAYMENT_OPTION.REQUEST",
    SUCCESS: "ADD_PAYMENT_OPTION.SUCCESS",
    FAILURE: "ADD_PAYMENT_OPTION.FAILURE",
  },
  ADD_PAYPAL_CHECKOUT: "ADD_PAYPAL_CHECKOUT",
  SET_PAYMENT_REQUEST: "SET_PAYMENT_REQUEST",
  SET_GOOGLE_PAY_TOKEN: "SET_GOOGLE_PAY_TOKEN",
  SET_APPLE_PAY_TOKEN: "SET_APPLE_PAY_TOKEN",
  ADYEN: {
    CREATE_PAYMENT_SESSION: {
      REQUEST: "CREATE_PAYMENT_SESSION.REQUEST",
      SUCCESS: "CREATE_PAYMENT_SESSION.SUCCESS",
      FAILURE: "CREATE_PAYMENT_SESSION.FAILURE",
    },
    CLEAR_PAYMENT_SESSION: "CLEAR_PAYMENT_SESSION",
  },
};
