import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CheckoutPaymentOptions from "../components/CheckoutPaymentOptions";
import { get } from "lodash";
import { groupedItemsSelector } from "../redux/selectors/item-group";
import { getUsableLocale } from "../lib/i18next";
import { venueStringsSelector } from "../redux/selectors/venue";
import { setPaymentRequest } from "../redux/actions/payments";

const mapStateToProps = () => {
  return (state, ownProps) => {
    const { order } = state;
    const {
      location: { search },
    } = ownProps;
    const searchParams = new URLSearchParams(search);
    const venueId = searchParams.get("cartVenueId");
    const {
      venues: {
        data: { [venueId]: venue },
      },
      user: { paymentRequest },
    } = state;
    const locale = getUsableLocale();
    const venueStrings = venueStringsSelector(venue, locale);
    const { totalPrice } = groupedItemsSelector(venue, venueStrings, order);

    return {
      orderLocation: order.location,
      subTotal: totalPrice,
      currency: (venue.currency && venue.currency.toLowerCase()) || "eur",
      label: get(order, ["preparedOrder", "paypalShortDescription"], "Total"),
      paymentRequest,
      countryCode: get(venue, "countryCode", "DE") || "DE",
    };
  };
};

const mapDispatchToProps = (dispatch) => {
  return { setPaymentRequest: (pr) => dispatch(setPaymentRequest(pr)) };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CheckoutPaymentOptions)
);
