import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { Button, withStyles } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
  validateEmail,
  validateNumber,
  validatePhoneNumber,
} from "../../util/validation";
import { GOOGLE_MAPS_API_URL } from "../../config";
import {
  checkDeliveryAddressAvailability,
  loadScript,
} from "../../lib/scripts";
import { THEME } from "../../theme";

const styles = {
  root: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
  },
  personalInformationContainer: {
    display: "flex",
    flexDirection: "column",
  },
  flexableWrap: {
    flexWrap: "nowrap",
    gap: "10px",
    // eslint-disable-next-line no-useless-computed-key
    ["@media (max-width: 960px)"]: {
      gap: "0px",
      flexWrap: "wrap",
    },
  },
  button: {
    height: 55,
    margin: "10px 0px",
  },
  disabledSubmitButton: {
    backgroundColor: "#BDBDBD",
  },
  text: {
    marginBottom: "20px",
  },
  confirmButton: {
    height: 45,
    marginTop: 10,
    color: THEME.WHITE_TEXT_COLOR,
    backgroundColor: THEME.BUTTON_BACKGROUND_COLOR,
    "&:hover": {
      backgroundColor: THEME.BUTTON_BACKGROUND_COLOR,
    },
  },
  addressForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
};

// street, number, postalcode, city

const AddressForm = ({
  t,
  classes,
  setDeliveryStreetName,
  setUserName,
  setUserEmail,
  setDeliveryAdditionalInformation,
  setDeliveryCity,
  setDeliveryPostalCode,
  setDeliveryStreetNumber,
  setPhoneNumber,
  setTriggerValidateAddressForm,
  triggerValidateAddressForm,
  triggerSubmitAddressForm,
  deliveryStreetName,
  deliveryStreetNumber,
  deliveryAdditionalInformation,
  deliveryCity,
  deliveryPostalCode,
  userName,
  userEmail,
  phoneNumber,
  venueCoordinates,
  deliveryRange,
  errorOutOfrange,
  setErrorOutOfRange,
  postalCodesWhitelist,
  submitOrder,
}) => {
  const [triggerValidateDeliveryAddress, setTriggerValidateDeliveryAddress] =
    useState(false);
  const canSubmit =
    validateNumber(deliveryStreetNumber) &&
    validateEmail(userEmail) &&
    !!userName &&
    validatePhoneNumber(phoneNumber) &&
    !!deliveryCity &&
    !!deliveryStreetName &&
    validateNumber(deliveryPostalCode);

  // const submitButtonStyle =
  //   canSubmit && !processing ? {} : styles.disabledSubmitButton;

  useEffect(() => {
    if (triggerSubmitAddressForm && !canSubmit) {
      setTriggerValidateAddressForm(true);
    } else if (triggerSubmitAddressForm && canSubmit) {
      loadScript(GOOGLE_MAPS_API_URL, () =>
        checkDeliveryAddressAvailability(
          {
            deliveryStreetName,
            deliveryStreetNumber,
            deliveryAdditionalInformation,
            deliveryCity,
            deliveryPostalCode,
            venueCoordinates,
            deliveryRange,
            postalCodesWhitelist,
          },
          submitOrder,
          () => setErrorOutOfRange(true)
        )
      );
    } else if (triggerValidateDeliveryAddress) {
      loadScript(GOOGLE_MAPS_API_URL, () =>
        checkDeliveryAddressAvailability(
          {
            deliveryStreetName,
            deliveryStreetNumber,
            deliveryAdditionalInformation,
            deliveryCity,
            deliveryPostalCode,
            venueCoordinates,
            deliveryRange,
            postalCodesWhitelist,
          },
          () => {
            setTriggerValidateDeliveryAddress(false);
            setErrorOutOfRange(false);
          },
          () => {
            setTriggerValidateDeliveryAddress(false);
            setErrorOutOfRange(true);
          }
        )
      );
    }
    return () =>
      !!document.getElementById("google-maps-ip") &&
      document.getElementById("google-maps-ip").remove();
  }, [triggerSubmitAddressForm, triggerValidateDeliveryAddress]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  };
  return (
    <>
      <Typography variant="body2" className={classes.text}>
        {t("enterInfo")}
      </Typography>
      <form onSubmit={handleSubmit} className={classes.addressForm}>
        <Grid container className={`${classes.root} ${classes.flexableWrap}`}>
          <Grid xs={12} md={3} container>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label={t("emailLabel")}
                name="email"
                autoComplete="email"
                fullWidth
                margin="dense"
                required
                type="email"
                helperText={
                  triggerValidateAddressForm && !validateEmail(userEmail)
                    ? t("validEmailErrorLabel")
                    : ""
                }
                error={triggerValidateAddressForm && !validateEmail(userEmail)}
                value={userEmail}
                onChange={(e) => {
                  setUserEmail(e.target.value);
                }}
                onBlur={(e) => {
                  setUserEmail(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label={t("nameLabel")}
                fullWidth
                name="name"
                autoComplete="name"
                margin="dense"
                variant="outlined"
                required
                helperText={
                  triggerValidateAddressForm && !userName
                    ? t("requiredStringErrorLabel")
                    : ""
                }
                error={triggerValidateAddressForm && !userName}
                value={userName}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
                onBlur={(e) => {
                  setUserName(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid xs={12} md={9} container className={classes.flexableWrap}>
            <Grid xs={12} md={8} container>
              <Grid container wrap="nowrap" className={classes.flexableWrap}>
                <Grid item xs={12} md={8}>
                  <TextField
                    id="outlined-basic"
                    label={t("streetLabel")}
                    name="street"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    required
                    helperText={
                      triggerValidateAddressForm && !deliveryStreetName
                        ? t("requiredStringErrorLabel")
                        : ""
                    }
                    error={triggerValidateAddressForm && !deliveryStreetName}
                    value={deliveryStreetName}
                    onChange={(e) => {
                      setDeliveryStreetName(e.target.value);
                    }}
                    onBlur={(e) => {
                      if (deliveryPostalCode && deliveryStreetNumber) {
                        setTriggerValidateDeliveryAddress(true);
                      }
                      setDeliveryStreetName(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="outlined-number"
                    label={t("streetNumberLabel")}
                    name="street-number"
                    variant="outlined"
                    type="number"
                    fullWidth
                    margin="dense"
                    required
                    helperText={
                      triggerValidateAddressForm &&
                      !validateNumber(deliveryStreetNumber)
                        ? t("requiredNumberErrorLabel")
                        : ""
                    }
                    error={
                      triggerValidateAddressForm &&
                      !validateNumber(deliveryStreetNumber)
                    }
                    value={deliveryStreetNumber}
                    onChange={(e) => {
                      setDeliveryStreetNumber(e.target.value);
                    }}
                    onBlur={(e) => {
                      if (deliveryPostalCode && deliveryStreetName) {
                        setTriggerValidateDeliveryAddress(true);
                      }
                      setDeliveryStreetNumber(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container wrap="nowrap" className={classes.flexableWrap}>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="outlined-number"
                    label={t("postalCodeLabel")}
                    name="postal-code"
                    fullWidth
                    margin="dense"
                    type="number"
                    variant="outlined"
                    required
                    helperText={
                      triggerValidateAddressForm &&
                      !validateNumber(deliveryPostalCode)
                        ? t("requiredNumberErrorLabel")
                        : ""
                    }
                    error={
                      triggerValidateAddressForm &&
                      !validateNumber(deliveryPostalCode)
                    }
                    value={deliveryPostalCode}
                    onChange={(e) => {
                      setDeliveryPostalCode(e.target.value);
                    }}
                    onBlur={(e) => {
                      if (deliveryStreetNumber && deliveryStreetName) {
                        setTriggerValidateDeliveryAddress(true);
                      }
                      setDeliveryPostalCode(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label={t("cityLabel")}
                    name="city"
                    fullWidth
                    margin="dense"
                    required
                    helperText={
                      triggerValidateAddressForm && !deliveryCity
                        ? t("requiredStringErrorLabel")
                        : ""
                    }
                    error={triggerValidateAddressForm && !deliveryCity}
                    value={deliveryCity}
                    onChange={(e) => {
                      setDeliveryCity(e.target.value);
                    }}
                    onBlur={(e) => {
                      setDeliveryCity(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="outlined-basic"
                label={t("phoneNumberLabel")}
                name="phone"
                type="tel"
                autoComplete="tel"
                fullWidth
                margin="dense"
                variant="outlined"
                required
                helperText={
                  triggerValidateAddressForm &&
                  !validatePhoneNumber(phoneNumber)
                    ? t("validPhoneNumberErrorLabel")
                    : ""
                }
                error={
                  triggerValidateAddressForm &&
                  !validatePhoneNumber(phoneNumber)
                }
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
                onBlur={(e) => {
                  setPhoneNumber(e.target.value);
                }}
              />
              <TextField
                id="outlined-basic"
                label={t("additionalInfoLabel")}
                fullWidth
                margin="dense"
                variant="outlined"
                value={deliveryAdditionalInformation}
                onChange={(e) => {
                  setDeliveryAdditionalInformation(e.target.value);
                }}
                onBlur={(e) => {
                  setDeliveryAdditionalInformation(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          className={classes.confirmButton}
          onClick={() => {
            setTriggerValidateDeliveryAddress(true);
          }}
        >
          {t("confirmAddress")}
        </Button>
      </form>
      {!!errorOutOfrange && (
        <Typography
          variant="body2"
          style={{
            color: THEME.ACCENT_RED,
            textAlign: "center",
            paddingTop: "1rem",
          }}
        >
          {t("addressOutOfRange")}
        </Typography>
      )}
    </>
  );
};

AddressForm.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  setDeliveryStreetName: PropTypes.func.isRequired,
  setUserName: PropTypes.func.isRequired,
  setUserEmail: PropTypes.func.isRequired,
  setDeliveryAdditionalInformation: PropTypes.func.isRequired,
  setDeliveryCity: PropTypes.func.isRequired,
  setDeliveryPostalCode: PropTypes.func.isRequired,
  setDeliveryStreetNumber: PropTypes.func.isRequired,
  setPhoneNumber: PropTypes.func.isRequired,
  setTriggerValidateAddressForm: PropTypes.any.isRequired,
  submitOrder: PropTypes.func.isRequired,
  triggerSubmitAddressForm: PropTypes.bool.isRequired,
  triggerValidateAddressForm: PropTypes.bool.isRequired,
  deliveryStreetName: PropTypes.string.isRequired,
  deliveryStreetNumber: PropTypes.number.isRequired,
  deliveryCity: PropTypes.string.isRequired,
  deliveryPostalCode: PropTypes.number.isRequired,
  deliveryAdditionalInformation: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  venueCoordinates: PropTypes.any.isRequired,
  postalCodesWhitelist: PropTypes.any.isRequired,
  deliveryRange: PropTypes.number.isRequired,
  errorOutOfrange: PropTypes.any.isRequired,
  setErrorOutOfRange: PropTypes.any.isRequired,
};

export default withTranslation("addressForm")(withStyles(styles)(AddressForm));
