import * as types from "../types";

export const login = () => ({
  type: types.auth.LOGIN.REQUEST,
});

export const loginSuccess = () => ({
  type: types.auth.LOGIN.SUCCESS,
});

export const loginFailure = (error) => ({
  type: types.auth.LOGIN.FAILURE,
  error,
});

export const logout = () => ({
  type: types.auth.LOGOUT.REQUEST,
});

export const logoutSuccess = () => ({
  type: types.auth.LOGOUT.SUCCESS,
});

export const logoutFailure = (error) => ({
  type: types.auth.LOGOUT.FAILURE,
  error,
});

export const syncFirebaseUser = (user) => ({
  type: types.auth.SYNC_FIREBASE_USER,
  user,
});
