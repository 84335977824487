/* eslint no-param-reassign: 0 */
import * as types from "../types";
import produce from "immer";

export const initialState = {
  requests: {
    getVenue: {},
    posMessage: {},
  },
  data: {},
};

export default function venuesReducer(state = initialState, action) {
  switch (action.type) {
    case types.venues.GET_VENUE.REQUEST:
      return produce(state, (newState) => {
        if (!newState.requests.getVenue[action.venueId]) {
          newState.requests.getVenue[action.venueId] = {};
        }
        newState.requests.getVenue[action.venueId].processing = true;
        newState.requests.getVenue[action.venueId].error = null;
      });
    case types.venues.GET_VENUE.SUCCESS:
      return produce(state, (newState) => {
        newState.requests.getVenue[action.venueId].processing = false;
        newState.data[action.venueId] = action.venue;
      });
    case types.venues.GET_VENUE.FAILURE:
      return produce(state, (newState) => {
        newState.requests.getVenue[action.venueId].processing = false;
        newState.requests.getVenue[action.venueId].error = action.error;
      });
    case types.venues.POS_MESSAGE.REQUEST:
      return produce(state, (newState) => {
        newState.requests.posMessage.processing = true;
        newState.requests.posMessage.error = null;
        newState.requests.posMessage.success = false;
      });
    case types.venues.POS_MESSAGE.SUCCESS:
      return produce(state, (newState) => {
        newState.requests.posMessage.processing = false;
        newState.requests.posMessage.success = true;
        newState.requests.posMessage.result = action.result;
        newState.requests.posMessage.error = null;
      });
    case types.venues.POS_MESSAGE.FAILURE:
      return produce(state, (newState) => {
        newState.requests.posMessage.processing = false;
        newState.requests.posMessage.error = action.error;
        newState.requests.posMessage.success = false;
      });
    case types.venues.POS_MESSAGE.RESET:
      return produce(state, (newState) => {
        newState.requests.posMessage = {};
      });
    default:
      return state;
  }
}
