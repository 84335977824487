import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";

import StripeInput from "./StripeInput";
// import Errors from "./Errors";

class StripElementWrapper extends React.PureComponent {
  state = {
    focused: false,
    empty: true,
    error: null,
  };

  handleBlur = () => {
    this.setState({ focused: false });
  };

  handleFocus = () => {
    this.setState({ focused: true });
  };

  handleChange = (changeObj) => {
    this.setState({
      error: changeObj.error,
      empty: changeObj.empty,
    });
  };

  render() {
    const { component, label, placeholder } = this.props;
    const { focused, empty, error } = this.state;

    return (
      <div>
        <FormControl variant="outlined" fullWidth>
          <InputLabel
            ref={(ref) => {
              // eslint-disable-next-line react/no-find-dom-node
              this.labelRef = ReactDOM.findDOMNode(ref);
            }}
            focused={focused}
            shrink={focused || !empty}
            error={!!error}
          >
            {label}
          </InputLabel>
          <OutlinedInput
            fullWidth
            notched={!empty || focused}
            placeholder={placeholder}
            inputComponent={StripeInput}
            inputProps={{
              component,
            }}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            labelWidth={this.labelRef ? this.labelRef.offsetWidth : 0}
            style={{ height: 60, fontFamily: "Open Sans" }}
            error={!!error}
          />
          {error && (
            <FormHelperText error={!!error} id="component-error-text">
              {error.message}
            </FormHelperText>
          )}
        </FormControl>
      </div>
    );
  }
}

StripElementWrapper.propTypes = {
  component: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default StripElementWrapper;
