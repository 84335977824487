import {
  calculateFees,
  calculateItemBenefits,
  calculateSumBenefits,
} from "@orda/shared-functions-js/lib/price";
import {
  expandBenefitsByIndex,
  getUsableBenefits,
  mergeUsedBenefits,
  updateUsedBenefits,
} from "@orda/shared-functions/benefits";
import { VENUE_LOCATION_TO_GO } from "@orda/shared-constants/order-locations";
import {
  PRICE_DISPLAY_DEFAULT,
  PRICE_DISPLAY_WITHOUT_PREFIX,
} from "@orda/shared-constants-js/price-display";

import { calculatePrice as calculateOptionsPrice } from "@orda/shared-functions-js/lib/options";
import { inflateOptions } from "./options";

export function calculatePrice(
  orderItems,
  items,
  venue,
  orderLocation,
  tip,
  benefits,
  sumBenefits,
  expandableBenefits,
  fees,
  skipSharingLastItemBenefits
) {
  const menuItems = orderItems.map((item) => {
    const finalItem = {
      id: item.itemId,
      item: items[item.itemId],
    };

    if (item.orderOptions) {
      finalItem.orderOptions = item.orderOptions;
    }

    return finalItem;
  });

  const expandedBenefits = expandBenefitsByIndex(
    expandableBenefits,
    orderItems
  );
  const { shareableBenefits, itemBenefits } = expandedBenefits.reduce(
    (results, expandedItemBenefits, index) => {
      if (!expandedItemBenefits || expandedItemBenefits.length === 0) {
        return results;
      }

      if (skipSharingLastItemBenefits && index === orderItems.length - 1) {
        if (expandedItemBenefits) {
          return {
            ...results,
            itemBenefits: {
              ...results.itemBenefits,
              [index]: expandedItemBenefits,
            },
          };
        }
        return results;
      }
      // Get used benefits up to this point
      const { usedBenefits } = results;
      let allBenefits = benefits;
      let usableBenefits = benefits;
      for (let i = 0; i < index; i++) {
        const menuItem = menuItems[i];
        if (expandedBenefits[i]) {
          for (let k = 0; k < expandedBenefits[i].length; k++) {
            const expandedBenefit = expandedBenefits[i][k];
            let concatEnabled = true;
            for (let j = 0; j < allBenefits.length; j++) {
              if (expandedBenefit.id === allBenefits[j].id) {
                allBenefits = [
                  ...allBenefits.slice(0, j),
                  {
                    ...expandedBenefit,
                    maxAmount:
                      expandedBenefit.maxAmount + allBenefits[j].maxAmount,
                  },
                  ...allBenefits.slice(j + 1),
                ];
                concatEnabled = false;
                break;
              }
            }
            if (concatEnabled) {
              allBenefits = allBenefits.concat([expandedBenefit]);
            }
          }
        }
        usableBenefits = getUsableBenefits(allBenefits, usedBenefits);
        const usablePrice =
          (orderLocation === VENUE_LOCATION_TO_GO
            ? menuItem.item.priceToGo || menuItem.item.price
            : menuItem.item.price) || 0;
        const { benefit } = calculateItemBenefits(
          menuItem.id,
          usablePrice,
          usableBenefits
        );
        updateUsedBenefits(usedBenefits, benefit);

        if (menuItem.orderOptions) {
          calculateOptionsPrice(
            menuItem.orderOptions,
            inflateOptions(venue, menuItem.item.options),
            allBenefits,
            usedBenefits,
            orderLocation
          );
        }
      }

      const maxAmountsBeforeApplication = expandedItemBenefits.reduce(
        (result, benefit) => {
          if (!usedBenefits[benefit.id]) {
            return result;
          }
          return {
            ...result,
            [benefit.id]: usedBenefits[benefit.id],
          };
        },
        {}
      );

      const menuItem = menuItems[index];
      expandedItemBenefits.forEach((expandedBenefit) => {
        for (let j = 0; j < allBenefits.length; j++) {
          if (expandedBenefit.id === allBenefits[j].id) {
            allBenefits = [
              ...allBenefits.slice(0, j),
              {
                ...expandedBenefit,
                maxAmount: expandedBenefit.maxAmount + allBenefits[j].maxAmount,
              },
              ...allBenefits.slice(j + 1),
            ];
            return;
          }
        }
        allBenefits = allBenefits.concat([expandedBenefit]);
      });
      usableBenefits = getUsableBenefits(allBenefits, usedBenefits);
      const usablePrice =
        (orderLocation === VENUE_LOCATION_TO_GO
          ? menuItem.item.priceToGo || menuItem.item.price
          : menuItem.item.price) || 0;
      const { benefit: resultingBenefit } = calculateItemBenefits(
        menuItem.id,
        usablePrice,
        usableBenefits
      );
      updateUsedBenefits(usedBenefits, resultingBenefit);
      usableBenefits = getUsableBenefits(allBenefits, usedBenefits);

      if (menuItem.orderOptions) {
        calculateOptionsPrice(
          menuItem.orderOptions,
          inflateOptions(venue, menuItem.item.options),
          allBenefits,
          usedBenefits,
          orderLocation
        );
      }

      // Check whether benefits expanded by item are shareable
      const { shareableItemBenefits, itemBenefits: singleItemBenefits } =
        expandedItemBenefits.reduce(
          (itemResult, benefit) => {
            if (!benefit.maxAmount) {
              return {
                ...itemResult,
                shareableBenefits: {
                  ...itemResult.shareableBenefits,
                  [benefit.id]: benefit,
                },
              };
            }

            const realUsage =
              (usedBenefits[benefit.id] || 0) -
              (maxAmountsBeforeApplication[benefit.id] || 0);
            const shareAmount = benefit.maxAmount - realUsage;
            let newShareableItemBenefits = itemResult.shareableItemBenefits;
            let newItemBenefits = itemResult.itemBenefits;

            if (shareAmount > 0) {
              newShareableItemBenefits = {
                ...newShareableItemBenefits,
                [benefit.id]: {
                  ...benefit,
                  maxAmount: shareAmount,
                },
              };
            }

            if (realUsage > 0) {
              newItemBenefits = newItemBenefits.concat([
                {
                  ...benefit,
                  itemBenefit: true,
                  maxAmount: realUsage,
                },
              ]);
            }

            return {
              shareableItemBenefits: newShareableItemBenefits,
              itemBenefits: newItemBenefits,
            };
          },
          { shareableItemBenefits: {}, itemBenefits: [] }
        );

      const newItemBenefits = {
        ...results.itemBenefits,
        [index]: singleItemBenefits,
      };

      const newShareableBenefits = Object.entries(shareableItemBenefits).reduce(
        (result, [benefitId, benefit]) => {
          if (!benefit.maxAmount || !result[benefitId]) {
            return {
              ...result,
              [benefitId]: benefit,
            };
          }

          return {
            ...result,
            [benefitId]: {
              ...result[benefitId],
              maxAmount: result[benefitId].maxAmount + benefit.maxAmount,
            },
          };
        },
        results.shareableBenefits
      );

      return {
        shareableBenefits: newShareableBenefits,
        itemBenefits: newItemBenefits,
        usedBenefits,
      };
    },
    {
      shareableBenefits: {},
      itemBenefits: {},
      usedBenefits: {},
    }
  );
  const sharedBenefits = Object.values(shareableBenefits);
  let allBenefits = benefits;
  sharedBenefits.forEach((expandedBenefit) => {
    for (let j = 0; j < allBenefits.length; j++) {
      if (expandedBenefit.id === allBenefits[j].id) {
        allBenefits = [
          ...allBenefits.slice(0, j),
          {
            ...expandedBenefit,
            maxAmount: expandedBenefit.maxAmount + allBenefits[j].maxAmount,
          },
          ...allBenefits.slice(j + 1),
        ];
        return;
      }
    }
    allBenefits = allBenefits.concat([expandedBenefit]);
  });
  const calculated = menuItems.reduce(
    (previous, { id, item, orderOptions }, index) => {
      if (!item) {
        return {
          price: 0,
          usedBenefits: {},
          allUsedBenefits: {},
        };
      }
      const { price, priceToGo, options } = item;
      let result =
        (orderLocation === VENUE_LOCATION_TO_GO ? priceToGo || price : price) ||
        0;

      const { usedBenefits, allUsedBenefits } = previous;
      let fullBenefits = allBenefits;
      if (itemBenefits && itemBenefits[index]) {
        itemBenefits[index].forEach((expandedBenefit) => {
          for (let j = 0; j < allBenefits.length; j++) {
            if (expandedBenefit.id === allBenefits[j].id) {
              fullBenefits = [
                ...fullBenefits.slice(0, j),
                {
                  ...expandedBenefit,
                  maxAmount:
                    expandedBenefit.maxAmount + fullBenefits[j].maxAmount,
                },
                ...fullBenefits.slice(j + 1),
              ];
              return;
            }
          }
          fullBenefits = fullBenefits.concat([expandedBenefit]);
        });
      }

      const remainingBenefits = getUsableBenefits(
        fullBenefits,
        previous.usedBenefits
      );
      const itemUsedBenefits = {};

      const { benefit, amount } = calculateItemBenefits(
        id,
        price,
        remainingBenefits
      );
      updateUsedBenefits(itemUsedBenefits, benefit);

      if (benefit) {
        result -= amount;
      }
      if (result < 0) {
        result = 0;
      }

      if (orderOptions) {
        const optionsPrice = calculateOptionsPrice(
          orderOptions,
          inflateOptions(venue, options),
          remainingBenefits,
          itemUsedBenefits,
          orderLocation
        );
        result += optionsPrice.price;
      }

      const difference = Object.entries(itemUsedBenefits).reduce(
        (diff, [benefitId, value]) => {
          // Check whether difference needs to be recorded
          let isItemBenefit = false;
          let usableValue = value;

          if (itemBenefits[index]) {
            const itemBenefit = itemBenefits[index].find(
              (innerItemBenefit) => innerItemBenefit.id === benefitId
            );
            if (itemBenefit) {
              isItemBenefit = true;
              if (itemBenefit.maxAmount) {
                const benefitDifference = itemBenefit.maxAmount - value;
                if (benefitDifference < 0) {
                  usableValue = Math.abs(benefitDifference);
                  isItemBenefit = false;
                }
              }
            }
          }

          if (isItemBenefit) {
            return diff;
          }

          if (!diff[benefitId]) {
            return {
              ...diff,
              [benefitId]: usableValue,
            };
          }
          return {
            ...diff,
            [benefitId]: usableValue + diff[benefitId],
          };
        },
        {}
      );

      return {
        price: previous.price + result,
        usedBenefits: mergeUsedBenefits(difference, usedBenefits),
        allUsedBenefits: mergeUsedBenefits(allUsedBenefits, itemUsedBenefits),
      };
    },
    {
      price: 0,
      usedBenefits: {},
      allUsedBenefits: {},
    }
  );

  let sum = calculated.price;

  if (sumBenefits) {
    const { amount, benefit } = calculateSumBenefits(sum, sumBenefits);
    if (benefit) {
      sum -= amount;
      updateUsedBenefits(calculated.allUsedBenefits, benefit);
    }
  }

  sum = Math.round(sum);

  let tipSum = 0;
  if (tip) {
    tipSum = Math.round(sum * tip);
  }
  let feeSum = 0;
  if (fees) {
    feeSum = calculateFees(fees, sum);
  }

  sum += tipSum;

  if (sum > 0) {
    sum += feeSum;
  }

  return {
    sum,
    usedBenefits: calculated.allUsedBenefits,
  };
}

export function formatPricePrefix(priceDisplay) {
  switch (priceDisplay) {
    case PRICE_DISPLAY_WITHOUT_PREFIX:
      return "";
    case PRICE_DISPLAY_DEFAULT:
    default:
      return "+ ";
  }
}
