import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import moment from "moment";
import localization from "moment/locale/de";
import LanguageDetector from "i18next-browser-languagedetector";
import { SUPPORTED_LOCALES } from "../constants";

import de from "../strings/de";
import en from "../strings/en";

function convertMoney({ value, currency }) {
  switch (currency) {
    case "EUR":
      return value / 100;
    default:
      return value / 100;
  }
}

const fallbackLng = "en";
const supported = ["en", "de"];
const TIME_THRESHOLD_MINUTES = 15;
i18next.orda = {
  getLocale(supportedLocales = supported) {
    const result =
      supportedLocales.find((lan) => i18next.language.startsWith(lan)) ||
      fallbackLng;
    return result;
  },
};
export function getUsableLocale() {
  return (
    SUPPORTED_LOCALES.find((locale) => i18next.language.startsWith(locale)) ||
    fallbackLng
  );
}

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng,
    debug: process.env.NODE_ENV === "development",
    interpolation: {
      format: (value, format) => {
        switch (format) {
          case "number":
            return new Intl.NumberFormat(getUsableLocale(), {
              maximumFractionDigits: 2,
            }).format(value.value);
          case "percent":
            return `${new Intl.NumberFormat(getUsableLocale(), {
              maximumFractionDigits: 2,
            }).format(value * 100)}%`;
          case "money":
            return new Intl.NumberFormat(getUsableLocale(), {
              style: "currency",
              currency: value.currency,
            }).format(convertMoney(value));
          case "time-llll":
            return moment.unix(value).locale(getUsableLocale()).format("llll");
          case "time-hhmm":
            return moment.unix(value).locale(getUsableLocale()).format("HH:mm");
          case "time-LT":
            return moment.unix(value).locale(getUsableLocale()).format("LT");
          case "calendar": {
            let result = "";
            if (getUsableLocale() === "de") {
              result =
                moment.unix(value).locale("de", localization).calendar() || "";
            } else {
              result =
                moment.unix(value).locale(getUsableLocale()).calendar() || "";
            }
            const firstChar = result[0] || "";
            return `${firstChar.toLowerCase()}${result.substr(
              1,
              result.length - 1
            )}`;
          }
          case "duration":
            return value.locale(getUsableLocale()).humanize(true);
          case "weekDay":
            return moment()
              .isoWeekday(value)
              .locale(getUsableLocale())
              .format("dddd");
          case "orderTime": {
            const initialTime = moment();
            initialTime.set({
              hour: parseInt(`${value[0]}${value[1]}`, 10),
              minute: parseInt(`${value[2]}${value[3]}`, 10),
              second: 0,
              millisecond: 0,
            });
            const timeFrom = moment(initialTime)
              .locale(i18next.orda.getLocale())
              .format("HH:mm");

            const timeTo = moment(initialTime)
              .add(TIME_THRESHOLD_MINUTES, "minutes")
              .locale(i18next.orda.getLocale())
              .format("HH:mm");

            return `${timeFrom} - ${timeTo}`;
          }
          default:
            return value;
        }
      },
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      wait: true,
    },
    resources: {
      de,
      en,
    },
  });

export default i18next;
