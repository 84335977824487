import * as types from "../types";
import { v4 as generate } from "uuid";

export const addItem = (venueId, itemId, note) => ({
  type: types.order.ORDER.ADD_ITEM,
  venueId,
  itemId,
  note,
});

export const addItemWithOptions = (
  venueId,
  itemId,
  configuredOptions,
  note
) => ({
  type: types.order.ORDER.ADD_ITEM_WITH_OPTIONS,
  venueId,
  id: generate(),
  itemId,
  configuredOptions,
  note,
});

export const removeItem = (cartItemId) => ({
  type: types.order.ORDER.REMOVE_ITEM,
  cartItemId,
});

export const clearOrder = () => ({
  type: types.order.ORDER.CLEAR,
});

export const clearPreparedOrder = () => ({
  type: types.order.ORDER.CLEAR_PREPARED_ORDER,
});

export const setTableId = (tableId) => ({
  type: types.order.ORDER.SET_TABLE_ID,
  tableId,
});

export const setVenueId = (venueId) => ({
  type: types.order.ORDER.SET_VENUE_ID,
  venueId,
});

export const setExpress = (state) => ({
  type: types.order.ORDER.SET_EXPRESS,
  state,
});

export const setTip = (tip) => ({
  type: types.order.ORDER.SET_TIP,
  tip,
});

export function setLocationOption(location) {
  return {
    type: types.order.ORDER.SET_LOCATION_OPTION,
    location,
  };
}

export function setUserName(userName) {
  return {
    type: types.order.ORDER.SET_USER_NAME,
    userName,
  };
}

export function setUserEmail(userEmail) {
  return {
    type: types.order.ORDER.SET_USER_EMAIL,
    userEmail,
  };
}

export function setDeliveryStreetName(deliveryStreetName) {
  return {
    type: types.order.ORDER.SET_DELIVERY_STREET_NAME,
    deliveryStreetName,
  };
}

export function setDeliveryStreetNumber(deliveryStreetNumber) {
  return {
    type: types.order.ORDER.SET_DELIVERY_STREET_NUMBER,
    deliveryStreetNumber,
  };
}

export function setDeliveryCity(deliveryCity) {
  return {
    type: types.order.ORDER.SET_DELIVERY_CITY,
    deliveryCity,
  };
}

export function setDeliveryPostalCode(deliveryPostalCode) {
  return {
    type: types.order.ORDER.SET_DELIVERY_POSTAL_CODE,
    deliveryPostalCode,
  };
}

export function setDeliveryAdditionalInformation(
  deliveryAdditionalInformation
) {
  return {
    type: types.order.ORDER.SET_DELIVERY_ADDITIONAL_INFORMATION,
    deliveryAdditionalInformation,
  };
}

export function setPhoneNumber(phoneNumber) {
  return {
    type: types.order.ORDER.SET_PHONENUMBER,
    phoneNumber,
  };
}

export function setDeliveryAddress(deliveryAddress) {
  return {
    type: types.order.ORDER.SET_DELIVERY_ADDRESS,
    deliveryAddress,
  };
}

export function setOrderTime(orderTime) {
  return {
    type: types.order.ORDER.SET_ORDER_TIME,
    orderTime,
  };
}

export const setCo2OffsetValue = (co2OffsetValue) => ({
  type: types.order.ORDER.SET_CO2_OFFSETTING,
  co2OffsetValue,
});

export const prepareOrder = (venueId, notifyPromise) => ({
  type: types.order.PREPARE_ORDER.REQUEST,
  venueId,
  notifyPromise,
});

export const prepareOrderSuccess = (data) => ({
  type: types.order.PREPARE_ORDER.SUCCESS,
  data,
});

export const prepareOrderFailure = (error) => ({
  type: types.order.PREPARE_ORDER.FAILURE,
  error,
});

export const executeOrder = (notifyPromise) => ({
  type: types.order.EXECUTE_ORDER.REQUEST,
  notifyPromise,
});

export const executeOrderSuccess = (order) => ({
  type: types.order.EXECUTE_ORDER.SUCCESS,
  order,
});

export const executeOrderFailure = (error) => ({
  type: types.order.EXECUTE_ORDER.FAILURE,
  error,
});

export const expressOrderStart = (
  venueId,
  itemId,
  configuredOptions,
  note
) => ({
  type: types.order.EXPRESS_ORDER.START,
  venueId,
  itemId,
  configuredOptions,
  note,
});

export const expressOrderSuccess = () => ({
  type: types.order.EXPRESS_ORDER.SUCCESS,
});

export const expressOrderFailure = () => ({
  type: types.order.EXPRESS_ORDER.FAILURE,
});

export const fetchBenefitsFees = (venueId) => ({
  type: types.order.FEES_BENEFITS.REQUEST,
  venueId,
});

export const successBenefitsFees = (
  hash,
  benefits,
  sumBenefits,
  expandableBenefits,
  fees
) => ({
  type: types.order.FEES_BENEFITS.SUCCESS,
  hash,
  fees,
  benefits,
  sumBenefits,
  expandableBenefits,
});

export const failedBenefitsFees = (error) => ({
  type: types.order.FEES_BENEFITS.FAILURE,
  error,
});
