import { connect } from "react-redux";
import OrderSuccess from "../components/OrderSuccess";
import { calculatePrepTimeRange } from "../lib/venues";
import moment from "moment-timezone";
import { TrafficStatus } from "@orda/shared-constants/venue-types";

const mapStateToProps = (state) => {
  const {
    user: { orders },
  } = state;

  const order = orders[orders.length - 1];

  const {
    venues: {
      data: { [order.venueId]: venue },
    },
  } = state;

  let avgPrepTime = null;
  const avgDeliveryTimeOffset = 20;

  let prepTime;
  if (venue) {
    if (typeof venue.avgPrepTime !== "undefined") {
      avgPrepTime = venue.avgPrepTime;
    }
    prepTime =
      avgPrepTime ||
      calculatePrepTimeRange(
        avgPrepTime,
        venue.traffic &&
          moment().diff(moment.unix(venue.traffic.lastUpdate), "minutes") < 120
          ? venue.traffic.status
          : TrafficStatus.Normal
      );
  } else {
    prepTime = 12;
  }

  const pickUpTime = moment().unix() + prepTime * 60;
  const avgDeliveryTime = avgDeliveryTimeOffset + prepTime;

  return {
    order,
    pickUpTime,
    avgDeliveryTime,
  };
};

export default connect(mapStateToProps)(OrderSuccess);
