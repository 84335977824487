import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import Loading from "./Loading";
import routes from "../routes";
import { VENUE_LOCATION_EAT_IN } from "@orda/shared-constants/order-locations";
import { Elements } from "react-stripe-elements";

const styles = {
  barsContainer: {
    position: "sticky",
    bottom: 0,
  },
};

class Venue extends React.Component {
  componentDidMount() {
    const { getVenue, venueLoaded } = this.props;

    if (!venueLoaded) {
      getVenue();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.venueLoaded) {
      nextProps.getVenue();
    }
  }

  render() {
    const {
      location,
      venueId,
      venueLoaded,
      setTableId,
      setLocationOption,
      venue,
    } = this.props;

    const searchParams = new URLSearchParams(location.search);
    const tableId = searchParams.get("qr");

    if (tableId && venueLoaded) {
      setTableId(tableId);
      setLocationOption(VENUE_LOCATION_EAT_IN);
      if (venue.startScreen) {
        return <Redirect to={routes.start.template(venueId)} />;
      }
      return <Redirect to={routes.menu.template(venueId)} />;
    }

    // logic needed because the venue data is not persisted meaning if you refresh the page in a compenent that uses
    // a state not persisted (whitelist: ["user", "order"]) the venue data will not be available (venue is refetched each time)
    return venueLoaded ? (
      <Switch>
        <Route
          exact={routes.inHousePayment.exact}
          component={routes.inHousePayment.component}
          path={routes.inHousePayment.path}
        />
        <Route
          exact={routes.inHouseCheckoutRedirect.exact}
          component={routes.inHouseCheckoutRedirect.component}
          path={routes.inHouseCheckoutRedirect.path}
        />
        <Route
          exact
          path={routes.venue.path}
          render={(props) => (
            <routes.menu.component {...props} routeId={routes.menu.id} />
          )}
        />
        <Route
          exact={routes.menu.exact}
          path={routes.menu.path}
          render={(props) => (
            <routes.menu.component {...props} routeId={routes.menu.id} />
          )}
        />
        <Route
          exact={routes.item.exact}
          path={routes.item.path}
          render={(props) => (
            <routes.item.component {...props} routeId={routes.item.id} />
          )}
        />
        <Elements>
          <Route
            exact={routes.cart.exact}
            path={routes.cart.path}
            render={(props) => (
              <routes.cart.component {...props} routeId={routes.cart.id} />
            )}
          />
        </Elements>
      </Switch>
    ) : (
      <Loading />
    );
  }
}

Venue.propTypes = {
  venueId: PropTypes.string,
  venueLoaded: PropTypes.bool.isRequired,
  getVenue: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  setTableId: PropTypes.func.isRequired,
  setLocationOption: PropTypes.func.isRequired,
  orders: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  venue: PropTypes.object,
};

Venue.defaultProps = {
  venueId: null,
  venue: null,
};

export default withStyles(styles)(Venue);
