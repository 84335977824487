import React from "react";
import PropTypes from "prop-types";
import { Typography, withStyles } from "@material-ui/core";
import { THEME } from "../theme";
import classNames from "classnames";

const styles = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    flexDirection: "column",
  },
  message: {
    fontSize: "1.4rem",
    fontWeight: "bold",
    textAlign: "center",
    whiteSpace: "pre-line",
  },
  errorMessage: { color: THEME.ACCENT_RED },
  successMessage: {
    color: THEME.GREEN_TEXT,
  },
};

const ResultMessage = ({ classes, message, subMessage, isError }) => (
  <div className={classes.root}>
    <Typography
      variant="body2"
      className={classNames(classes.message, {
        [classes.errorMessage]: isError,
        [classes.successMessage]: !isError,
      })}
    >
      {message}
    </Typography>
    <Typography
      variant="body2"
      className={classNames(classes.message, {
        [classes.errorMessage]: isError,
        [classes.successMessage]: !isError,
      })}
    >
      {subMessage}
    </Typography>
  </div>
);
ResultMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  isError: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  subMessage: PropTypes.string.isRequired,
};
export default withStyles(styles)(ResultMessage);
