import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { ShieldLock as ShieldLockIcon } from "mdi-material-ui";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
  },
};

const SecurityNotice = ({ t, classes }) => (
  <div className={classes.root}>
    <ShieldLockIcon
      fontSize="large"
      style={{ color: "#999999", marginRight: 10 }}
    />
    <Typography
      variant="caption"
      style={{ color: "#999999", fontSize: "0.65rem" }}
    >
      {t("text")}
    </Typography>
  </div>
);

SecurityNotice.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withTranslation("securityNotice")(
  withStyles(styles)(SecurityNotice)
);
