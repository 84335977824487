export const THEME = {
  // BUTTON-ICON
  PRIMARY_BUTTON_ICON: "#ADD450",
  SECONDARY_BUTTON_ICON: "#2C2F3A",
  DISABLED_BUTTON_ICON: "#828282",
  DIMMED_DISABLED_BUTTON_ICON: "#565656",

  // BANNER
  BANNER_BORDER: "#ADD450",
  BANNER_BACKGROUND_COLOR: `rgba(255,255,255,0.53)`,

  // TEXT
  PRIMARY_TEXT_COLOR: "#2C2F3A",
  SECONDARY_TEXT_COLOR: "#868e96", // BDBDBD
  DIMMED_SECONDARY_TEXT_COLOR: "#505050",
  ACCENT_TEXT_COLOR: "#ADD450",
  WHITE_TEXT_COLOR: "white",
  DELETE_RED_COLOR: "#E73E45",
  DISABLED_TEXT_COLOR: "#aaa",

  // BACKGROUND
  DEFAULT_BACKGROUND_COLOR: "#fafafa",
  PRIMARY_BACKGROUND_COLOR: "white",
  SECONDARY_BACKGROUND_COLOR: "#2C2F3A",
  LIST_BACKGROUND_COLOR: "#EBEBEB",

  FILL_INACTIVE_BUTTON: "rgba(235,235,235,0.3)",

  // CARDS
  CARD_BACKGROUND_COLOR: "white",
  CARD_BORDER_COLOR: "#BDBDBD",

  // BUTTON WITH BACKGROUND
  BUTTON_TEXT_COLOR: "white",
  BUTTON_BACKGROUND_COLOR: "#2C2F3A",
  BUTTON_BACKGROUND_DISABLED_COLOR: "#bababa",

  // COLORS
  ORANGE: "#FFBB59",
  MAIN_GREEN: "#ADD450",
  DARKER_MAIN_GREEN: "#96c130",
  LIGHTER_MAIN_GREEN: "#add450ba",
  BACKGROUND_GREEN: "rgba(173, 212, 80, 0.35)",
  GREEN_TEXT: "#91ad4e",
  LIGHT_ORANGE: "#FF9382",
  BORDER_GRAY: "#BDBDBD",
  ACCENT_RED: "#E73E45",

  OLD_PRIMARY_COLOR: "black",
  OLD_BACKGROUND_COLOR: "#2C2F3A",
  OLD_TEXT_COLOR: "white",
  MAX_SCREEN_WIDTH: 900,
};
