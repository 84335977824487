import { connect } from "react-redux";
import AddPaymentMethod from "../components/AddPaymentMethod";
import { paymentOptionsDefault } from "../constants";
import { login } from "../redux/actions/auth";
import { addPaypalCheckout } from "../redux/actions/payments";
import { getVenue } from "../redux/actions/venues";

const mapStateToProps = (state, ownProps) => {
  const {
    auth: {
      user: authUser,
      requests: {
        login: { processing: loginProcessing },
      },
      syncFinished: authSyncFinished,
    },
    order: { express },
    user: { hasSeenTermsNotice },
  } = state;
  const {
    location: { search },
  } = ownProps;

  const searchParams = new URLSearchParams(search);
  const venueId = searchParams.get("cartVenueId");

  const {
    venues: {
      requests: {
        getVenue: { [venueId]: getVenueRequest = {} },
      },
      data: { [venueId]: venue },
    },
  } = state;
  let paymentOptions = !!venue && venue.paymentOptions;
  if (!paymentOptions || paymentOptions.length <= 0) {
    paymentOptions = paymentOptionsDefault;
  }
  return {
    loginProcessing,
    authUser,
    authSyncFinished,
    express,
    hasSeenTermsNotice,
    paymentOptions,
    venueLoaded: !!venue && !getVenueRequest.processing,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getVenue: (venueId) => dispatch(getVenue(venueId)),
  login: () => dispatch(login()),
  addPaypalCheckout: () => dispatch(addPaypalCheckout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentMethod);
