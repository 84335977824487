import React from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { THEME } from "../../theme";

const styles = {
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #ddd",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  total: {
    marginTop: 10,
    fontWeight: "bold",
    textAlign: "right",
  },
  itemName: {
    fontWeight: 500,
  },
  optionName: {
    paddingLeft: "1.5rem",
  },
  sumTotalLabel: {
    fontSize: "1.4rem",
    fontWeight: "bold",
  },
  errorMessage: {
    textAlign: "center",
    fontWeight: "bold",
    color: THEME.ACCENT_RED,
    whiteSpace: "pre-line",
  },
};
const InHouseOrderOverview = ({ t, classes, positions, currency }) => {
  const formatProductName = (product) =>
    `${product.qty} x ${product.productName}`;
  const sumTotal = positions.reduce(
    (acc, curr) => acc + curr.price * curr.qty * 100,
    0
  );
  return (
    <List>
      {positions.map((product) => (
        <ListItem key={product.uid} className={classes.listItem}>
          {!product.uidParent ? (
            <ListItemText
              primary={formatProductName(product)}
              primaryTypographyProps={{ className: classes.itemName }}
            />
          ) : (
            <ListItemText
              secondary={formatProductName(product)}
              secondaryTypographyProps={{
                className: classes.optionName,
              }}
            />
          )}
          <ListItemSecondaryAction>
            <Typography>
              {t("common:money", {
                data: {
                  currency,
                  value: product.qty * product.price * 100,
                },
              })}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
      {!!sumTotal && (
        <ListItem key="sumTotal" className={classes.listItem}>
          <ListItemText
            primary={t("sumTotalLabel")}
            primaryTypographyProps={{
              className: classes.sumTotalLabel,
            }}
          />
          <ListItemSecondaryAction>
            <Typography className={classes.sumTotalLabel}>
              {t("common:money", {
                data: {
                  currency,
                  value: sumTotal,
                },
              })}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>
      )}
    </List>
  );
};

InHouseOrderOverview.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  positions: PropTypes.array,
  currency: PropTypes.string,
};
InHouseOrderOverview.defaultProps = {
  positions: [],
  currency: "EUR",
};
export default withTranslation("inHousePayment")(
  withStyles(styles)(InHouseOrderOverview)
);
