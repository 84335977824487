import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { withTranslation } from "react-i18next";
import {
  PRICE_DISPLAY_DEFAULT,
  PRICE_DISPLAY_ONLY_AFTER_BENEFIT,
} from "@orda/shared-constants-js/price-display";
import { replaceTrailingSpaces } from "../util/strings";
import TypographyExponent from "./TypographyExponent";

const styles = {
  priceBeforeBenefits: {
    display: "flex",
  },
  priceAfterBenefits: {
    display: "flex",
  },
  priceDisplayStrikeThrough: {
    textDecoration: "line-through",
  },
};

const PriceCart = ({
  t,
  classes,
  currency,
  priceBeforeBenefits,
  priceAfterBenefits,
  priceDisplay,
  prefix,
  hideFree,
  remark,
}) => {
  const benefitApplied = priceAfterBenefits !== priceBeforeBenefits;

  const displayBeforeBenefits =
    priceDisplay !== PRICE_DISPLAY_ONLY_AFTER_BENEFIT || !benefitApplied;

  return (
    <div>
      {displayBeforeBenefits &&
        (priceBeforeBenefits > 0 ? (
          <Typography
            variant="body2"
            className={classNames({
              [classes.priceDisplayStrikeThrough]: benefitApplied,
            })}
          >
            {t("common:money", {
              data: { currency, value: priceBeforeBenefits },
            })}
          </Typography>
        ) : (
          !hideFree && (
            <Typography variant="body2">{t("ui-parts:freeLabel")}</Typography>
          )
        ))}
      {benefitApplied &&
        (priceAfterBenefits > 0 ? (
          <div className={classes.priceAfterBenefits}>
            <Typography component="body2">
              {replaceTrailingSpaces(prefix)}
            </Typography>
            <TypographyExponent
              base={t("common:money", {
                data: { currency, value: priceAfterBenefits },
              })}
              exponent={remark}
            />
          </div>
        ) : (
          !hideFree && (
            <TypographyExponent
              base={t("ui-parts:freeLabel")}
              exponent={remark}
            />
          )
        ))}
    </div>
  );
};

PriceCart.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  priceBeforeBenefits: PropTypes.number.isRequired,
  priceAfterBenefits: PropTypes.number.isRequired,
  priceDisplay: PropTypes.number,
  hideFree: PropTypes.bool,
  remark: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  prefix: PropTypes.string,
};

PriceCart.defaultProps = {
  hideFree: false,
  remark: null,
  priceDisplay: PRICE_DISPLAY_DEFAULT,
  prefix: "",
};

export default withTranslation("common")(withStyles(styles)(PriceCart));
