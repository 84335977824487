import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CreditCardForm from "../../components/CreditCardForm/CreditCardForm";
import { addCreditCard } from "../../redux/actions/payments";

const mapStateToProps = (state) => {
  const {
    user: {
      requests: {
        addCreditCard: { processing: addCreditCardProcessing },
      },
    },
    order: { express },
    user: { hasSeenTermsNotice },
  } = state;

  return {
    addCreditCardProcessing,
    express,
    hasSeenTermsNotice,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addCreditCard: (token) => dispatch(addCreditCard(token)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreditCardForm)
);
