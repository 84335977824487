import React from "react";
import { THEME } from "../../theme";
import PropTypes from "prop-types";

const WalletIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    space="preserve"
  >
    <g fill={fill}>
      <path
        d="m17.27 5.009-2.123-3.637a.752.752 0 0 0-1.033-.266L7.624 5z"
        opacity="1"
        data-original="#000000"
      ></path>
      <path
        d="M3 6c-.55 0-1-.45-1-1s.45-1 1-1h3.37l3.34-2H3C1.52 2 .29 3.08.05 4.5c-.02.08-.05.16-.05.25V20c0 1.65 1.35 3 3 3h17c1.1 0 2-.9 2-2v-2h-2.5c-2.48 0-4.5-2.02-4.5-4.5s2.02-4.5 4.5-4.5H22V8c0-1.1-.9-2-2-2zm16-2c0-1.01-.75-1.85-1.73-1.98L19 5z"
        opacity="1"
        data-original="#000000"
      ></path>
      <path
        d="M23.25 11.5H19.5c-1.654 0-3 1.346-3 3s1.346 3 3 3h3.75a.75.75 0 0 0 .75-.75v-4.5a.75.75 0 0 0-.75-.75zm-3.75 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
        opacity="1"
        data-original="#000000"
      ></path>
    </g>
  </svg>
);

WalletIcon.propTypes = {
  fill: PropTypes.string,
};
WalletIcon.defaultProps = {
  fill: THEME.WHITE_TEXT_COLOR,
};

export default WalletIcon;
