import { API_ENDPOINT } from "../config";
import { transport } from "../lib/transport";
import { PaymentOption } from "@orda/shared-constants";

export const addCreditCard = async (userId, stripeToken, authToken) => {
  const result = await transport(
    {
      method: "POST",
      url: `${API_ENDPOINT}/users/${userId}/payments`,
      body: JSON.stringify({
        paymentOption: PaymentOption.CreditCard,
        token: stripeToken,
      }),
    },
    authToken
  );
  return result;
};

export const addPaymentOption = async (userId, paymentOption, authToken) => {
  const result = await transport(
    {
      method: "POST",
      url: `${API_ENDPOINT}/users/${userId}/payments`,
      body: JSON.stringify({
        paymentOption,
      }),
    },
    authToken
  );
  return result;
};

export const retrieveSetupIntent = async (userId, authToken) => {
  const result = await transport(
    {
      method: "POST",
      url: `${API_ENDPOINT}/users/${userId}/payments/setup-intent`,
    },
    authToken
  );
  return result;
};

export const retrieveBenefitsAndFees = async (
  type,
  currency,
  restaurantId,
  authToken
) => {
  let url = `${API_ENDPOINT}/payment/adjustments?`;
  if (type) {
    url += `type=${type}&`;
  }
  if (currency) {
    url += `currency=${currency}&`;
  }
  if (restaurantId) {
    url += `restaurantId=${restaurantId}&`;
  }
  const result = await transport(
    {
      method: "GET",
      url,
    },
    authToken
  );
  return result;
};

export const createAdyenPaymentSessionTransport = async (venueId, data) => {
  const result = await transport({
    method: "POST",
    url: `${API_ENDPOINT}/venues/${venueId}/in-house-checkout/sessions`,
    body: JSON.stringify(data),
  });
  return result;
};
