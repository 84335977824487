import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = {
  root: {
    display: "flex",
  },
  exponent: {
    fontSize: "0.7em",
  },
};

const TypographyExponent = ({ classes, base, exponent, labelDisplay }) => (
  <div className={classes.root}>
    <Typography component="span" style={labelDisplay}>
      {base}
    </Typography>
    <Typography component="span" className={classes.exponent}>
      {exponent}
    </Typography>
  </div>
);

TypographyExponent.propTypes = {
  classes: PropTypes.object.isRequired,
  base: PropTypes.string.isRequired,
  exponent: PropTypes.string.isRequired,
  labelDisplay: PropTypes.object,
};

TypographyExponent.defaultProps = {
  labelDisplay: {},
};

export default withStyles(styles)(TypographyExponent);
