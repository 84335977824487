import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { Refresh as RefreshIcon } from "mdi-material-ui";

const styles = {
  cartErrorContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  retryButton: {
    marginTop: 15,
    height: 55,
  },
  retryButtonIcon: {
    marginRight: 5,
  },
};

const CartPrepareOrderError = ({
  t,
  classes,
  prepareOrderError,
  prepareOrder,
}) => (
  <div className={classes.cartErrorContainer}>
    <Typography variant="body2">{prepareOrderError.message}</Typography>
    <Button
      variant="contained"
      color="default"
      className={classes.retryButton}
      onClick={prepareOrder}
    >
      <RefreshIcon className={classes.retryButtonIcon} />
      {t("retryButtonLabel")}
    </Button>
  </div>
);

CartPrepareOrderError.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  prepareOrderError: PropTypes.object.isRequired,
  prepareOrder: PropTypes.func.isRequired,
};

export default withTranslation("cart")(
  withStyles(styles)(CartPrepareOrderError)
);
