/* eslint no-param-reassign: 0 */
import * as types from "../types";
import produce from "immer";
import isEqual from "lodash/isEqual";

import {
  ENTRY_TYPE_ADD,
  ENTRY_TYPE_REMOVE,
} from "../../lib/item-configuration";

export const initialState = {
  entries: [],
  minimumOptions: null,
  note: null,
};

export default function itemConfigurationReducer(state = null, action) {
  switch (action.type) {
    case types.itemConfig.ITEM_CONFIG.RESET:
      return initialState;
    case types.itemConfig.ITEM_CONFIG.INTIALIZE:
      return produce(state, (newState) => {
        newState.entries = [];
        newState.minimumOptions = action.minimumOptions;
      });
    case types.itemConfig.ITEM_CONFIG.UPDATE:
      switch (action.entry.entryType) {
        case ENTRY_TYPE_ADD:
          return produce(state, (newState) => {
            newState.entries.push(action.entry);
          });
        case ENTRY_TYPE_REMOVE:
          return produce(state, (newState) => {
            newState.entries = newState.entries.filter(
              ({ path }) => !isEqual(path, action.entry.path)
            );
            newState.entries.push(action.entry);
          });
        default:
          return state;
      }
    case types.itemConfig.ITEM_CONFIG.UPDATE_NOTE:
      return produce(state, (newState) => {
        newState.note = action.note;
      });
    default:
      return state;
  }
}
