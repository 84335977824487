import React, { useMemo, useState } from "react";
import { THEME } from "../theme";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Close } from "mdi-material-ui";
import { IconButton, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { checkIfMobileOrTablet } from "../util/validation";

const styles = {
  downloadAppDialogContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "8px 21px",
    zIndex: 4,
    backgroundColor: THEME.SECONDARY_BUTTON_ICON,
    maxWidth: THEME.MAX_SCREEN_WIDTH,
  },
  downloadAppDialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 0",
    fontWeight: "bold",
  },
  downloadAppDialogClose: {
    color: THEME.MAIN_GREEN,
  },
  title: {
    fontSize: 20,
    fontFamily: "Open Sans",
    color: THEME.WHITE_TEXT_COLOR,
    wordBreak: "break-all",
  },
  downloadAppText: {
    color: THEME.WHITE_TEXT_COLOR,
    fontWeight: "500",
    fontFamily: "Open Sans",
    fontSize: 13,
    marginBottom: 20,
  },
  downloadAppButton: {
    backgroundColor: THEME.PRIMARY_BUTTON_ICON,
    color: THEME.PRIMARY_TEXT_COLOR,
    fontWeight: "bold",
    fontFamily: "Open Sans",
    "&:hover": {
      backgroundColor: THEME.PRIMARY_BUTTON_ICON,
    },
  },
};

const DownloadAppModal = ({ t, classes, venueId, tableId }) => {
  const [open, setOpen] = useState(false);
  const goToDownloadApp = () => {
    window.open(
      `https://get.orda-app.com?~channel=${tableId}&venueId=${venueId}&tableId=${tableId}`,
      "_blank",
      "noopener,noreferrer"
    );
    setOpen(false);
  };
  const mobileAndTabletCheck = useMemo(() => checkIfMobileOrTablet(), []);
  return (
    mobileAndTabletCheck &&
    open && (
      <div className={classes.downloadAppDialogContainer}>
        <div className={classes.downloadAppDialogTitle}>
          <Typography variant="h1" className={classes.title}>
            {t("title")}
          </Typography>
          <IconButton
            className={classes.downloadAppDialogClose}
            onClick={() => setOpen(false)}
          >
            <Close />
          </IconButton>
        </div>
        <div>
          <Typography
            variant="body2"
            className={classes.downloadAppText}
            noWrap
          >
            {t("content")}
          </Typography>
        </div>
        <Button
          className={classes.downloadAppButton}
          size="large"
          onClick={goToDownloadApp}
        >
          {t("buttonLabel")}
        </Button>
      </div>
    )
  );
};

DownloadAppModal.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withTranslation("downloadAppModal")(
  withStyles(styles)(DownloadAppModal)
);
