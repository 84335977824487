import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import "./styles/normalize.css";
import "./styles/font.css";
import "./styles/paypal.css";
import "./lib/sentry";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/400.css";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
