export const CLIENTVERSION = 22;

const useDevEnvironment = process.env.REACT_APP_DEPLOY_ENV !== "production";
const useLocalApi = false;

let STRIPE_KEY = "pk_live_8LTe0pKzfptreBWcdSr4N8H0";
const WEBAPP_GOOGLE_MAPS_API_KEY = "AIzaSyBscObwVgqh97BuSZc4VWETc67IEJUXYd4";
if (useDevEnvironment) {
  STRIPE_KEY = "pk_test_giJlz87CvsOIkdnhDTvvpspk";
}
export const STRIPE_PUBLISHABLE_KEY = STRIPE_KEY;

let API_URL = "https://api.orda-app.com";
export const GOOGLE_MAPS_API_URL = `https://maps.googleapis.com/maps/api/js?key=${WEBAPP_GOOGLE_MAPS_API_KEY}&libraries=places`;

if (useDevEnvironment && !useLocalApi) {
  API_URL = "https://api-dev.orda-app.com";
} else if (useLocalApi) {
  API_URL = "http://localhost:3100";
} else {
  API_URL = "https://api.orda-app.com";
}
export const API_ENDPOINT = API_URL;

export const PAYPAL_CONFIG = {
  merchantName: "ORDA",
  merchantPrivacyUrl: "https://www.orda-app.com/datenschutz",
  merchantUserAgreementUrl: "https://www.orda-app.com/agb-nutzer",
  client: {
    sandbox:
      "AZXE7qx8sY-nmhOWvAFMfOWQRXUjL3V_oaJGkEvKLcdlW_yxSltxCIvoxD4U8KY1ogo0kMSTr80wTeob",
    production:
      "AfoYzwhwuFAWtry4DW2xF7avi9xgcsErPhTPAIo7S5OV9JVjKSR1znGmyg_rkH8OA1wYeLM_e41C1oyI",
  },
};

if (useDevEnvironment) {
  PAYPAL_CONFIG.environment = "sandbox";
} else {
  PAYPAL_CONFIG.environment = "production";
}

let AMPLITUDE_KEY = "5b664a0d16baae49ce5ba08eb508b5c3";
if (useDevEnvironment) {
  AMPLITUDE_KEY = "ed59d103683ccf921d7696f57a4707cb";
}
export const AMPLITUDE_API_KEY = AMPLITUDE_KEY;
let ADYEN_CLIENT_KEY;
let ADYEN_ENVIRONMENT;
if (useDevEnvironment) {
  ADYEN_CLIENT_KEY = "test_2NYKS2B4EBFHVOEY5RHNIAB27ENHTNEN";
  ADYEN_ENVIRONMENT = "test";
} else {
  ADYEN_CLIENT_KEY = "live_QUDUHURP6FD5NJLWGCCY7Y44DILSZXRV";
  ADYEN_ENVIRONMENT = "live";
}
export const REACT_APP_ADYEN_CLIENT_KEY = ADYEN_CLIENT_KEY;
export const REACT_APP_ADYEN_ENVIRONMENT = ADYEN_ENVIRONMENT;
