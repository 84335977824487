import React from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
} from "react-stripe-elements";
import { withTranslation, Trans } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import StripeElementWrapper from "./StripeElementWrapper";
import CircularProgress from "@material-ui/core/CircularProgress";
import routes from "../../routes";
import { THEME } from "../../theme";

const styles = {
  root: {
    margin: "0 40px",
    width: "100%",
  },
  button: {
    height: 55,
    backgroundColor: THEME.SECONDARY_BUTTON_ICON,
    color: THEME.WHITE_TEXT_COLOR,
    "&:hover": {
      backgroundColor: THEME.SECONDARY_BUTTON_ICON,
      opacity: 0.8,
    },
  },
  termsNotice: {
    textAlign: "center",
    color: "#999",
    fontSize: "0.7em",
    marginBottom: 10,
  },
};

class CreditCardForm extends React.Component {
  state = {
    stripeProcessing: false,
  };

  componentDidMount() {
    global.window.scroll(0, 0);
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { stripe, addCreditCard, location, express, history } = this.props;

    this.setState({ stripeProcessing: true });
    const { token } = await stripe.createToken();
    if (token) {
      addCreditCard(token.id);
    }
    this.setState({ stripeProcessing: false });

    const searchParams = new URLSearchParams(location.search);
    const venueId = searchParams.get("cartVenueId");
    if (!express) {
      history.push(
        `${routes.cart.template(venueId)}?addPaymentOptionDone=${1}`
      );
    }
  };

  render() {
    const { t, classes, express, addCreditCardProcessing, hasSeenTermsNotice } =
      this.props;
    const { stripeProcessing } = this.state;

    const buttonLabelKey = express ? "orderButtonLabel" : "continueButtonLabel";

    return (
      <form className={classes.root} onSubmit={this.handleSubmit}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <StripeElementWrapper
              label={t("cardNumber")}
              placeholder="1234 1234 1234 1234"
              component={CardNumberElement}
            />
          </Grid>
          <Grid item xs={7}>
            <StripeElementWrapper
              label={t("validUntil")}
              placeholder="MM/YY"
              component={CardExpiryElement}
            />
          </Grid>
          <Grid item xs={5}>
            <StripeElementWrapper
              label={t("cvc")}
              placeholder="CVC"
              component={CardCVCElement}
            />
          </Grid>
          <Grid item xs={12}>
            <>
              {express && !hasSeenTermsNotice && (
                <Typography variant="body2" className={classes.termsNotice}>
                  <Trans i18nKey="cart:termsNotice">
                    By placing an order you are accepting the
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.orda-app.com/agb-nutzer"
                      className={classes.link}
                    >
                      Terms & Conditions
                    </a>
                    .
                  </Trans>
                </Typography>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                className={classes.button}
              >
                {stripeProcessing || addCreditCardProcessing ? (
                  <CircularProgress size={20} style={{ color: "#fff" }} />
                ) : (
                  t(buttonLabelKey)
                )}
              </Button>
            </>
          </Grid>
          <Grid style={{ textAlign: "center" }} item xs={12}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://stripe.com"
            >
              <img
                // eslint-disable-next-line global-require
                src={require("../../assets/img/stripe.svg")}
                alt={t("poweredByStripe")}
              />
            </a>
          </Grid>
        </Grid>
      </form>
    );
  }
}

CreditCardForm.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  addCreditCard: PropTypes.func.isRequired,
  stripe: PropTypes.object.isRequired,
  addCreditCardProcessing: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  express: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  hasSeenTermsNotice: PropTypes.bool.isRequired,
};

export default withTranslation("creditCardForm")(
  withStyles(styles)(injectStripe(CreditCardForm))
);
