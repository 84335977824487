import { connect } from "react-redux";
import PaymentMethodInformation from "../../components/Cart/PaymentMethodInformation";
import { withRouter } from "react-router-dom";
import { paymentOptionsDefault } from "../../constants";

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { venueId },
    },
  } = ownProps;
  const {
    venues: {
      data: { [venueId]: venue },
    },
    order,
    user: { paymentMethods, currentPaymentMethod },
  } = state;
  let paymentOptions = !!venue && venue.paymentOptions;
  if (!paymentOptions || paymentOptions.length <= 0) {
    paymentOptions = paymentOptionsDefault;
  }
  const currentPaymentMethodInternal = currentPaymentMethod
    ? paymentMethods.find(
        (paymentMethod) => paymentMethod.id === currentPaymentMethod
      )
    : null;
  return {
    location: order.location,
    venueId,
    paymentOptions,
    // current payment associated with this user
    currentPaymentMethod: currentPaymentMethodInternal,
  };
};

export default withRouter(connect(mapStateToProps)(PaymentMethodInformation));
