import * as types from "../types";

export const createUser = () => ({
  type: types.user.CREATE_USER.REQUEST,
});

export const createUserSuccess = (user) => ({
  type: types.user.CREATE_USER.SUCCESS,
  user,
});

export const createUserFailure = (error) => ({
  type: types.user.CREATE_USER.FAILURE,
  error,
});

export const clearCurrentPaymentMethod = () => ({
  type: types.user.CLEAR_CURRENT_PAYMENT_METHOD,
});
