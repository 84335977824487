import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GoogleDeliveryForm from "../components/GoogleDeliveryForm";
import {
  setDeliveryCity,
  setDeliveryPostalCode,
  setDeliveryStreetName,
  setDeliveryStreetNumber,
  setLocationOption,
} from "../redux/actions/order";
import { get } from "lodash";
import { MAX_DELIVERY_RANGE } from "../constants";

const mapStateToProps = () => {
  return (state, ownProps) => {
    const {
      match: {
        params: { venueId },
      },
    } = ownProps;

    const {
      venues: {
        data: { [venueId]: venue },
      },
    } = state;

    const {
      toDelivery: {
        deliveryRange = MAX_DELIVERY_RANGE,
        // @ts-ignore
        // minimumBasket = MIN_DELIVERY_BASKET,
        // @ts-ignore
        postalCodesWhitelist = [],
      } = {},
    } = venue;
    return {
      postalCodesWhitelist,
      deliveryRange,
      venueCoordinates:
        get(venue, ["location", "coordinates", "latitude"], null) &&
        get(venue, ["location", "coordinates", "longitude"], null)
          ? get(venue, ["location", "coordinates"], null)
          : null,
      countryCode: get(venue, "countryCode", "de") || "de",
    };
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDeliveryStreetName: (deliveryStreetName) =>
      dispatch(setDeliveryStreetName(deliveryStreetName)),
    setDeliveryCity: (deliveryCity) => dispatch(setDeliveryCity(deliveryCity)),
    setDeliveryPostalCode: (deliveryPostalCode) =>
      dispatch(setDeliveryPostalCode(deliveryPostalCode)),
    setDeliveryStreetNumber: (deliveryStreetNumber) =>
      dispatch(setDeliveryStreetNumber(deliveryStreetNumber)),
    setLocationOption: (location) => dispatch(setLocationOption(location)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GoogleDeliveryForm)
);
