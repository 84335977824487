export default {
  GET_VENUE: {
    REQUEST: "GET_VENUE.REQUEST",
    SUCCESS: "GET_VENUE.SUCCESS",
    FAILURE: "GET_VENUE.FAILURE",
  },
  SET_CURRENT_VENUE_ID: "SET_CURRENT_VENUE_ID",
  POS_MESSAGE: {
    REQUEST: "POS_MESSAGE.REQUEST",
    SUCCESS: "POS_MESSAGE.SUCCESS",
    FAILURE: "POS_MESSAGE.FAILURE",
    RESET: "POS_MESSAGE.RESET",
  },
};
