import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";
import classnames from "classnames";
import { THEME } from "../theme";

const styles = (theme) => ({
  noPadding: {
    padding: 0,
  },
  stepperRoot: {
    display: "flex",
    justifyContent: "space-around",
  },
  iconRoot: {
    display: "block",
    color: THEME.DISABLED_BUTTON_ICON,
    "&$active": {
      color: THEME.BUTTON_BACKGROUND_COLOR,
    },
    "&$completed": {
      color: theme.palette.primary.main,
    },
    "&$error": {
      color: theme.palette.error.main,
    },
  },
  active: {},
  completed: {},
  error: {},
  text: {
    fill: theme.palette.primary.contrastText,
    fontSize: theme.typography.caption.fontSize,
    fontFamily: theme.typography.fontFamily,
  },
});

const TipSelector = ({ classes, tip: currentTip, setTip }) => {
  const { t } = useTranslation();

  const tipsRange = [0, 0.03, 0.05, 0.07, 0.1];

  return (
    <div>
      <Typography variant="body2">{t("cart:tip")}</Typography>
      <Stepper
        nonLinear
        activeStep={tipsRange.findIndex((tip) => tip === currentTip)}
        connector={null}
        classes={{
          root: classes.stepperRoot,
        }}
      >
        {tipsRange.map((tip) => (
          <Step key={tip}>
            <StepButton
              classes={{
                touchRipple: classes.noPadding,
              }}
              onClick={() => setTip(tip)}
              icon={
                <SvgIcon
                  fontSize="large"
                  viewBox="0 0 32 32"
                  className={classnames(classes.iconRoot, {
                    [classes.active]: tip === currentTip,
                  })}
                >
                  <rect width="32" height="32" rx="8"></rect>
                  <text
                    className={classes.text}
                    x="16"
                    y="20"
                    textAnchor="middle"
                  >
                    {t("common:percent", { data: tip })}
                  </text>
                </SvgIcon>
              }
            />
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

TipSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  tip: PropTypes.number.isRequired,
  setTip: PropTypes.func.isRequired,
};

export default withStyles(styles)(TipSelector);
