import MenuDetailItem from "../components/MenuDetailItem";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { getUsableLocale } from "../lib/i18next";
import { venueStringsSelector } from "../redux/selectors/venue";
import { buildMinimumOptions } from "../lib/options";
import { collectAdditives } from "../lib/additives";
import { getItemAvailability } from "../lib/hours";
import {
  initItemConfiguration,
  updateNote,
  resetItemConfiguration,
} from "../redux/actions/item-configuration";
import {
  configuredOptionsSelector,
  postConfigurationUsedBenefitsSelector,
  postConfigurationBenefitsSelector,
} from "../redux/selectors/item-configuration";

const additivesSelector = createSelector(
  (venue) => venue,
  (_, itemId) => itemId,
  (venue, itemId) => collectAdditives(itemId, venue)
);

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { venueId, itemId },
    },
    routeId,
  } = ownProps;

  const {
    venues: {
      data: { [venueId]: venue },
    },
    itemConfiguration: { entries, minimumOptions },
    order,
  } = state;

  // localization and strings
  const locale = getUsableLocale();
  let venueStrings = venueStringsSelector(venue, locale);
  if (!venueStrings[itemId]) {
    venueStrings = venueStringsSelector(venue, venue.defaultLocale || "de");
  }

  const item = venue.menu.items[itemId];

  // options
  const configuredOptions = configuredOptionsSelector(
    venue,
    itemId,
    entries,
    minimumOptions
  );

  // additives for allergy information
  const additives = additivesSelector(venue, itemId);

  // benefits
  const postConfigurationUsedBenefits = postConfigurationUsedBenefitsSelector(
    venue,
    itemId,
    configuredOptions,
    order
  );
  const postConfigurationBenefits = postConfigurationBenefitsSelector(
    itemId,
    configuredOptions,
    postConfigurationUsedBenefits,
    order
  );

  // availability
  const itemAvailability = getItemAvailability(
    venue.openingHours,
    item,
    false,
    order.location,
    order.orderTime
  );

  let description = "";
  if (item.description) {
    description = venueStrings[item.description];
  } else if (item.shortDescription) {
    description = venueStrings[item.shortDescription];
  }

  return {
    name: venueStrings[itemId],
    description,
    benefits: postConfigurationBenefits,
    locale,
    itemAvailability,
    additives,
    configuredOptions,
    venue,
    images: item.images,
    itemId,
    noNotes: !!(venue.settings && venue.settings.noNotes),
    venueName: venueStrings[venueId],
    routeId,
    venueId,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    match: {
      params: { itemId },
    },
  } = ownProps;

  return {
    initItemConfiguration: (venue) => {
      const minimumOptions = buildMinimumOptions(venue, itemId);
      dispatch(initItemConfiguration(minimumOptions));
    },
    updateNote: (note) => dispatch(updateNote(note)),
    resetItemConfiguration: () => dispatch(resetItemConfiguration()),
  };
};

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  initItemConfiguration: () =>
    dispatchProps.initItemConfiguration(stateProps.venue),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(MenuDetailItem);
