import OrOption from "../components/OrOption";
import { connect } from "react-redux";
import { createBenefitsSelector } from "../redux/selectors/options";
import { withRouter } from "react-router-dom";

const mapStateToProps = () => {
  const benefitsSelector = createBenefitsSelector();
  return (state, ownProps) => {
    const {
      match: {
        params: { venueId, itemId },
      },
      checked,
    } = ownProps;

    const {
      venues: {
        data: { [venueId]: venue },
      },
    } = state;

    if (checked) {
      return {
        benefits: benefitsSelector(venue, itemId, state),
      };
    }
    return {};
  };
};

export default withRouter(connect(mapStateToProps)(OrOption));
