import React from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  VENUE_LOCATION_DELIVERY,
  VENUE_LOCATION_EAT_IN,
  VENUE_LOCATION_TO_GO,
} from "@orda/shared-constants/order-locations";
import Button from "@material-ui/core/Button";
import classNames from "classnames";
import { Typography } from "@material-ui/core";
import { THEME } from "../../theme";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  locationOptionContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    justifyContent: "center",
  },
  selectedButton: {
    color: "white",
    backgroundColor: THEME.BUTTON_BACKGROUND_COLOR,
    "&:hover": {
      backgroundColor: THEME.BUTTON_BACKGROUND_COLOR,
    },
  },
  notSelectedButton: {
    backgroundColor: THEME.DISABLED_BUTTON_ICON,
    color: "white",
    "&:hover": {
      backgroundColor: THEME.BUTTON_BACKGROUND_COLOR,
      color: "white",
    },
  },
  orderLocationButton: {
    padding: "5px",
    width: "110px",
    minHeight: "50px",
    minWidth: "60px",
    maxWidth: "150px",
    flexGrow: 1,
  },
  text: {
    marginBottom: "20px",
  },
};

const CartOrderLocationSwitcherBar = ({
  t,
  classes,
  orderLocations,
  location,
  setLocationOption,
  setOrderTimeSelected,
  setOrderTime,
  asapNotAvailable,
  setShowPreOrderTimes,
  setOpenModal,
  addressEmpty,
}) => {
  const isSelected = (orderLocation) => {
    return location === orderLocation
      ? classes.selectedButton
      : classes.notSelectedButton;
  };
  const { isAllowedDelivery, isAllowedTOGO, isAllowedDINEIN } = orderLocations;

  return (
    <>
      <Typography variant="body2" className={classes.text}>
        {t("modes")}
      </Typography>
      <div className={classes.locationOptionContainer}>
        {isAllowedTOGO && (
          <Button
            onClick={() => {
              setLocationOption(VENUE_LOCATION_TO_GO);
              if (!asapNotAvailable) {
                setOrderTimeSelected(null);
                setOrderTime(null);
              }
              setShowPreOrderTimes(false);
            }}
            className={classNames(
              classes.orderLocationButton,
              isSelected(VENUE_LOCATION_TO_GO)
            )}
          >
            {t("toGo")}
          </Button>
        )}
        {isAllowedDINEIN && (
          <Button
            onClick={() => {
              setLocationOption(VENUE_LOCATION_EAT_IN);
              if (!asapNotAvailable) {
                setOrderTimeSelected(null);
                setOrderTime(null);
              }
              setShowPreOrderTimes(false);
            }}
            className={classNames(
              classes.orderLocationButton,
              isSelected(VENUE_LOCATION_EAT_IN)
            )}
          >
            {t("dineIn")}
          </Button>
        )}
        {isAllowedDelivery && (
          <Button
            onClick={() => {
              setLocationOption(VENUE_LOCATION_DELIVERY);
              if (addressEmpty) {
                setOpenModal(true);
              }
              if (!asapNotAvailable) {
                setOrderTimeSelected(null);
                setOrderTime(null);
              }
              setShowPreOrderTimes(false);
            }}
            className={classNames(
              classes.orderLocationButton,
              isSelected(VENUE_LOCATION_DELIVERY)
            )}
          >
            {t("delivery")}
          </Button>
        )}
      </div>
    </>
  );
};

CartOrderLocationSwitcherBar.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  orderLocations: PropTypes.object.isRequired,
  location: PropTypes.number.isRequired,
  setLocationOption: PropTypes.func.isRequired,
  setOrderTime: PropTypes.func.isRequired,
  setOrderTimeSelected: PropTypes.func.isRequired,
  setShowPreOrderTimes: PropTypes.func.isRequired,
  asapNotAvailable: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  addressEmpty: PropTypes.bool.isRequired,
};

export default withTranslation("cart")(
  withStyles(styles)(CartOrderLocationSwitcherBar)
);
