import * as types from "../types";

export const getVenue = (venueId) => ({
  type: types.venues.GET_VENUE.REQUEST,
  venueId,
});

export const getVenueSuccess = (venueId, venue) => ({
  type: types.venues.GET_VENUE.SUCCESS,
  venueId,
  venue,
});

export const getVenueFailure = (venueId, error) => ({
  type: types.venues.GET_VENUE.FAILURE,
  venueId,
  error,
});

export const posMessage = (venueId, data) => ({
  type: types.venues.POS_MESSAGE.REQUEST,
  data,
  venueId,
});

export const posMessageSuccess = (result) => ({
  type: types.venues.POS_MESSAGE.SUCCESS,
  result,
});

export const posMessageFailure = (venueId, error) => ({
  type: types.venues.POS_MESSAGE.FAILURE,
  venueId,
  error,
});

export const posMessageReset = () => ({
  type: types.venues.POS_MESSAGE.RESET,
});
