import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { THEME } from "../theme";

const styles = {
  root: {
    backgroundColor: THEME.SECONDARY_BUTTON_ICON,
    padding: "0 20px",
    display: "flex",
    alignItems: "center",
    minHeight: 40,
    maxHeight: 40,
  },
  label: {
    color: "white",
    marginRight: 10,
  },
};

const LastOrderBar = ({ t, classes, orders }) => {
  let lastOrders = orders
    .filter((order) =>
      moment.unix(order.time).isBetween(moment().subtract(3, "hour"), moment())
    )
    .reverse()
    .slice(0, 5);
  if (lastOrders.length === 0) {
    // if there aren't multiple orders within the last 2 hours grab the last order
    lastOrders =
      orders.length !== 0 &&
      moment
        .utc(orders[orders.length - 1].time)
        .isAfter(moment.utc().subtract(1, "day").startOf("day"))
        ? [orders[orders.length - 1]]
        : [];
  }

  return lastOrders.length === 0 ? null : (
    <div className={classes.root}>
      <>
        <Typography variant="body2" className={classes.label}>
          {t("lastOrderLabel", { count: lastOrders.length })}{" "}
        </Typography>
        <Typography
          component="span"
          style={{ fontWeight: 900, fontSize: "1rem", color: "white" }}
        >
          {lastOrders.map((order) => order.orderNumber).join(", ")}
        </Typography>
      </>
    </div>
  );
};

LastOrderBar.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  orders: PropTypes.array.isRequired,
};

export default withTranslation("lastOrderBar")(
  withStyles(styles)(LastOrderBar)
);
