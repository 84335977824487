import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import VenueInfoModal from "../components/VenueInfoModal";
import { get } from "lodash";
import { calculatePrepTimeRange } from "../lib/venues";
import moment from "moment";
import { TrafficStatus } from "@orda/shared-constants/venue-types";

const buildOrderLocationsConfig = (venue) => {
  const isAllowedTOGO =
    venue.toGo !== undefined &&
    (venue.toGo.disabled === undefined || venue.toGo.disabled === false);
  const isAllowedDelivery =
    venue.toDelivery !== undefined &&
    venue.deliveryEnabled &&
    (venue.offersDelivery || venue.offersDeliveryBeta) &&
    (venue.toDelivery.disabled === undefined ||
      venue.toDelivery.disabled === false);
  const isAllowedDINEIN =
    venue.eatIn !== undefined &&
    (venue.eatIn.disabled === false || venue.eatIn.disabled === undefined);

  return {
    isAllowedDelivery,
    isAllowedTOGO,
    isAllowedDINEIN,
  };
};

const mapStateToProps = () => {
  return (state, ownProps) => {
    const {
      match: {
        params: { venueId },
      },
    } = ownProps;

    const {
      venues: {
        data: { [venueId]: venue },
      },
    } = state;

    const { isAllowedDelivery, isAllowedTOGO, isAllowedDINEIN } =
      buildOrderLocationsConfig(venue);

    const prepTime = {
      prepTime:
        venue.avgPrepTime ||
        calculatePrepTimeRange(
          venue.avgPrepTime,
          venue.traffic &&
            moment().diff(moment.unix(venue.traffic.lastUpdate), "minutes") <
              120
            ? venue.traffic.status
            : TrafficStatus.Normal
        ),
    };

    const modifiedOpeningHours = {};
    Object.keys(venue.openingHours)
      .filter((hoursId) => hoursId === "open")
      .forEach((hoursId) => {
        modifiedOpeningHours[hoursId] = {};
        Object.keys(venue.openingHours[hoursId]).forEach((day) => {
          modifiedOpeningHours[hoursId][day] = [];
          venue.openingHours[hoursId][day].forEach((slot) => {
            const slotCopy = { ...slot };

            if (slotCopy.endHour === 24) {
              slotCopy.endHour = 0;
            }

            modifiedOpeningHours[hoursId][day].push({
              ...slotCopy,
            });
          });
        });
      });

    return {
      venueAddress: get(venue, ["location", "address"], null),
      venueName: venue.strings[venue.defaultLocale][venueId],
      orderLocations: { isAllowedDelivery, isAllowedTOGO, isAllowedDINEIN },
      prepTime,
      openingHours: get(modifiedOpeningHours, "open", null),
      // phoneNumber: get(venue, ["billing", "phoneNumber"], null),
    };
  };
};

export default withRouter(connect(mapStateToProps)(VenueInfoModal));
