import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { THEME } from "../theme";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
  },
  appBar: {
    backgroundColor: THEME.SECONDARY_BUTTON_ICON,
    padding: "20px 0",
    width: "100%",
    textAlign: "center",
    boxShadow: "0px 4px 20px 1px rgba(0,0,0,0.2)",
    flexGrow: 0,
  },
  headerTextContainer: {
    flexGrow: 0.3,
    display: "flex",
    alignItems: "center",
  },
  explanationTextContainer: {
    flexGrow: 0.2,
    display: "flex",
    alignItems: "flex-start",
    padding: "0 40px",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  explanationText: {
    textAlign: "center",
    fontSize: "0.8rem",
    whiteSpace: "pre-line",
    width: "100%",
  },
};

const ErrorNotFound = ({ t, classes }) => (
  <div className={classes.root}>
    <div className={classes.appBar}>
      <span style={{ fontSize: "1rem" }} className="orda-icon-ORDA" />
    </div>
    <div className={classes.headerTextContainer}>
      <Typography variant="h5">{t("header")}</Typography>
    </div>
    <div className={classes.explanationTextContainer}>
      <Typography variant="body2" className={classes.explanationText}>
        {t("errorText")}
      </Typography>
      <Typography variant="body2" className={classes.explanationText}>
        {t("retryText")}
      </Typography>
    </div>
  </div>
);

ErrorNotFound.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation("errorNotFound")(
  withStyles(styles)(ErrorNotFound)
);
