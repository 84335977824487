import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import AdyenCheckout from "@adyen/adyen-web";
import { AdyenPaymentResultCode, PosMessageType } from "../../constants";
import { THEME } from "../../theme";
import ResultMessage from "../ResultMessage";
import Loading from "../Loading";

const styles = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    flexDirection: "column",
  },
  message: { fontSize: "1.4rem", fontWeight: "bold" },
  errorMessage: { color: THEME.ACCENT_RED },
  successMessage: {
    color: THEME.GREEN_TEXT,
  },
};
const redirectToMenu = (venueId, qr) =>
  window.location.replace(
    qr ? `venues/${venueId}/menu?qr=${qr}` : `venues/${venueId}/menu}`
  );
const InHouseCheckoutRedirect = ({
  t,
  location: { search },
  classes,
  order,
  clearAdyenPaymentSession,
  posMessage,
  adyenPaymentState,
  venueId,
}) => {
  const [redirectState, setRedirectState] = useState({
    success: false,
    error: false,
  });
  const sessionId = new URLSearchParams(search).get("sessionId");
  const redirectResult = new URLSearchParams(search).get("redirectResult");
  const payload = new URLSearchParams(search).get("payload");
  const parsedPayload = JSON.parse(atob(payload));

  useEffect(() => {
    const { config } = adyenPaymentState;
    const { amount, orderRef, tableId, clientKey } = parsedPayload;
    async function createAdyenCheckout() {
      const checkout = await AdyenCheckout({
        ...config,
        clientKey,
        session: { id: sessionId },
        onPaymentCompleted: (result) => {
          if (result.resultCode === AdyenPaymentResultCode.Authorised) {
            setRedirectState({ ...redirectState, error: false, success: true });
            posMessage(venueId, {
              order,
              payment: {
                ...result,
                orderRef,
                amount: amount && (+amount / 100).toFixed(2),
              },
              type: PosMessageType.PaymentRequest,
            });
          } else {
            setRedirectState({ ...redirectState, error: true, success: false });
            redirectToMenu(venueId, tableId);
          }
          clearAdyenPaymentSession();
        },
        onError: (error) => {
          setRedirectState({ ...redirectState, error: !!error });
          redirectToMenu(venueId, tableId);
        },
      });
      checkout.submitDetails({ details: { redirectResult } }); // we finalize the redirect flow with the received payload
    }
    createAdyenCheckout();
  }, [adyenPaymentState]);

  return (
    <div className={classes.root}>
      {redirectState.error && (
        <ResultMessage
          message={t("checkoutError")}
          subMessage={t("redirectMessage")}
          isError={redirectState.error}
        />
      )}
      {redirectState.success && (
        <ResultMessage
          message={t("checkoutSuccess")}
          subMessage={t("redirectMessage")}
          isError={false}
        />
      )}
      <Loading />
    </div>
  );
};

InHouseCheckoutRedirect.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  venueId: PropTypes.string.isRequired,
  order: PropTypes.any.isRequired,
  adyenPaymentState: PropTypes.object.isRequired,
  clearAdyenPaymentSession: PropTypes.any.isRequired,
  posMessage: PropTypes.any.isRequired,
  location: PropTypes.object.isRequired,
};
export default withTranslation("inHouseCheckout")(
  withStyles(styles)(InHouseCheckoutRedirect)
);
