import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { withTranslation } from "react-i18next";
import {
  PRICE_DISPLAY_DEFAULT,
  PRICE_DISPLAY_ONLY_AFTER_BENEFIT,
} from "@orda/shared-constants-js/price-display";
import { replaceTrailingSpaces } from "../util/strings";

const styles = {
  priceBeforeBenefits: {
    display: "flex",
  },
  priceAfterBenefits: {
    display: "flex",
  },
  priceDisplay: {
    fontSize: "0.9em",
    fontWeight: "900",
  },
  priceDisplayStrikeThrough: {
    textDecoration: "line-through",
  },
};

class Price extends React.Component {
  state = {};

  render() {
    const { remark: remarkState } = this.state;
    const {
      t,
      currency,
      priceBeforeBenefits,
      hideFree,
      priceAfterBenefitsRaw,
      usedBenefit,
      prefix,
      priceDisplay,
      minimumOptionsPriceBeforeBenefits,
      minimumOptionsPriceAfterBenefits,
      classes,
    } = this.props;

    const remark =
      usedBenefit && usedBenefit.remark && remarkState >= 0
        ? remarkState + 1
        : "";

    const fullPriceBeforeBenefits =
      priceBeforeBenefits + minimumOptionsPriceBeforeBenefits;
    const fullPriceAfterBenefits = Math.round(
      priceAfterBenefitsRaw + minimumOptionsPriceAfterBenefits
    );

    const benefitApplied = fullPriceBeforeBenefits !== fullPriceAfterBenefits;

    const displayBeforeBenefits =
      priceDisplay !== PRICE_DISPLAY_ONLY_AFTER_BENEFIT || !benefitApplied;

    return (
      <div className={classes.priceContainer}>
        {displayBeforeBenefits &&
          (fullPriceBeforeBenefits > 0 ? (
            <div className={classes.priceBeforeBenefits}>
              <Typography
                component="span"
                className={classNames(classes.priceDisplay, {
                  [classes.priceDisplayStrikeThrough]: benefitApplied,
                })}
              >
                {minimumOptionsPriceBeforeBenefits > 0
                  ? replaceTrailingSpaces(t("ui-parts:startingFromPrefix"))
                  : prefix}
              </Typography>
              <Typography
                component="span"
                className={classNames(classes.priceDisplay, {
                  [classes.priceDisplayStrikeThrough]: benefitApplied,
                })}
              >
                {t("common:money", {
                  data: { currency, value: fullPriceBeforeBenefits },
                })}
              </Typography>
            </div>
          ) : (
            !hideFree && (
              <Typography component="span" className={classes.priceDisplay}>
                {t("ui-parts:freeLabel")}
              </Typography>
            )
          ))}

        {benefitApplied &&
          (fullPriceAfterBenefits > 0 ? (
            <div className={classes.priceAfterBenefits}>
              <Typography component="span" className={classes.priceDisplay}>
                {replaceTrailingSpaces(
                  minimumOptionsPriceBeforeBenefits > 0
                    ? t("ui-parts:startingFromPrefix")
                    : prefix
                )}
              </Typography>
              <Typography component="span" className={classes.priceDisplay}>
                {t("common:money", {
                  data: { currency, value: fullPriceAfterBenefits },
                })}
              </Typography>
              {/* <TextWithExponent base="" exponent={remark} /> */}
            </div>
          ) : (
            <>
              <Typography component="span" className={classes.priceDisplay}>
                {t("ui-parts:freeLabel")}
              </Typography>
              <Typography component="span" className={classes.priceDisplay}>
                {remark}
              </Typography>
            </>
          ))}
      </div>
    );
  }
}

Price.propTypes = {
  t: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  priceBeforeBenefits: PropTypes.number.isRequired,
  hideFree: PropTypes.bool,
  priceAfterBenefitsRaw: PropTypes.number.isRequired,
  priceDisplay: PropTypes.number,
  usedBenefit: PropTypes.object,
  prefix: PropTypes.string,
  minimumOptionsPriceBeforeBenefits: PropTypes.number,
  minimumOptionsPriceAfterBenefits: PropTypes.number,
  classes: PropTypes.object.isRequired,
};

Price.defaultProps = {
  usedBenefit: null,
  priceDisplay: PRICE_DISPLAY_DEFAULT,
  hideFree: false,
  prefix: "",
  minimumOptionsPriceBeforeBenefits: 0,
  minimumOptionsPriceAfterBenefits: 0,
};

export default withTranslation()(withStyles(styles)(Price));
