import React from "react";
import { THEME } from "../../theme";
import PropTypes from "prop-types";

const DefaultPaymentMethod = ({ fill }) => (
  <svg height="100%" width="100%" fill={fill} viewBox="0 0 128 128">
    <path
      d="m31.305 39.242c-2.8008 0-5.0898 2.3125-5.0898 5.1172v15.73h57.328v-15.73c0-2.8047-2.2891-5.1133-5.0898-5.1133zm52.242 20.848v5.2109h8.6055c0.92969 2.0703 2.4531 3.707 4.4219 4.7148v8.8203c-1.9648 1.0039-3.4883 2.6406-4.418 4.707h-20.246c4.9805-0.058594 9.0273-4.1289 9.0273-9.1211 0-0.64062-0.085938-1.2617-0.21484-1.8672 1.6602-0.84375 2.8203-2.5586 2.8203-4.543v-2.707h-57.328v2.707c0 2.8047 2.2891 5.1094 5.0898 5.1094h10.547v10.555c0 2.793 2.293 5.0781 5.0898 5.0781h49.758c2.793 0.003906 5.0859-2.2812 5.0859-5.0781v-18.5c0-2.793-2.293-5.0859-5.0898-5.0859zm-49.512-15.637h28.664v2.6055h-28.664zm31.27 0h5.2109v2.6055h-5.2109zm7.8164 0h5.2109v2.6055h-5.2109zm-39.09 5.2109h7.8164v7.8828h-7.8164zm13.031 23.453h15.711c-0.0625 0.42578-0.10156 0.85937-0.10156 1.3047 0 4.9922 4.0547 9.0664 9.0352 9.1211h-20.219c-0.92969-2.0664-2.457-3.7031-4.4219-4.707z"
      fill={fill}
    />
  </svg>
);

DefaultPaymentMethod.propTypes = {
  fill: PropTypes.string,
};
DefaultPaymentMethod.defaultProps = {
  fill: THEME.WHITE_TEXT_COLOR,
};

export default DefaultPaymentMethod;
