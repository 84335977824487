import { connect } from "react-redux";
import Venue from "../components/Venue";
import { getVenue } from "../redux/actions/venues";
import {
  setTableId,
  setVenueId,
  setLocationOption,
} from "../redux/actions/order";

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { venueId },
    },
  } = ownProps;

  const {
    venues: {
      requests: {
        getVenue: { [venueId]: getVenueRequest = {} },
      },
      data: { [venueId]: venue },
    },
    user: { orders },
  } = state;

  return {
    venueId,
    venueLoaded: !!venue && !getVenueRequest.processing,
    venue,
    orders,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    match: {
      params: { venueId },
    },
  } = ownProps;

  return {
    getVenue: () => dispatch(getVenue(venueId)),
    setTableId: (id) => dispatch(setTableId(id)),
    setVenueId: () => dispatch(setVenueId(venueId)),
    setLocationOption: (location) => dispatch(setLocationOption(location)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Venue);
