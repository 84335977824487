import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import scriptLoader from "react-async-script-loader";
import { PAYPAL_CONFIG, API_ENDPOINT } from "../config";
import { getUsableLocale } from "../lib/i18next";
import Deferred from "../util/deferred";
import routes from "../routes";
import CircularProgress from "@material-ui/core/CircularProgress";
import { store } from "../redux/store";
import {
  convertMonetaryValue,
  logRevenue,
  getSessionId,
} from "../lib/analytics";
import { Paper, Typography } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { THEME } from "../theme";
import { PaymentOption } from "@orda/shared-constants";

const styles = {
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 45,
  },
};

let PaypalButtonVendor;

const initializePaypal = () => {
  if (!PaypalButtonVendor) {
    PaypalButtonVendor = global.paypal.Button.driver("react", {
      React,
      ReactDOM,
    });
  }
};

const getLastPreparedOrder = () => store.getState().order.preparedOrder;

const PaypalButton = ({
  // checkout.js loading
  isScriptLoaded,
  isScriptLoadSucceed,
  // authUser to perform API calls
  authUser,
  authSyncFinished,
  // common actions
  executeOrderSuccess,
  executeOrderFailure,
  injectError,
  // via cart
  notifyPromise,
  awaitExecuteOrderFinish,
  //
  // for express orders
  //
  // whether this button triggers an express order
  express,
  // skip add to cart
  skipAddToCart,
  // dispatch actions on store
  expressOrderSuccess,
  expressOrderFailure,
  venueId,
  // to inject item into the order
  itemId,
  // configured options for item
  configuredOptions,
  // note for item
  note,
  // item availability
  itemAvailable,
  // used to navigate to other pages
  navigate,
  // set an order as express order
  setExpress,
  // prepare order in case of an express order
  prepareOrder,
  // add paypal checkout as the current payment method
  currentPaymentMethod,
  addPaypalCheckout,
  // number of items in cart to trigger redirect to cart
  countItems,
  // add item to cart
  addToCart,
  // clear order
  clearOrder,
  // clear prepared order
  clearPreparedOrder,
  // reset item config
  resetItemConfiguration,
  // translation
  t,
  // classes
  classes,
  // is all data valid in the cart (delivery form, minimum basket)
  isReadyToOrder,
  // if the venue has his own paypal account
  paypalClientId,
}) => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    if (isScriptLoaded && isScriptLoadSucceed) {
      initializePaypal();
    }
  }, [isScriptLoaded, isScriptLoadSucceed]);

  useEffect(() => {
    if (isScriptLoaded && isScriptLoadSucceed && authSyncFinished) {
      setShowButton(true);
    }
  }, [isScriptLoaded, isScriptLoadSucceed, authSyncFinished]);
  let locale = "de_DE";
  if (
    !global.navigator.language
      .replace(/-/gi, "_")
      .match(/^[a-z]{2}[_][A-Z][A-Z0-9]$/)
  ) {
    if (getUsableLocale() === "en") {
      locale = "en_US";
    }
  } else {
    locale = global.navigator.language.replace(/-/gi, "_");
  }

  if (!isReadyToOrder()) {
    return (
      <Paper className={classes.container}>
        <Typography
          variant="body2"
          style={{ fontWeight: "bold", color: THEME.ACCENT_RED }}
        >
          {t("errorValidation")}
        </Typography>
      </Paper>
    );
  }

  return !showButton ? (
    <div className={classes.container}>
      <CircularProgress size={20} />
    </div>
  ) : (
    <PaypalButtonVendor
      commit
      locale={locale}
      env={PAYPAL_CONFIG.environment}
      style={{
        label: "buynow",
        branding: true,
        size: "responsive",
        shape: "rect",
        color: "black",
        tagline: false,
        height: 45,
      }}
      client={
        paypalClientId
          ? { [PAYPAL_CONFIG.environment]: paypalClientId }
          : PAYPAL_CONFIG.client
      }
      payment={async (_, actions) => {
        if (express) {
          if (!skipAddToCart) {
            addToCart(venueId, itemId, itemAvailable, configuredOptions, note);

            if (countItems > 0) {
              navigate(routes.cart.template(venueId));
              return null;
            }
            // this is now an express order
            setExpress(true);
          }

          // this is will never be the case for now
          // create paypal payment object if it doesn't exist
          if (
            !currentPaymentMethod ||
            currentPaymentMethod.type !== PaymentOption.PayPalCheckout ||
            currentPaymentMethod.type !== PaymentOption.PayPalPrivateCheckout
          ) {
            addPaypalCheckout();
          }

          // prepare order and wait until it finishes
          const prepareOrderNotifyDeferred = new Deferred();
          prepareOrder(venueId, prepareOrderNotifyDeferred);
          try {
            await prepareOrderNotifyDeferred.promise;
          } catch (error) {
            navigate(routes.cart.template(venueId));
            setExpress(false);
            expressOrderFailure();
          }
        }

        const authToken = await authUser.getIdToken();
        const result = await actions.request({
          method: "POST",
          url: `${API_ENDPOINT}/users/${authUser.uid}/orders/${
            getLastPreparedOrder().orderId
          }/paypal/create-payment`,
          "Accept-Language": getUsableLocale(),
          "Content-Type": "application/json",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Amplitude-Session": getSessionId(),
          },
        });
        if (awaitExecuteOrderFinish) {
          awaitExecuteOrderFinish();
        }
        return result.id;
      }}
      onAuthorize={async (data, actions) => {
        const authToken = await authUser.getIdToken();
        try {
          setShowButton(false);
          const order = await actions.request({
            method: "POST",
            url: `${API_ENDPOINT}/users/${authUser.uid}/orders/${
              getLastPreparedOrder().orderId
            }/execute`,
            "Accept-Language": getUsableLocale(),
            "Content-Type": "application/json",
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Amplitude-Session": getSessionId(),
            },
            json: {
              userId: authUser.uid,
              orderId: getLastPreparedOrder().orderId,
              paypalPaymentId: data.paymentID,
              paypalPayerId: data.payerID,
              metadata: {
                platform: "web",
                userAgent: global.navigator.userAgent,
              },
            },
          });
          executeOrderSuccess(order);

          logRevenue(
            order.venueId,
            1,
            convertMonetaryValue(order.currency, order.sumTotal)
            // event properties are not currently tracked with revenue since the
            // react-native package doesn't support it
          );

          if (express) {
            navigate(routes.orders.success.path);
            clearOrder();
            resetItemConfiguration();
            expressOrderSuccess();
          }

          if (notifyPromise) {
            notifyPromise.resolve();
          }
        } catch (error) {
          executeOrderFailure(error);
          // needed here since the error page will be displayed
          injectError(error);

          if (express) {
            navigate(routes.errors.operation.path);
            clearPreparedOrder();
            expressOrderFailure();
          }

          if (notifyPromise) {
            notifyPromise.reject();
          }
        }
      }}
    />
  );
};

PaypalButton.propTypes = {
  venueId: PropTypes.string.isRequired,
  itemId: PropTypes.string,
  isScriptLoaded: PropTypes.bool.isRequired,
  isScriptLoadSucceed: PropTypes.bool.isRequired,
  authUser: PropTypes.object,
  authSyncFinished: PropTypes.bool.isRequired,
  executeOrderSuccess: PropTypes.func.isRequired,
  executeOrderFailure: PropTypes.func.isRequired,
  injectError: PropTypes.func.isRequired,
  notifyPromise: PropTypes.object,
  awaitExecuteOrderFinish: PropTypes.func,
  express: PropTypes.bool,
  navigate: PropTypes.func.isRequired,
  expressOrderFailure: PropTypes.func.isRequired,
  expressOrderSuccess: PropTypes.func.isRequired,
  prepareOrder: PropTypes.func.isRequired,
  setExpress: PropTypes.func.isRequired,
  addPaypalCheckout: PropTypes.func.isRequired,
  currentPaymentMethod: PropTypes.object,
  configuredOptions: PropTypes.object,
  itemAvailable: PropTypes.bool,
  note: PropTypes.object,
  countItems: PropTypes.number,
  addToCart: PropTypes.func.isRequired,
  clearOrder: PropTypes.func.isRequired,
  clearPreparedOrder: PropTypes.func.isRequired,
  resetItemConfiguration: PropTypes.func.isRequired,
  skipAddToCart: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  isReadyToOrder: PropTypes.func.isRequired,
  paypalClientId: PropTypes.string,
};

PaypalButton.defaultProps = {
  express: false,
  authUser: null,
  awaitExecuteOrderFinish: null,
  notifyPromise: null,
  currentPaymentMethod: null,
  itemId: null,
  configuredOptions: null,
  itemAvailable: null,
  note: null,
  countItems: null,
  skipAddToCart: null,
  paypalClientId: null,
};

export default withTranslation("paypalButton")(
  scriptLoader("https://www.paypalobjects.com/api/checkout.js")(
    withStyles(styles)(PaypalButton)
  )
);
