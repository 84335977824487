import React from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import AppRoot from "./AppRoot";
import { store, persistor } from "../redux/store";
import { PersistGate } from "redux-persist/integration/react";
import Loading from "./Loading";
import "../lib/i18next";
import { StripeProvider } from "react-stripe-elements";
import { STRIPE_PUBLISHABLE_KEY } from "../config";
import { ConnectedRouter } from "connected-react-router";
import history from "../history";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#6d6d6d",
      main: "#000000",
      dark: "#000000",
      contrastText: "#fff",
    },
    secondary: {
      light: "#999999",
      main: "#ffffff",
      dark: "#333333",
      contrastText: "#000",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 4,
      },
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: "Open Sans",
    fontDisplay: "block",
  },
});

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MuiThemeProvider theme={theme}>
        <PersistGate loading={<Loading />} persistor={persistor}>
          <StripeProvider apiKey={STRIPE_PUBLISHABLE_KEY}>
            <AppRoot />
          </StripeProvider>
        </PersistGate>
      </MuiThemeProvider>
    </ConnectedRouter>
  </Provider>
);

export default App;
