import { takeEvery, call, put, all, race, delay } from "redux-saga/effects";
import * as types from "../types";
import {
  posMessageTransport,
  getVenue as getVenueTransport,
} from "../../api/venues";
import {
  getVenueSuccess,
  getVenueFailure,
  posMessageSuccess,
  posMessageFailure,
} from "../actions/venues";
import history from "../../history";
import routes from "../../routes";
import { injectError } from "../actions/errors";
import { store } from "../store";
import {
  fetchBenefitsFees,
  removeItem,
  setLocationOption,
  setOrderTime,
  setTableId,
  setVenueId,
} from "../actions/order";
import {
  AdyenPaymentResultCode,
  DEFAULT_API_REQUEST_TIMEOUT,
  PosMessageType,
  PosPaymentType,
  SOCKET_EVENT_TARGET_TYPE,
  paymentOptionsDefault,
} from "../../constants";
import { clearCurrentPaymentMethod } from "../actions/user";
import { setPaymentRequest } from "../actions/payments";
import { VENUE_LOCATION_TO_GO } from "@orda/shared-constants/order-locations";
import { get } from "lodash";

// eslint-disable-next-line func-names
const withTimeout = function* (
  { saga, sagaParams = null },
  duration = DEFAULT_API_REQUEST_TIMEOUT
) {
  const { result, timeout } = yield race({
    result: call(saga, sagaParams),
    timeout: delay(duration),
  });
  if (timeout) {
    throw new Error("Request timeout");
  } else {
    return result;
  }
};

function* getVenueSaga(action) {
  try {
    yield put(setOrderTime(null));
    const storedVenueId = store.getState().order.venueId;
    if (storedVenueId !== action.venueId) {
      yield put(setTableId(null));
      yield put(setLocationOption(VENUE_LOCATION_TO_GO));
    }
    const venue = yield call(getVenueTransport, action.venueId);
    const { user } = store.getState();
    if (user && user.currentPaymentMethod) {
      let paymentOptions = !!venue && venue.paymentOptions;
      if (!paymentOptions || paymentOptions.length <= 0) {
        paymentOptions = paymentOptionsDefault;
      }
      const currentPaymentMethodInternal = user.currentPaymentMethod
        ? user.paymentMethods.find(
            (paymentMethod) => paymentMethod.id === user.currentPaymentMethod
          )
        : null;
      if (
        currentPaymentMethodInternal &&
        !paymentOptions.find(
          (paymentOption) =>
            paymentOption.id === currentPaymentMethodInternal.type
        )
      ) {
        yield put(clearCurrentPaymentMethod());
        yield put(setPaymentRequest(null));
      }
    }

    yield put(getVenueSuccess(action.venueId, venue));
    yield all(
      store
        .getState()
        .order.items.map((item) => item.itemId)
        // eslint-disable-next-line consistent-return, array-callback-return
        .map((itemId) => {
          if (
            !Object.keys(
              store.getState().venues.data[action.venueId].menu.items
            ).includes(itemId)
          ) {
            return put(removeItem(itemId));
          }
        })
    );
    yield put(setVenueId(action.venueId));
    yield put(fetchBenefitsFees(action.venueId));
  } catch (error) {
    yield put(getVenueFailure(action.venueId, error));
    yield put(injectError(error));
    yield call([history, history.push], routes.errors.operation.path);
  }
}

function* posMessageSaga({
  venueId,
  data: {
    order,
    reason,
    customerInfo,
    type = PosMessageType.CallWaiter,
    payment,
  },
}) {
  try {
    let payload = null;
    const { tableId, table = null } = order.preparedOrder
      ? order.preparedOrder
      : order;
    switch (type) {
      case PosMessageType.CallWaiter:
        payload = {
          reason,
          customerInfo,
          target: {
            type: SOCKET_EVENT_TARGET_TYPE.TABLE,
            nr: table,
            id: tableId,
          },
          type: PosMessageType.CallWaiter,
        };
        break;
      case PosMessageType.InfoRequest:
        payload = {
          target: {
            type: SOCKET_EVENT_TARGET_TYPE.TABLE,
            nr: table,
            id: tableId,
          },
          type: PosMessageType.InfoRequest,
        };
        break;
      case PosMessageType.PaymentRequest: {
        let receipt;
        if (payment) {
          receipt = {
            payment: {
              payItems: [
                {
                  payMethod: {
                    id: PosPaymentType.OnlinePayment,
                  },
                  transaction: {
                    amount: payment.amount,
                    transNr: payment.orderRef,
                    resultCode: payment.resultCode,
                    success:
                      payment.resultCode === AdyenPaymentResultCode.Authorised,
                  },
                },
              ],
            },
          };
        }
        payload = {
          target: {
            type: SOCKET_EVENT_TARGET_TYPE.TABLE,
            nr: table,
            id: tableId,
            receipt,
          },
          type: PosMessageType.PaymentRequest,
        };
        break;
      }
      default:
        break;
    }
    const result = yield call(posMessageTransport, venueId, payload);
    if (!result.success) {
      yield put(posMessageFailure(venueId, result.errorMsg));
    }
    const receiptUrl = get(
      result,
      "target.receipt.digitalInvoiceServerUrl",
      null
    );
    const receiptUrlId = get(result, "target.receipt.digitalInvoiceGuid", null);
    if (receiptUrl && receiptUrlId) {
      window.location.replace(`${receiptUrl}/${receiptUrlId}`);
    }
    yield put(posMessageSuccess(result));
  } catch (error) {
    yield put(posMessageFailure(venueId, error));
  }
}

function* posMessageSagaWithTimeout(action) {
  try {
    yield call(withTimeout, {
      saga: posMessageSaga,
      sagaParams: action,
    });
  } catch (error) {
    yield put(posMessageFailure(action.venueId, error));
  }
}

export default function* venuesRootSaga() {
  yield all([
    yield takeEvery(types.venues.GET_VENUE.REQUEST, getVenueSaga),
    yield takeEvery(
      types.venues.POS_MESSAGE.REQUEST,
      posMessageSagaWithTimeout
    ),
  ]);
}
