import { connect } from "react-redux";
import AddressForm from "../../components/Cart/AddressForm";
import {
  setDeliveryStreetName,
  setUserName,
  setUserEmail,
  setDeliveryAdditionalInformation,
  setDeliveryCity,
  setDeliveryPostalCode,
  setDeliveryStreetNumber,
  setPhoneNumber,
} from "../../redux/actions/order";
import { get } from "lodash";
import { withRouter } from "react-router-dom";
import { MAX_DELIVERY_RANGE } from "../../constants";

const mapStateToProps = (state, ownProps) => {
  const { order } = state;
  const {
    match: {
      params: { venueId },
    },
  } = ownProps;

  const {
    venues: {
      data: { [venueId]: venue },
    },
  } = state;

  const {
    toDelivery: {
      deliveryRange = MAX_DELIVERY_RANGE,
      // @ts-ignore
      // minimumBasket = MIN_DELIVERY_BASKET,
      // @ts-ignore
      postalCodesWhitelist = [],
    } = {},
  } = venue;

  return {
    location: order.location,
    userName: order.userName,
    userEmail: order.userEmail,
    phoneNumber: order.phoneNumber,
    deliveryStreetName: order.deliveryStreetName,
    deliveryStreetNumber: order.deliveryStreetNumber,
    deliveryCity: order.deliveryCity,
    deliveryPostalCode: order.deliveryPostalCode,
    deliveryAdditionalInformation: order.deliveryAdditionalInformation,
    venueCoordinates:
      get(venue, ["location", "coordinates", "latitude"], null) &&
      get(venue, ["location", "coordinates", "longitude"], null)
        ? get(venue, ["location", "coordinates"], null)
        : null,
    postalCodesWhitelist,
    deliveryRange,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDeliveryStreetName: (deliveryStreetName) =>
      dispatch(setDeliveryStreetName(deliveryStreetName)),
    setDeliveryAdditionalInformation: (deliveryAdditionalInformation) =>
      dispatch(setDeliveryAdditionalInformation(deliveryAdditionalInformation)),
    setDeliveryCity: (deliveryCity) => dispatch(setDeliveryCity(deliveryCity)),
    setDeliveryPostalCode: (deliveryPostalCode) =>
      dispatch(setDeliveryPostalCode(deliveryPostalCode)),
    setDeliveryStreetNumber: (deliveryStreetNumber) =>
      dispatch(setDeliveryStreetNumber(deliveryStreetNumber)),
    setUserName: (userName) => dispatch(setUserName(userName)),
    setUserEmail: (userEmail) => dispatch(setUserEmail(userEmail)),
    setPhoneNumber: (phoneNumber) => dispatch(setPhoneNumber(phoneNumber)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddressForm)
);
