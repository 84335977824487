import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { Plus, Minus } from "mdi-material-ui";
import PriceCart from "../PriceCart";
import CartItemOptions from "./CartItemOptions";
import { logEvent, convertMonetaryValue } from "../../lib/analytics";
import {
  ORDER_DECREMENT_ITEM_COUNT_CART,
  ORDER_INCREMENT_ITEM_COUNT_CART,
} from "../../lib/analytics/events";
import { withTranslation } from "react-i18next";
import { getItemUnvailableExplanationText } from "../../lib/hours";
import { THEME } from "../../theme";

const styles = {
  cartItemGroupContainer: {
    display: "flex",
    marginBottom: "0.75rem",
    paddingBottom: "0.75rem",
    borderBottom: "1px solid #e1e1e1",
    "&:last-child": {
      borderBottom: "none",
      marginBottom: "0",
      paddingBottom: "0",
    },
  },
  itemsDetailContainer: {
    width: "100%",
    marginLeft: "1rem",
    display: "flex",
    flexDirection: "column",
  },
  itemName: {
    fontWeight: 600,
  },
  countContainer: {
    display: "flex",
    alignItems: "center",
  },
  itemDetailSegmentContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  note: {
    fontStyle: "italic",
    fontSize: "0.8em",
  },
  itemUnavailable: {
    fontSize: "0.9rem",
    fontWeight: "400",
    opacity: 0.5,
    color: THEME.ACCENT_RED,
  },
};

const CartItem = ({
  t,
  classes,
  amount,
  itemName,
  priceBeforeBenefits,
  priceAfterBenefits,
  currency,
  remark,
  id: itemId,
  orderOptions,
  increaseAmount,
  decreaseAmount,
  trackingVenueId,
  trackingVenueName,
  trackingItemName,
  note,
  itemAvailability,
}) => {
  if (!itemId) {
    return null;
  }
  const explanation = useMemo(
    () =>
      itemAvailability.reason && itemAvailability.nextAvailable
        ? getItemUnvailableExplanationText(
            t,
            itemAvailability,
            trackingVenueName,
            trackingItemName
          )
        : "",
    [itemAvailability]
  );

  return (
    <div className={classes.cartItemGroupContainer}>
      <div className={classes.countContainer}>
        <IconButton
          onClick={() => {
            logEvent(ORDER_DECREMENT_ITEM_COUNT_CART, {
              venueId: trackingVenueId,
              venueName: trackingVenueName,
              itemId,
              itemName: trackingItemName,
              price: convertMonetaryValue(currency, priceBeforeBenefits),
              count: amount - 1,
            });
            decreaseAmount();
          }}
        >
          <Minus fontSize="small" />
        </IconButton>
        <Typography component="span">{amount}</Typography>
        <IconButton
          onClick={() => {
            logEvent(ORDER_INCREMENT_ITEM_COUNT_CART, {
              venueId: trackingVenueId,
              venueName: trackingVenueName,
              itemId,
              itemName: trackingItemName,
              price: convertMonetaryValue(currency, priceBeforeBenefits),
              count: amount - 1,
            });
            increaseAmount();
          }}
        >
          <Plus fontSize="small" />
        </IconButton>
      </div>
      <div className={classes.itemsDetailContainer}>
        <div className={classes.itemDetailSegmentContainer}>
          <div>
            <Typography variant="body2" className={classes.itemName}>
              {itemName}
            </Typography>
            {!!explanation && (
              <Typography className={classes.itemUnavailable} variant="body1">
                {explanation}
              </Typography>
            )}
          </div>
          <PriceCart
            hideFree
            priceBeforeBenefits={amount * priceBeforeBenefits}
            priceAfterBenefits={amount * priceAfterBenefits}
            currency={currency}
            remark={remark}
            itemId={itemId}
          />
        </div>
        <CartItemOptions
          option={orderOptions}
          amount={amount}
          currency={currency}
          level={0}
        />
        {!!note && (
          <div className={classes.noteContainer}>
            <Typography variant="body2" className={classes.note}>
              {`${t("noteLabel")}: ${note}`}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

CartItem.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  amount: PropTypes.number.isRequired,
  itemName: PropTypes.string.isRequired,
  priceBeforeBenefits: PropTypes.number.isRequired,
  priceAfterBenefits: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  remark: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  id: PropTypes.string.isRequired,
  orderOptions: PropTypes.object,
  increaseAmount: PropTypes.func.isRequired,
  decreaseAmount: PropTypes.func.isRequired,
  trackingVenueId: PropTypes.string.isRequired,
  trackingVenueName: PropTypes.string.isRequired,
  trackingItemName: PropTypes.string.isRequired,
  itemAvailability: PropTypes.object.isRequired,
  note: PropTypes.string,
};

CartItem.defaultProps = {
  orderOptions: null,
  remark: null,
  note: null,
};

export default withTranslation("cart")(withStyles(styles)(CartItem));
