export const PROCESSING_CANCELLED_PROMISE = "PROCESSING_CANCELLED_PROMISE";

export default class Deferred {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.internalResolve = resolve;
      this.internalReject = reject;
      this.cancelled = false;
    });
  }

  resolve = (data) => {
    if (!this.cancelled) {
      this.internalResolve(data);
    } else {
      // cannot resolve cancelled promise
      this.internalReject(new Error(PROCESSING_CANCELLED_PROMISE));
    }
  };

  reject = (error) => {
    if (!this.cancelled) {
      this.internalReject(error);
    } else {
      // cannot reject cancelled promise
      this.internalReject(new Error(PROCESSING_CANCELLED_PROMISE));
    }
  };
}
