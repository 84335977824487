import { createSelector } from "reselect";

import { groupItems } from "../../lib/group-items";
import { getUsableLocale } from "../../lib/i18next";

/**
 *
 *
 *
 * @param  {object} venue
 * @param  {arrayOf string} strings
 * @param  {arrayOf object} benefits
 * @param  {} expandableBenefits
 * @param  {} orderLocation
 *
 * @returns {
 * totalPrice,
 * sectionResults,
 * remarks, // ** final remarks
 * }
 */
export const groupedItemsSelector = createSelector(
  (venue) => venue,
  (_, strings) => strings,
  (_, __, order) => order.items,
  (_, __, order) => order.feesBenefits && order.feesBenefits.benefits,
  (_, __, order) => order.feesBenefits && order.feesBenefits.expandableBenefits,
  (_, __, order) => order.location,
  (venue, strings, orderItems, benefits, expandableBenefits, orderLocation) =>
    groupItems(
      orderItems,
      venue,
      strings,
      benefits,
      expandableBenefits,
      getUsableLocale(),
      orderLocation
    )
);
