import React from "react";
import PropTypes from "prop-types";
import { IconButton, withStyles } from "@material-ui/core";
import { Elements } from "react-stripe-elements";
import CreditCardForm from "../../containers/CreditCardForm/CreditCardForm";
import SecurityNotice from "../SecurityNotice";
import { THEME } from "../../theme";
import routes from "../../routes";
import { Link } from "react-router-dom";
import { ArrowLeft } from "mdi-material-ui";

const styles = {
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    backgroundColor: "#fafafa",
  },
  appBar: {
    display: "flex",
    backgroundColor: THEME.SECONDARY_BUTTON_ICON,
    boxShadow: "0px 4px 20px 1px rgba(0,0,0,0.2)",
    position: "sticky",
    height: 50,
    top: 0,
    zIndex: 3,
  },
  formContainer: {
    flexGrow: 3,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  securityNoticeContainer: {
    flexGrow: 1,
    padding: "0 40px",
  },
  iconButton: {
    color: THEME.PRIMARY_BUTTON_ICON,
    backgroundColor: THEME.SECONDARY_BUTTON_ICON,
    "&:hover": {
      opacity: 0.8,
    },
    borderRadius: 0,
    fontSize: "clamp(0.7rem, 3vmin, 1rem)",
  },
  header: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
};

const CreditCardFormWrapper = ({ classes, location: { search } }) => {
  const searchParams = new URLSearchParams(search);
  const venueId = searchParams.get("cartVenueId");
  return (
    <div className={classes.root}>
      <div className={classes.appBar}>
        <IconButton
          className={classes.iconButton}
          component={Link}
          to={routes.payments.add.template(venueId)}
        >
          <ArrowLeft />
        </IconButton>
        <div className={classes.header}>
          <span style={{ fontSize: "1rem" }} className="orda-icon-ORDA" />
        </div>
      </div>
      <div className={classes.formContainer}>
        <Elements>
          <CreditCardForm />
        </Elements>
      </div>
      <div className={classes.securityNoticeContainer}>
        <SecurityNotice />
      </div>
    </div>
  );
};

CreditCardFormWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreditCardFormWrapper);
