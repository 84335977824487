import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import { getUsableLocale } from "../lib/i18next";
import { venueStringsSelector } from "../redux/selectors/venue";
import { Trans, withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
  VENUE_LOCATION_EAT_IN,
  VENUE_LOCATION_DELIVERY,
  VENUE_LOCATION_TO_GO,
} from "@orda/shared-constants/order-locations";

const styles = {
  root: {
    maxHeight: 40,
    minHeight: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "4px 10px",
    flexDirection: "column",
  },
  text: {
    fontSize: "clamp(0.25rem, 2.75vmin, 0.65rem)",
    fontWeight: 600,
    textALign: "center",
  },
};

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { venueId },
    },
  } = ownProps;

  const {
    venues: {
      data: { [venueId]: venue },
    },
    user: { orders },
    order: { location },
  } = state;

  let orderLocation = location;
  let venueName = null;
  if (orders.length > 0) {
    venueName = orders[orders.length - 1].venueName;
    if (location === VENUE_LOCATION_TO_GO) {
      orderLocation = orders[orders.length - 1].orderLocation;
    }
  }
  // localization and strings
  const locale = getUsableLocale();
  const venueStrings = venueStringsSelector(venue, locale);

  let labelModeKey = "nativeUpsellBanner:toGo";
  if (orderLocation === VENUE_LOCATION_EAT_IN) {
    labelModeKey = "nativeUpsellBanner:dineIn";
  } else if (orderLocation === VENUE_LOCATION_DELIVERY) {
    labelModeKey = "nativeUpsellBanner:delivery";
  }

  return {
    venueName: venueStrings[venueId] || (venueName && venueName.venue),
    labelModeKey,
  };
};

let NativeUpsellBanner = ({ classes, t, labelModeKey, venueName }) => (
  <div className={classes.root}>
    <Typography variant="body2" className={classes.text} noWrap>
      {t("nativeUpsellBanner:header", {
        venueName,
      })}
    </Typography>
    <Typography variant="body2" className={classes.text} noWrap>
      {t("nativeUpsellBanner:mode", {
        mode: t(labelModeKey),
      })}
    </Typography>
    <Typography variant="body2" className={classes.text} noWrap>
      <Trans i18nKey="nativeUpsellBanner:text">
        {t("downloadAppFragment1")}
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={classes.downloadAppText}
          href="https://get.orda-app.com"
        >
          {t("appLinkLabel")}
        </a>
        {t("downloadAppFragment2")}
      </Trans>
    </Typography>
  </div>
);

NativeUpsellBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  labelModeKey: PropTypes.string.isRequired,
  venueName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

NativeUpsellBanner = withStyles(styles)(NativeUpsellBanner);

NativeUpsellBanner = withTranslation(["ui-parts", "nativeUpsellBanner"])(
  NativeUpsellBanner
);

NativeUpsellBanner = connect(mapStateToProps)(NativeUpsellBanner);

export default withRouter(NativeUpsellBanner);
