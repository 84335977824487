import {
  buildMinimumOptions as buildOptions,
  buildOrderOption,
} from "@orda/shared-functions-js/lib/options";
import {
  getUsableBenefits,
  updateUsedBenefits,
} from "@orda/shared-functions/benefits";
import { VENUE_LOCATION_TO_GO } from "@orda/shared-constants/order-locations";

import { calculateItemBenefits } from "@orda/shared-functions-js/lib/price";
import isPlainObject from "lodash/isPlainObject";
import sha1 from "sha1";
import { venueStringsSelector } from "../redux/selectors/venue";
import { getUsableLocale } from "./i18next";

function inflateItems(venue, items) {
  const locale = getUsableLocale();
  let venueStrings = venueStringsSelector(venue, locale);
  return items.map((item) => {
    const fullItem = venue.menu.items[item];
    if (!venueStrings[item]) {
      venueStrings = venueStringsSelector(venue, "de");
    }
    let inflatedOptions;
    if (fullItem.options) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      inflatedOptions = inflateOptions(venue, fullItem.options, venueStrings);
    }
    return {
      ...fullItem,
      id: item,
      name: venueStrings[item],
      options: inflatedOptions,
    };
  });
}

export function inflateOptions(venue, optionsRaw, venueStrings = {}) {
  if (!optionsRaw) {
    return null;
  }

  const options = venue.menu.options[optionsRaw];
  if (!options) {
    return null;
  }

  if (options.options) {
    if (options.type === "SPECIAL") {
      return {
        ...options,
        id: optionsRaw,
        options: inflateOptions(venue, options.options, venueStrings),
      };
    }
    return {
      ...options,
      id: optionsRaw,
      options: options.options.map((option) =>
        inflateOptions(venue, option, venueStrings)
      ),
    };
  }

  if (options.items) {
    return {
      ...options,
      id: optionsRaw,
      items: inflateItems(venue, options.items, venueStrings),
    };
  }

  throw new Error("Option needs to have either options or items.");
}

export function buildMinimumOptions(venue, itemId) {
  const options = venue.menu.items[itemId] && venue.menu.items[itemId].options;
  if (!options) {
    return null;
  }
  const inflatedOptions = inflateOptions(venue, options);
  return buildOptions(inflatedOptions);
}

export function buildOptionsForPath(venue, itemId, path) {
  const { options } = venue.menu.items[itemId];
  const inflatedOptions = inflateOptions(venue, options);
  return buildOrderOption(path, inflatedOptions);
}

function removeOrphans(orderOptions) {
  if (Array.isArray(orderOptions)) {
    if (orderOptions.length === 0) {
      return null;
    }
    return orderOptions;
  }

  if (!isPlainObject(orderOptions)) {
    return orderOptions;
  }

  return Object.entries(orderOptions)
    .map(([key, value]) => [key, removeOrphans(value)])
    .reduce((newOrderOptions, [key, value]) => {
      if (
        value === null ||
        value === undefined ||
        (isPlainObject(value) && Object.keys(value).length === 0)
      ) {
        return newOrderOptions;
      }
      const usedOrderOptions = newOrderOptions || {};
      return {
        ...usedOrderOptions,
        [key]: value,
      };
    }, null);
}

function removeOrderOptionHelper(orderOptions, [head, ...tail]) {
  if (!orderOptions) {
    return {};
  }

  if (tail.length > 0) {
    return {
      ...orderOptions,
      [head]: removeOrderOptionHelper(orderOptions[head], tail),
    };
  }

  if (Array.isArray(orderOptions)) {
    return orderOptions.filter((value) => value !== head);
  }

  if (orderOptions === head) {
    return {};
  }

  return {
    ...orderOptions,
    [head]: undefined,
  };
}

export function removeOrderOption(orderOptions, path) {
  const result = removeOrderOptionHelper(orderOptions, path);
  return removeOrphans(result);
}

export function inflateOrderOptions(orderOptions, options, items) {
  const [optionId] = Object.keys(orderOptions);
  if (!optionId) {
    return null;
  }

  const option = options[optionId];
  switch (option.type) {
    case "AND": {
      const result = {
        id: optionId,
        price: option.price,
      };
      if (option.options) {
        result.options = Object.keys(orderOptions[optionId]).map((index) =>
          inflateOrderOptions(orderOptions[optionId][index], options, items)
        );
      } else {
        result.options = orderOptions[optionId].map((itemId) => ({
          id: itemId,
          price: items[itemId].price,
        }));
      }
      return result;
    }
    case "OR": {
      const result = {
        id: optionId,
        price: option.price,
      };
      if (option.options) {
        result.options = inflateOrderOptions(
          orderOptions[optionId].item,
          options,
          items
        );
      } else {
        result.options = {
          id: orderOptions[optionId],
          price: items[orderOptions[optionId]].price,
        };
      }
      return result;
    }
    default:
      throw new Error("Unexpected Option type!");
  }
}

export function inflateOrderOptionsNew(
  orderOptions,
  venue,
  venueStrings,
  benefits,
  usedBenefits,
  remarks,
  locale,
  orderLocation
) {
  const {
    menu: { items, options },
  } = venue;

  const [optionId] = Object.keys(orderOptions);

  const option = options[optionId];
  if (!optionId || !option) {
    return null;
  }

  const priceBeforeBenefits =
    (orderLocation === VENUE_LOCATION_TO_GO
      ? option.priceToGo || option.price
      : option.price) || 0;
  let priceAfterBenefits = priceBeforeBenefits;
  let priceAfterBenefitsRaw = priceBeforeBenefits;
  let usableBenefits = getUsableBenefits(benefits, usedBenefits);
  const { benefit, amount } = calculateItemBenefits(
    optionId,
    priceBeforeBenefits,
    usableBenefits
  );
  if (benefit) {
    updateUsedBenefits(usedBenefits, benefit);
    priceAfterBenefitsRaw -= amount;
    priceAfterBenefits = Math.round(priceAfterBenefitsRaw);
    if (priceAfterBenefits < 0) {
      priceAfterBenefits = 0;
    }
  }

  let remark;
  if (benefit && benefit.remark) {
    const remarkHash = sha1(
      benefit.remark[locale] || benefit.remark[venue.defaultLocale]
    );
    const reusableRemark = remarks[remarkHash];
    if (reusableRemark) {
      remark = reusableRemark;
    } else {
      remark = benefit.remark[locale] || benefit.remark[venue.defaultLocale];
      // eslint-disable-next-line no-param-reassign
      remarks[remarkHash] = remark;
    }
  }

  const result = {
    id: optionId,
    itemName: venueStrings[optionId],
    priceBeforeBenefits,
    priceAfterBenefitsRaw,
    priceAfterBenefits,
    priceDisplay: option.priceDisplay,
    remark,
  };
  switch (option.type) {
    case "AND": {
      if (option.options) {
        result.options = Object.keys(orderOptions[optionId]).map((index) =>
          inflateOrderOptionsNew(
            orderOptions[optionId][index],
            venue,
            venueStrings,
            benefits,
            usedBenefits,
            remarks,
            locale,
            orderLocation
          )
        );
      } else {
        result.options = orderOptions[optionId].map((itemId) => {
          const item = items[itemId];
          const itemPriceBeforeBenefits =
            (orderLocation === VENUE_LOCATION_TO_GO
              ? item.priceToGo || item.price
              : item.price) || 0;
          let itemPriceAfterBenefits = itemPriceBeforeBenefits;
          let itemPriceAfterBenefitsRaw = itemPriceBeforeBenefits;
          usableBenefits = getUsableBenefits(benefits, usedBenefits);
          const { benefit: itemBenefit, amount: itemAmount } =
            calculateItemBenefits(
              itemId,
              itemPriceBeforeBenefits,
              usableBenefits
            );
          if (itemBenefit) {
            updateUsedBenefits(usedBenefits, itemBenefit);
            itemPriceAfterBenefitsRaw -= itemAmount;
            itemPriceAfterBenefits = Math.round(itemPriceAfterBenefitsRaw);
            if (itemPriceAfterBenefits < 0) {
              itemPriceAfterBenefits = 0;
            }
          }

          let itemRemark;
          if (itemBenefit && itemBenefit.remark) {
            const remarkHash = sha1(
              itemBenefit.remark[locale] ||
                itemBenefit.remark[venue.defaultLocale]
            );
            const reusableRemark = remarks[remarkHash];
            if (reusableRemark) {
              itemRemark = reusableRemark;
            } else {
              itemRemark =
                itemBenefit.remark[locale] ||
                itemBenefit.remark[venue.defaultLocale];
              // eslint-disable-next-line no-param-reassign
              remarks[remarkHash] = itemRemark;
            }
          }

          return {
            id: itemId,
            itemName: venueStrings[itemId],
            priceBeforeBenefits: itemPriceBeforeBenefits,
            priceAfterBenefitsRaw: itemPriceAfterBenefitsRaw,
            priceAfterBenefits: itemPriceAfterBenefits,
            priceDisplay: items[itemId].priceDisplay,
            remark: itemRemark,
          };
        });
      }
      return result;
    }
    case "OR": {
      if (option.options) {
        result.options = inflateOrderOptionsNew(
          orderOptions[optionId].item,
          venue,
          venueStrings,
          benefits,
          usedBenefits,
          remarks,
          locale,
          orderLocation
        );
      } else {
        const itemId = orderOptions[optionId];

        const item = items[itemId];
        const itemPriceBeforeBenefits =
          (orderLocation === VENUE_LOCATION_TO_GO
            ? item.priceToGo || item.price
            : item.price) || 0;
        let itemPriceAfterBenefits = itemPriceBeforeBenefits;
        let itemPriceAfterBenefitsRaw = itemPriceBeforeBenefits;
        usableBenefits = getUsableBenefits(benefits, usedBenefits);
        const { benefit: itemBenefit, amount: itemAmount } =
          calculateItemBenefits(
            itemId,
            itemPriceBeforeBenefits,
            usableBenefits
          );
        if (itemBenefit) {
          updateUsedBenefits(usedBenefits, itemBenefit);
          itemPriceAfterBenefitsRaw -= itemAmount;
          itemPriceAfterBenefits = Math.round(itemPriceAfterBenefitsRaw);
          if (itemPriceAfterBenefits < 0) {
            itemPriceAfterBenefits = 0;
          }
        }

        let itemRemark;
        if (itemBenefit && itemBenefit.remark) {
          const remarkHash = sha1(
            itemBenefit.remark[locale] ||
              itemBenefit.remark[venue.defaultLocale]
          );
          const reusableRemark = remarks[remarkHash];
          if (reusableRemark) {
            itemRemark = reusableRemark;
          } else {
            itemRemark =
              itemBenefit.remark[locale] ||
              itemBenefit.remark[venue.defaultLocale];
            // eslint-disable-next-line no-param-reassign
            remarks[remarkHash] = itemRemark;
          }
        }

        result.options = {
          id: itemId,
          itemName: venueStrings[itemId],
          priceBeforeBenefits: itemPriceBeforeBenefits,
          priceAfterBenefitsRaw: itemPriceAfterBenefitsRaw,
          priceAfterBenefits: itemPriceAfterBenefits,
          priceDisplay: items[itemId].priceDisplay,
          remark: itemRemark,
        };
      }
      return result;
    }
    default:
      throw new Error("Unexpected Option type!");
  }
}
