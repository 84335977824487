import React, { useEffect, useState } from "react";
import { THEME } from "../theme";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import history from "../history";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Snackbar, Typography } from "@material-ui/core";
import CallWaiterModal from "../containers/CallWaiterModal";
import routes from "../routes";
import { Redirect } from "react-router-dom";
import { PosMessageType } from "../constants";
import { get } from "lodash";
import MenuIcon from "./icons/MenuIcon";
import ClocheIcon from "./icons/ClocheIcon";
import WalletIcon from "./icons/WalletIcon";
import ORDA from "./icons/ORDA";
import Loading from "./Loading";
import { getVenueName } from "../lib/venues";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    minHeight: "100%",
    width: "100%",
  },
  header: {
    minHeight: 200,
    maxHeight: 200,
    height: "100%",
    width: "100%",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "30px",
    height: "100%",
  },
  title: { fontSize: "1.6rem", fontWeight: "bold", textAlign: "center" },
  subTitle: { fontSize: "1.2rem", textAlign: "center" },
  actionContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "20px",
  },
  button: {
    backgroundColor: THEME.SECONDARY_BUTTON_ICON,
    "&:hover": {
      backgroundColor: THEME.SECONDARY_BUTTON_ICON,
    },
    "&:active": {
      backgroundColor: THEME.PRIMARY_BUTTON_ICON,
    },
    width: 150,
    height: 150,
    "@media (max-width: 320px) or (max-height: 650px)": {
      maxWidth: 120,
      maxHeight: 120,
    },
    "@media (hover: hover) and (pointer: fine)": {
      "&:hover": {
        backgroundColor: THEME.PRIMARY_BUTTON_ICON,
      },
    },
  },
  buttonLabel: {
    color: THEME.WHITE_TEXT_COLOR,
    fontSize: "1.2em",
    fontWeight: "bold",
    "&:hover": {
      color: THEME.PRIMARY_TEXT_COLOR,
    },
    "@media (max-width: 320px) or (max-height: 650px)": {
      fontSize: "1em",
    },
  },
  actionButtonContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  actionIconContainer: {
    width: "75%",
  },
  footer: {
    maxWidth: 100,
    width: "15%",
    margin: "0.6em 0",
    position: "absolute",
    bottom: 0,
  },
};
const navigateToMenu = (id) => history.push(routes.menu.template(id));
const navigateToInHousePayment = (id) =>
  history.push(routes.inHousePayment.template(id));

const StartScreen = ({
  t,
  classes,
  order,
  venueId,
  posMessageState,
  venue,
  venueLoaded,
  getVenue,
}) => {
  const heroBackground = get(venue, ["theme", "logo", "images", "hero"], null);
  const [openCallWaiterModal, setOpenCallWaiterModal] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  useEffect(() => {
    if (!venueLoaded) {
      getVenue(venueId);
    }
  }, [venueLoaded]);
  useEffect(() => {
    if (posMessageState.success || posMessageState.error) {
      setSnackOpen(true);
    }
  }, [posMessageState]);
  if (!venueLoaded) {
    return <Loading />;
  }
  if (!venue.startScreen) {
    return <Redirect to={routes.menu.template(venueId)} />;
  }
  const venueName = getVenueName(venue);
  const { startScreen = null } = venue;
  const actions = [
    {
      enabled: startScreen && startScreen.order,
      label: t("orderNow"),
      icon: <MenuIcon />,
      onCLick: () => {
        navigateToMenu(venueId);
      },
    },
    {
      enabled: startScreen && startScreen.callWaiter,
      label: t("callWaiter"),
      icon: <ClocheIcon />,
      onCLick: () => {
        setOpenCallWaiterModal(true);
      },
      state: posMessageState,
    },
    {
      enabled: startScreen && startScreen.pay,
      label: t("pay"),
      icon: <WalletIcon />,
      onCLick: () => {
        navigateToInHousePayment(venueId);
      },
      state: posMessageState,
    },
  ];
  if (!actions || actions.length < 1 || !order.tableId) {
    navigateToMenu(venueId);
    return null;
  }
  return (
    <div className={classes.container}>
      {!!heroBackground && (
        <div
          className={classes.header}
          style={{ background: `center/cover url(${heroBackground})` }}
        />
      )}
      <div className={classes.content}>
        <div>
          <Typography variant="body2" className={classes.title}>
            {venueName}
          </Typography>
          <Typography variant="body2" className={classes.subTitle}>
            {t("subTitle")}
          </Typography>
        </div>
        <div className={classes.actionContainer}>
          {actions.map(
            (action) =>
              action.enabled && (
                <Button
                  onClick={action.onCLick}
                  className={classes.button}
                  key={action.label}
                >
                  <div className={classes.actionButtonContent}>
                    <div className={classes.actionIconContainer}>
                      {action.icon}
                    </div>
                    <Typography variant="body2" className={classes.buttonLabel}>
                      {action.label}
                    </Typography>
                  </div>
                </Button>
              )
          )}
        </div>
        {posMessageState.result &&
          posMessageState.result.type === PosMessageType.CallWaiter &&
          !posMessageState.processing && (
            <Snackbar
              open={snackOpen}
              message={t("posMessageSuccess")}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              autoHideDuration={3500}
              onClose={() => setSnackOpen(false)}
              ContentProps={{
                style: { backgroundColor: THEME.MAIN_GREEN },
              }}
            />
          )}
        {!!posMessageState.error && (
          <Snackbar
            open={snackOpen}
            message={t("posMessageError")}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={3500}
            onClose={() => setSnackOpen(false)}
            ContentProps={{
              style: { backgroundColor: THEME.ACCENT_RED },
            }}
          />
        )}
        {posMessageState && posMessageState.processing && (
          <CircularProgress size={20} />
        )}
      </div>
      <div className={classes.footer}>
        <ORDA fill={THEME.SECONDARY_BUTTON_ICON} />
      </div>
      {openCallWaiterModal && (
        <CallWaiterModal
          open={openCallWaiterModal}
          setOpen={setOpenCallWaiterModal}
        />
      )}
    </div>
  );
};

StartScreen.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  order: PropTypes.any.isRequired,
  venueId: PropTypes.string.isRequired,
  posMessageState: PropTypes.any.isRequired,
  venue: PropTypes.object.isRequired,
  venueLoaded: PropTypes.bool.isRequired,
  getVenue: PropTypes.func.isRequired,
};

export default withTranslation("startScreen")(withStyles(styles)(StartScreen));
