import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Typography,
  Divider,
  Button,
  IconButton,
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { THEME } from "../../theme";
import InHouseCheckout from "../../containers/InHousePayment/InHouseCheckout";
import { PosMessageType } from "../../constants";
import routes from "../../routes";
import { Link } from "react-router-dom";
import { ArrowLeft } from "mdi-material-ui";
import Loading from "../Loading";
import InHouseOrderOverview from "./InHouseOrderOverview";

const styles = {
  root: {
    height: "100%",
  },
  headerContainer: {
    top: 0,
    display: "flex",
    position: "sticky",
    backgroundColor: THEME.DEFAULT_BACKGROUND_COLOR,
    zIndex: 10,
    height: "10%",
  },
  headerTitle: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headerLabel: {
    fontWeight: "bold",
  },
  mainContainer: { padding: 10, height: "80%" },
  button: {
    height: 45,
    color: THEME.WHITE_TEXT_COLOR,
    backgroundColor: THEME.BUTTON_BACKGROUND_COLOR,
    "&:hover": {
      backgroundColor: THEME.BUTTON_BACKGROUND_COLOR,
    },
  },
  iconButton: {
    color: THEME.PRIMARY_BUTTON_ICON,
    "&:hover": {
      opacity: 0.8,
    },
    borderRadius: 0,
    fontSize: "clamp(0.7rem, 3vmin, 1rem)",
  },
  errorMessage: {
    textAlign: "center",
    fontWeight: "bold",
    color: THEME.ACCENT_RED,
    whiteSpace: "pre-line",
    marginTop: "1.5rem",
  },
  footer: {
    bottom: 0,
    position: "sticky",
    backgroundColor: THEME.DEFAULT_BACKGROUND_COLOR,
    zIndex: 10,
    padding: 12,
    height: "10%",
  },
};
const InHousePayment = ({
  t,
  classes,
  currency,
  posMessageState,
  order,
  venueId,
  posMessage,
  posMessageReset,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [positions, setPositions] = useState([]);
  const sumTotal = positions.reduce(
    (acc, curr) => acc + curr.price * curr.qty * 100,
    0
  );
  useEffect(() => {
    if (order.tableId) {
      posMessage(venueId, {
        order,
        type: PosMessageType.InfoRequest,
      });
    }
  }, []);
  useEffect(() => {
    if (
      posMessageState.success &&
      posMessageState.result.target &&
      posMessageState.result.target.positions
    ) {
      setPositions(posMessageState.result.target.positions);
    }
  }, [posMessageState]);

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <IconButton
          className={classes.iconButton}
          component={Link}
          to={routes.start.template(venueId)}
        >
          <ArrowLeft />
        </IconButton>
        <div className={classes.headerTitle}>
          <Typography variant="h5" className={classes.headerLabel}>
            {t("title")}
          </Typography>
        </div>
      </div>
      <Divider />
      {!!posMessageState.error && (
        <Typography variant="body2" className={classes.errorMessage}>
          {t("posMessageError")}
        </Typography>
      )}
      {posMessageState.processing ? (
        <Loading />
      ) : (
        <>
          {posMessageState.success &&
            (positions.length > 0 ? (
              <InHouseOrderOverview positions={positions} currency={currency} />
            ) : (
              <Typography variant="body2" className={classes.errorMessage}>
                {t("noPositions")}
              </Typography>
            ))}
          <div className={classes.footer}>
            {sumTotal > 0 && (
              <Button
                fullWidth
                className={classes.button}
                variant="contained"
                size="large"
                color="primary"
                onClick={() => {
                  setOpenModal(true);
                  posMessageReset();
                }}
              >
                {t("payButtonLabel", {
                  sumTotal: t("common:money", {
                    data: {
                      currency,
                      value: sumTotal,
                    },
                  }),
                })}
              </Button>
            )}
          </div>
        </>
      )}
      {openModal && (
        <InHouseCheckout
          open={openModal}
          setOpen={setOpenModal}
          order={{ ...order, positions }}
          setPositions={setPositions}
        />
      )}
    </div>
  );
};

InHousePayment.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  posMessageState: PropTypes.object.isRequired,
  posMessage: PropTypes.any.isRequired,
  posMessageReset: PropTypes.any.isRequired,
  venueId: PropTypes.string.isRequired,
  order: PropTypes.object.isRequired,
  currency: PropTypes.string,
};
InHousePayment.defaultProps = {
  currency: "EUR",
};
export default withTranslation("inHousePayment")(
  withStyles(styles)(InHousePayment)
);
