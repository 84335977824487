import React from "react";
import PropTypes from "prop-types";
import { AlertCircleOutline as ErrorIcon } from "mdi-material-ui";
import Zoom from "@material-ui/core/Zoom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core";
import { THEME } from "../theme";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
    backgroundColor: THEME.SECONDARY_BUTTON_ICON,
    width: "100%",
  },
  logoContainer: {
    padding: "20px 0",
    width: "100%",
    textAlign: "center",
    flexGrow: 0,
  },
  iconContainer: {
    width: "100%",
    flexGrow: 1,
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  messageContainer: {
    width: "100%",
    flexGrow: 1,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  message: {
    color: "white",
    padding: "0 40px",
    textAlign: "center",
    whiteSpace: "pre-line",
  },
  actionContainer: {
    flexGrow: 1,
  },
  button: {
    width: 200,
  },
};

const Error = ({ t, classes, errorMessage, action, buttonLabelKey }) => (
  <div className={classes.root}>
    <div className={classes.logoContainer}>
      <span style={{ fontSize: "1rem" }} className="orda-icon-ORDA" />
    </div>
    <div className={classes.iconContainer}>
      <Zoom in>
        <ErrorIcon style={{ fontSize: 80 }} />
      </Zoom>
    </div>
    <div className={classes.messageContainer}>
      <Typography className={classes.message} variant="body1">
        {errorMessage || t("errors:generic")}
      </Typography>
    </div>
    <div className={classes.actionContainer}>
      <Button
        onClick={action}
        className={classes.button}
        variant="outlined"
        color="secondary"
        size="large"
      >
        {t(buttonLabelKey)}
      </Button>
    </div>
  </div>
);

Error.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
  action: PropTypes.func.isRequired,
  buttonLabelKey: PropTypes.string.isRequired,
};

Error.defaultProps = {
  errorMessage: null,
};

export default withTranslation(["error", "errors"])(withStyles(styles)(Error));
