export default {
  LOGIN: {
    REQUEST: "LOGIN.REQUEST",
    SUCCESS: "LOGIN.SUCCESS",
    FAILURE: "LOGIN.FAILURE",
  },
  LOGOUT: {
    REQUEST: "LOGOUT.REQUEST",
    SUCCESS: "LOGOUT.SUCCESS",
    FAILURE: "LOGOUT.FAILURE",
  },
  SYNC_FIREBASE_USER: "SYNC_FIREBASE_USER",
};
