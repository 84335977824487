import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";

const styles = () => ({
  root: {
    width: "100%",
    paddingLeft: "15px",
    cursor: "text",
  },
});

class StripeInput extends React.PureComponent {
  static displayName = "StripeInput";

  static propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    component: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    onFocus: () => {},
    onBlur: () => {},
    onChange: () => {},
  };

  render() {
    const {
      classes: c,
      theme,
      component: Component,
      onFocus,
      onBlur,
      onChange,
    } = this.props;

    return (
      <Component
        className={c.root}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        placeholder=""
        style={{
          base: {
            fontSize: `${theme.typography.fontSize}px`,
            fontFamily: theme.typography.fontFamily,
            color: "#000000de",
          },
        }}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(StripeInput);
