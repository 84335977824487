import React, { useMemo } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  VENUE_LOCATION_EAT_IN,
  VENUE_LOCATION_TO_GO,
} from "@orda/shared-constants/order-locations";
import { PRICE_DISPLAY_DEFAULT } from "@orda/shared-constants-js/price-display";
import { getCharacter } from "../lib/additives";
import { withTranslation } from "react-i18next";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Price from "../containers/Price";
import { formatPricePrefix } from "../lib/price";
import TypographyExponent from "./TypographyExponent";
import { THEME } from "../theme";

const styles = {
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  labelContainer: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    marginLeft: 0,
  },
  labelDisplay: {
    fontSize: "1em",
    fontWeight: "700",
  },
  descriptionDisplay: {
    fontSize: "0.8rem",
    fontWeight: "400",
    color: THEME.SECONDARY_TEXT_COLOR,
  },
  disabledDisplay: {
    fontSize: "0.8rem",
    fontWeight: "400",
    color: THEME.ACCENT_RED,
  },
};

const AndOption = ({
  t,
  checked,
  onCheckedChange,
  label,
  itemId,
  additives,
  tags,
  priceDisplay,
  unavailable,
  maxChildrenReached,
  notEatIn,
  notToGo,
  orderLocation,
  benefits,
  classes,
  path,
  description,
}) => {
  const notice = useMemo(() => {
    if (unavailable) {
      return t("defaultDisabledNotice");
    }

    switch (orderLocation) {
      case VENUE_LOCATION_EAT_IN:
        if (notEatIn) {
          return t("notEatIn");
        }
        break;
      case VENUE_LOCATION_TO_GO:
        if (notToGo) {
          return t("notToGo");
        }
        break;
      default:
        break;
    }

    if (maxChildrenReached) {
      return t("maxChildrenReached");
    }

    return null;
  }, [orderLocation, notEatIn, notToGo, unavailable, maxChildrenReached]);

  const labelComponent = (
    <div
      className={classes.labelContainer}
      style={{
        opacity: notice ? 0.3 : 1,
      }}
    >
      {tags.length > 0 ? (
        <TypographyExponent
          base={label}
          exponent={tags
            .map((tag) => getCharacter(additives.indexOf(tag)))
            .join(",")}
          labelDisplay={styles.labelDisplay}
        />
      ) : (
        <Typography component="span" className={classes.labelDisplay}>
          {label}
        </Typography>
      )}
      {!notice && !!description && (
        <Typography component="span" className={classes.descriptionDisplay}>
          {description}
        </Typography>
      )}
      {!!notice && (
        <Typography component="span" className={classes.disabledDisplay}>
          {notice}
        </Typography>
      )}
    </div>
  );

  return (
    <div className={classes.root}>
      <FormControlLabel
        label={labelComponent}
        control={
          <Checkbox
            color="primary"
            style={{
              color: THEME.BUTTON_BACKGROUND_COLOR,
              opacity: notice ? 0.3 : 1,
            }}
            onChange={onCheckedChange(path)}
            checked={checked}
            disabled={!!notice}
          />
        }
      />
      <Price
        priceDisplay={priceDisplay}
        prefix={formatPricePrefix(priceDisplay)}
        benefits={benefits}
        itemId={itemId}
      />
    </div>
  );
};

AndOption.propTypes = {
  notToGo: PropTypes.bool,
  notEatIn: PropTypes.bool,
  unavailable: PropTypes.bool,
  maxChildrenReached: PropTypes.bool,
  tags: PropTypes.array,
  priceDisplay: PropTypes.number,
  checked: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  path: PropTypes.array.isRequired,
  onCheckedChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  additives: PropTypes.array.isRequired,
  orderLocation: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  benefits: PropTypes.array.isRequired,
  description: PropTypes.string,
};

AndOption.defaultProps = {
  notToGo: false,
  notEatIn: false,
  unavailable: false,
  tags: [],
  priceDisplay: PRICE_DISPLAY_DEFAULT,
  description: null,
  maxChildrenReached: false,
};

export default withTranslation("ui-parts")(withStyles(styles)(AndOption));
