import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PaypalButton from "../components/PaypalButton";
import {
  executeOrderSuccess,
  executeOrderFailure,
  expressOrderFailure,
  expressOrderSuccess,
  prepareOrder,
  addItemWithOptions,
  addItem,
  setExpress,
  clearOrder,
  clearPreparedOrder,
} from "../redux/actions/order";
import { injectError } from "../redux/actions/errors";
import { noteValid } from "../util/validation";
import { addPaypalCheckout } from "../redux/actions/payments";
import { resetItemConfiguration } from "../redux/actions/item-configuration";
import { CLIENT_ID_KEY, paymentOptionsDefault } from "../constants";

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { venueId },
    },
  } = ownProps;
  const {
    venues: {
      data: { [venueId]: venue },
    },
    auth: { user: authUser, syncFinished: authSyncFinished },
    order: { preparedOrder },
    user,
  } = state;

  let paymentOptions = !!venue && venue.paymentOptions;
  if (!paymentOptions || paymentOptions.length <= 0) {
    paymentOptions = paymentOptionsDefault;
  }

  const currentPaymentMethodInternal = user.currentPaymentMethod
    ? user.paymentMethods.find(
        (paymentMethod) => paymentMethod.id === user.currentPaymentMethod
      )
    : null;

  const currentSelectedPaymentMethodInternalMetaData =
    currentPaymentMethodInternal
      ? paymentOptions.find(
          (paymentOption) =>
            paymentOption.id === currentPaymentMethodInternal.type
        )
      : null;
  const paypalClientId =
    currentPaymentMethodInternal &&
    currentSelectedPaymentMethodInternalMetaData.parameters &&
    currentSelectedPaymentMethodInternalMetaData.parameters.length > 0
      ? currentSelectedPaymentMethodInternalMetaData.parameters.find(
          (parameter) => parameter.key === CLIENT_ID_KEY
        )
      : null;

  return {
    authUser,
    authSyncFinished,
    preparedOrder,
    currentPaymentMethod: currentPaymentMethodInternal,
    paypalClientId: paypalClientId && paypalClientId.value,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { history } = ownProps;

  return {
    executeOrderSuccess: (order) => dispatch(executeOrderSuccess(order)),
    executeOrderFailure: (error) => dispatch(executeOrderFailure(error)),
    injectError: (error) => dispatch(injectError(error)),
    expressOrderSuccess: () => dispatch(expressOrderSuccess()),
    expressOrderFailure: () => dispatch(expressOrderFailure()),
    navigate: (to) => {
      history.push(to);
    },
    prepareOrder: (venueId, notifyPromise) =>
      dispatch(prepareOrder(venueId, notifyPromise)),
    addToCart: (venueId, itemId, itemAvailable, configuredOptions, note) => {
      if (itemAvailable && noteValid(note)) {
        if (configuredOptions) {
          dispatch(
            addItemWithOptions(venueId, itemId, configuredOptions, note)
          );
        } else {
          dispatch(addItem(venueId, itemId, note));
        }
      }
    },
    addPaypalCheckout: () => dispatch(addPaypalCheckout()),
    setExpress: (express) => dispatch(setExpress(express)),
    clearOrder: () => dispatch(clearOrder()),
    clearPreparedOrder: () => dispatch(clearPreparedOrder()),
    resetItemConfiguration: () => dispatch(resetItemConfiguration()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaypalButton)
);
