export const cleanUpPositionIds = (items) =>
  items.map((item) => {
    const cleanedUpItem = {
      id: item.itemId,
    };

    if (item.orderOptions) {
      cleanedUpItem.orderOptions = item.orderOptions;
    }

    if (item.note) {
      cleanedUpItem.note = item.note;
    }

    return cleanedUpItem;
  });
