/* eslint no-param-reassign: 0 */
import produce from "immer";
import * as types from "../types";
import { REACT_APP_ADYEN_ENVIRONMENT } from "../../config";

export const initialState = {
  error: null,
  session: null,
  orderRef: null,
  paymentData: null,
  config: {
    environment: REACT_APP_ADYEN_ENVIRONMENT,
    analytics: {
      enabled: false,
    },
  },
};

export default function adyenPaymentReducer(state = initialState, action) {
  switch (action.type) {
    case types.payments.ADYEN.CREATE_PAYMENT_SESSION.SUCCESS:
      return produce(state, (newState) => {
        const {
          response: { sessionData, id, amount },
          orderRef,
        } = action.payload;
        newState.session = { sessionData, id };
        newState.amount = amount && (amount.value / 100).toFixed(2);
        newState.orderRef = orderRef;
      });
    case types.payments.ADYEN.CREATE_PAYMENT_SESSION.FAILURE:
      return produce(state, (newState) => {
        newState.error = action.error;
      });
    case types.payments.ADYEN.CLEAR_PAYMENT_SESSION:
      return initialState;
    default:
      return state;
  }
}
