import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { IconButton, withStyles } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import uniq from "lodash/uniq";
import Option from "../containers/Option";
import { getCharacter } from "../lib/additives";
import CheckoutBar from "../containers/CheckoutBar";
import { getItemUnvailableExplanationText } from "../lib/hours";
import classnames from "classnames";
import Header from "./Header";
import { Link } from "react-router-dom";
import routes from "../routes";
import { ArrowLeft } from "mdi-material-ui";
import { THEME } from "../theme";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: "#fafafa",
    overflowY: "auto",
  },
  checkoutMargin: {
    marginBottom: 80,
  },
  hero: {
    minHeight: 280,
    maxHeight: 280,
    height: "100%",
  },
  titleContainer: {
    padding: 20,
  },
  title: {
    fontSize: "1.5em",
    fontWeight: 600,
  },
  unavailableContainer: {
    padding: "5px 20px 10px 20px",
  },
  unavailableText: {
    fontSize: "0.8em",
    color: "#e57373",
  },
  descriptionContainer: {
    padding: "5px 20px 0px 20px",
  },
  description: {
    fontSize: "0.75rem",
  },
  additiveInformationContainer: {
    padding: "5px 20px 5px 20px",
  },
  additiveInformationLabel: {
    fontSize: "0.6rem",
    fontWeight: 700,
    color: "grey",
  },
  additiveInformationItem: {
    fontSize: "0.6rem",
    color: "grey",
  },
  optionsContainer: {
    padding: "5px 20px 5px 20px",
  },
  optionsAdditivesInformationContainer: {
    padding: "20px 20px 15px 20px",
  },
  noteContainer: {
    padding: "5px 20px 15px 20px",
  },
  noteLabel: {
    fontWeight: 600,
  },
  noteErrorHelpText: {
    fontSize: "0.75rem",
    marginTop: 5,
    color: "red",
  },
  noteDisclaimer: {
    fontSize: "0.75rem",
    marginTop: 5,
  },
  barsContainer: {
    position: "fixed",
    maxWidth: THEME.MAX_SCREEN_WIDTH,
    width: "100%",
    bottom: 0,
  },
  iconButton: {
    color: THEME.PRIMARY_BUTTON_ICON,
    "&:hover": {
      // backgroundColor: "rgba(44, 47, 58, 0.5)",
      backgroundColor: THEME.SECONDARY_BUTTON_ICON,
      opacity: 0.8,
    },
    borderRadius: 0,
    fontSize: "clamp(0.7rem, 3vmin, 1rem)",
  },
  headerContainer: {
    top: 0,
    display: "flex",
    position: "sticky",
    backgroundColor: THEME.SECONDARY_BUTTON_ICON,
    boxShadow: "0px 4px 20px 1px rgba(0,0,0,0.2)",
    height: 50,
    zIndex: 10,
  },
  header: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const invalidCharactersRegEx = /[^a-zA-Z0-9+\-üäöÜÄÖ/,.()"'+&\s]/g;
const getInvalidNoteChars = (string) => string.match(invalidCharactersRegEx);

class MenuDetailItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      note: "",
      noteEditingStarted: false,
      imgLoadingFailed: false,
    };
  }

  componentDidMount() {
    const { initItemConfiguration, images } = this.props;
    initItemConfiguration();
    if (images && images.default) {
      const img = new Image();
      img.src = images.default;

      img.onerror = () => {
        this.setState({ imgLoadingFailed: true });
      };
    }
    // global.window.scroll(300, 300);
  }

  componentWillUnmount() {
    const { resetItemConfiguration } = this.props;
    resetItemConfiguration();
  }

  handleNoteChange = (event) => {
    this.setState({
      note: event.target.value,
      noteEditingStarted: true,
    });
    this.handleNoteUpdate();
  };

  handleNoteUpdate = () => {
    const { note } = this.state;
    const { updateNote } = this.props;

    updateNote(note);
  };

  render() {
    const {
      t,
      itemId,
      name,
      description,
      benefits,
      configuredOptions,
      additives,
      images,
      noNotes,
      venueName,
      classes,
      routeId,
      itemAvailability,
      venueId,
    } = this.props;
    const { note, noteEditingStarted, imgLoadingFailed } = this.state;

    const invalidChars = getInvalidNoteChars(note);
    const noteErrorHelpText = invalidChars && invalidChars.length !== 0 && (
      <Typography className={classes.noteErrorHelpText} variant="body2">
        {`${t("iosNoteError")}: ${uniq(invalidChars).join(", ")}`}
      </Typography>
    );

    const itemUnavailableExplanationText = !itemAvailability.available
      ? getItemUnvailableExplanationText(t, itemAvailability, venueName, name)
      : "";

    const heroBackground = `${
      images && images.default && !imgLoadingFailed
        ? `center/contain no-repeat url(${images.default}) ${THEME.SECONDARY_BACKGROUND_COLOR}`
        : ""
    }`;

    return (
      <>
        <div
          className={classnames(classes.root, {
            [classes.checkoutMargin]: itemAvailability.available,
          })}
        >
          <div className={classes.headerContainer}>
            <IconButton
              className={classes.iconButton}
              component={Link}
              to={routes.menu.template(venueId)}
            >
              <ArrowLeft />
            </IconButton>
            <div className={classes.header}>
              <Header title={name} />
            </div>
          </div>
          {heroBackground && (
            <div
              className={classes.hero}
              style={{
                background: heroBackground,
              }}
            />
          )}
          {!itemAvailability.available && (
            <div className={classes.unavailableContainer}>
              <Typography variant="body2" className={classes.unavailableText}>
                {itemUnavailableExplanationText}
              </Typography>
            </div>
          )}
          <div className={classes.descriptionContainer}>
            <Typography className={classes.description} variant="body2">
              {description}
            </Typography>
          </div>
          {additives.item.length > 0 && (
            <div className={classes.additiveInformationContainer}>
              <Typography
                className={classes.additiveInformationLabel}
                variant="overline"
              >
                {t("additionalInfoLabel")}
              </Typography>
              {additives.item.map((additive) => (
                <Typography
                  className={classes.additiveInformationItem}
                  variant="caption"
                  // TODO: add extra style for preferences and intolerances
                  key={`item${additive}`}
                >
                  {t(`additives:${additive}`)}
                </Typography>
              ))}
            </div>
          )}
          <div className={classes.optionsContainer}>
            <Option
              itemId={itemId}
              additives={additives.options}
              benefits={benefits}
              configuredOptions={configuredOptions}
            />
          </div>
          <div className={classes.noteContainer}>
            <Typography variant="overline" className={classes.noteLabel}>
              {t("noteLabel")}
            </Typography>
            {noNotes ? (
              <Typography variant="body2">
                {t("notesDisabled", { venueName })}
              </Typography>
            ) : (
              [
                <TextField
                  key="note-text-input"
                  fullWidth
                  autoCorrect="on"
                  value={note}
                  onChange={this.handleNoteChange}
                  placeholder={t("noteTextInputPlaceholder")}
                  autoCapitalize="off"
                  onBlur={this.handleNoteUpdate}
                />,
                noteErrorHelpText,
                noteEditingStarted && (
                  <Typography
                    className={classes.noteDisclaimer}
                    key="note-text-disclaimer"
                    variant="body2"
                  >
                    {t("notesDisclaimer", { venueName })}
                  </Typography>
                ),
              ]
            )}
          </div>
          {additives.options.length > 0 && (
            <div className={classes.optionsAdditivesInformationContainer}>
              <Typography
                className={classes.additiveInformationLabel}
                variant="overline"
              >
                {t("additionalInfoLabel")}
              </Typography>
              {additives.options.map((id, index) => (
                <Typography
                  className={classes.additiveInformationItem}
                  key={`option${id}`}
                >
                  {`${getCharacter(index)} - ${t(`additives:${id}`)}`}
                </Typography>
              ))}
            </div>
          )}
        </div>
        <div className={classes.barsContainer}>
          <CheckoutBar mountedAt={routeId} />
        </div>
      </>
    );
  }
}

MenuDetailItem.propTypes = {
  t: PropTypes.func.isRequired,
  itemId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  benefits: PropTypes.array.isRequired,
  additives: PropTypes.object.isRequired,
  configuredOptions: PropTypes.object,
  images: PropTypes.object,
  noNotes: PropTypes.bool.isRequired,
  venueName: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  initItemConfiguration: PropTypes.func.isRequired,
  updateNote: PropTypes.func.isRequired,
  routeId: PropTypes.string.isRequired,
  venueId: PropTypes.string.isRequired,
  resetItemConfiguration: PropTypes.func.isRequired,
  itemAvailability: PropTypes.object.isRequired,
};

MenuDetailItem.defaultProps = {
  description: null,
  configuredOptions: null,
  images: {},
};

export default withTranslation("menuItemDetail")(
  withStyles(styles)(MenuDetailItem)
);
