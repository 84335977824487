import * as types from "../types";

export const addCreditCard = (token) => ({
  type: types.payments.ADD_CREDIT_CARD.REQUEST,
  token,
});

export const addCreditCardSuccess = (data) => ({
  type: types.payments.ADD_CREDIT_CARD.SUCCESS,
  data,
});

export const addCreditCardFailure = (error) => ({
  type: types.payments.ADD_CREDIT_CARD.FAILURE,
  error,
});

export const addPaymentOption = (paymentOption) => ({
  type: types.payments.ADD_PAYMENT_OPTION.REQUEST,
  paymentOption,
});

export const addPaymentOptionSuccess = (data) => ({
  type: types.payments.ADD_PAYMENT_OPTION.SUCCESS,
  data,
});

export const addPaymentOptionFailure = (error) => ({
  type: types.payments.ADD_PAYMENT_OPTION.FAILURE,
  error,
});

export const addPaypalCheckout = (isPrivate = false) => ({
  type: types.payments.ADD_PAYPAL_CHECKOUT,
  isPrivate,
});

export const setPaymentRequest = (paymentRequest) => ({
  type: types.payments.SET_PAYMENT_REQUEST,
  paymentRequest,
});

export const setGooglePayToken = (googlePayTokenId) => ({
  type: types.payments.SET_GOOGLE_PAY_TOKEN,
  googlePayTokenId,
});

export const setApplePayToken = (applePayTokenId) => ({
  type: types.payments.SET_APPLE_PAY_TOKEN,
  applePayTokenId,
});

export const createAdyenPaymentSession = (venueId, payload) => ({
  type: types.payments.ADYEN.CREATE_PAYMENT_SESSION.REQUEST,
  venueId,
  payload,
});

export const createAdyenPaymentSessionFailure = (error) => ({
  type: types.payments.ADYEN.CREATE_PAYMENT_SESSION.FAILURE,
  error,
});

export const clearAdyenPaymentSession = () => ({
  type: types.payments.ADYEN.CLEAR_PAYMENT_SESSION,
});

export const storeAdyenPaymentData = (payload) => ({
  type: types.payments.ADYEN.CREATE_PAYMENT_SESSION.SUCCESS,
  payload,
});
