// menu
export const EXPLORE_VIEW_SECTION = "explore-view-section";
export const EXPLORE_VIEW_MENU_ITEM_DETAIL = "explore-view-menu-item-detail";
export const EXPLORE_MENU_SWIPE_TO_SECTION = "explore-menu-swipe-to-section";
export const EXPLORE_MENU_CLICK_SECTION_TAB = "explore-menu-click-section-tab";

// order
export const ORDER_ADD_ITEM_TO_CART = "order-add-item-to-cart";
export const ORDER_VIEW_CART = "order-view-cart";
export const ORDER_DECREMENT_ITEM_COUNT_CART =
  "order-decrement-item-count-cart";
export const ORDER_INCREMENT_ITEM_COUNT_CART =
  "order-increment-item-count-cart";
export const ORDER_SUCCESS = "order-success";

// payment
export const ORDER_ADD_PAYMENT_OPTION_SUCCESS =
  "order-add-payment-method-success";
