import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import metadata from "../../assets/metadata.json";
import DataProtection from "../DataProtection";

const styles = {
  root: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  orda: {
    textAlign: "center",
  },
  footerText: {
    fontSize: "0.7em",
    fontFamily: "Open Sans",
  },
};

const Footer = ({ classes }) => {
  const currentYear = new Date().getFullYear();

  return (
    <div className={classes.root}>
      <div className={classes.orda}>
        <Typography
          variant="body2"
          component="span"
          className={classes.footerText}
        >
          © ORDA by GastroSoft GmbH {currentYear || ""}
        </Typography>
        <Typography
          className={classes.footerText}
          variant="body2"
          component="span"
        >
          {`V${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`}
        </Typography>
        <DataProtection />
      </div>
    </div>
  );
};
Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
