import { connect } from "react-redux";
import Cart from "../../components/Cart/Cart";
import { venueStringsSelector } from "../../redux/selectors/venue";
import { getUsableLocale } from "../../lib/i18next";
import { groupItems } from "../../lib/group-items";
import { createSelector } from "reselect";
import { login } from "../../redux/actions/auth";
import { createUser } from "../../redux/actions/user";
import {
  prepareOrder,
  executeOrder,
  clearOrder,
  clearPreparedOrder,
  setLocationOption,
  setDeliveryAddress,
  setOrderTime,
  setUserName,
  setUserEmail,
  setPhoneNumber,
} from "../../redux/actions/order";
import { getHourWindows } from "@orda/shared-functions-js/lib/hour-windows";
import moment from "moment-timezone";
import {
  VENUE_LOCATION_DELIVERY,
  VENUE_LOCATION_TO_GO,
} from "@orda/shared-constants/order-locations";
import { getItemAvailability } from "../../lib/hours";
import { get } from "lodash";
import { calculatePrice } from "../../lib/price";
import { MIN_DELIVERY_BASKET, paymentOptionsDefault } from "../../constants";
import {
  setGooglePayToken,
  setApplePayToken,
} from "../../redux/actions/payments";
import { currentlyWithinSpecificHours } from "@orda/shared-functions-js/lib/opening-hours";

const minimumBasketReached = (
  orderLocation,
  orderItems,
  items,
  venue,
  customMinimumBasketPrice = 0
) => {
  if (orderLocation === VENUE_LOCATION_DELIVERY) {
    if (!venue.toDelivery) {
      return false;
    }
    const rawSum = calculatePrice(
      orderItems,
      items,
      venue,
      orderLocation,
      null,
      [],
      null,
      []
    ).sum;
    const minimumBasket =
      customMinimumBasketPrice ||
      get(venue.toDelivery, "minimumBasket") ||
      get(venue.toDelivery, "minimumBasketPrice") ||
      MIN_DELIVERY_BASKET;
    return rawSum < minimumBasket;
  }
  if (orderLocation === VENUE_LOCATION_TO_GO) {
    if (!venue.toGo) {
      return false;
    }
    const rawSum = calculatePrice(
      orderItems,
      items,
      venue,
      orderLocation,
      null,
      [],
      null,
      []
    ).sum;
    const minimumBasket =
      customMinimumBasketPrice || get(venue.toGo, "minimumBasketPrice") || 0;
    return rawSum < minimumBasket;
  }
  return false;
};

const minimumBasketAmount = (
  orderLocation,
  venue,
  customMinimumBasketPrice = 0
) => {
  if (orderLocation === VENUE_LOCATION_DELIVERY) {
    if (!venue.toDelivery) {
      return "";
    }
    const minimumBasket =
      customMinimumBasketPrice ||
      get(venue.toDelivery, "minimumBasket") ||
      get(venue.toDelivery, "minimumBasketPrice") ||
      MIN_DELIVERY_BASKET;
    return `${(minimumBasket / 100).toFixed(2)}€`;
  }
  if (orderLocation === VENUE_LOCATION_TO_GO) {
    if (!venue.toGo) {
      return "";
    }
    const minimumBasket =
      customMinimumBasketPrice || get(venue.toGo, "minimumBasketPrice") || 0;
    return `${(minimumBasket / 100).toFixed(2)}€`;
  }
  return "";
};

const groupedItemsSelector = createSelector(
  (venue) => venue,
  (_, strings) => strings,
  (_, __, order) => order.items,
  (_, __, order) => order.feesBenefits && order.feesBenefits.benefits,
  (_, __, order) => order.feesBenefits && order.feesBenefits.expandableBenefits,
  (_, __, order) => order.location,
  (venue, strings, orderItems, benefits, expandableBenefits, orderLocation) =>
    groupItems(
      orderItems,
      venue,
      strings,
      benefits,
      expandableBenefits,
      getUsableLocale(),
      orderLocation
    )
);

const orderTimesSelector = createSelector(
  (_) => _,
  (_, currentTime) => currentTime,
  (openingHours, currentTime) => {
    const currentMoment = moment
      .unix(currentTime)
      .tz(openingHours.timeZone)
      .format("HHmm");
    return []
      .concat(getHourWindows(openingHours))
      .filter((time) => !time || time >= currentMoment);
  }
);

const buildOrderTimes = (venue, order) => {
  const deliveryTimeOffset = 15;
  let asapNotAvailable = false;
  let nextOpen = 0;
  const orderLocation = order.location;
  const itemsOpeningHours = order.items.map(
    (orderItem) => venue.menu.items[orderItem.itemId]
  );
  for (let index = 0; index < itemsOpeningHours.length; index++) {
    const item = itemsOpeningHours[index];
    if (venue.openingHours[item.hours]) {
      const itemAvailability = getItemAvailability(
        venue.openingHours,
        item,
        false,
        order.location,
        moment.tz(venue.openingHours.timeZone).format("HHmm")
      );
      if (itemAvailability.nextAvailable) {
        asapNotAvailable = true;
        if (nextOpen < itemAvailability.nextAvailable) {
          nextOpen = itemAvailability.nextAvailable;
        } else {
          asapNotAvailable = false;
        }
      }
    }
  }
  const customOpeningHourKeyPerOrderLocation =
    venue.openingHours[orderLocation] &&
    Object.keys(venue.openingHours[orderLocation]).length > 0;
  if (customOpeningHourKeyPerOrderLocation) {
    const withinOpeningHours = currentlyWithinSpecificHours(
      venue.openingHours,
      customOpeningHourKeyPerOrderLocation ? orderLocation : "open",
      null
    );
    nextOpen = withinOpeningHours.nextOpen;
  }

  let currentTime = nextOpen || moment().tz(venue.openingHours.timeZone).unix();
  if (order.location === VENUE_LOCATION_DELIVERY) {
    currentTime += deliveryTimeOffset * 60;
  }

  const [hobbyCookItem] = Object.values(venue.menu.items).filter(
    (item) => item.availableOnDate && item.pickupFromTime && item.pickupToTime
  );

  let orderTimes;

  try {
    if (hobbyCookItem) {
      const hobbycookItemAvailibilityDate = moment(
        hobbyCookItem.availableOnDate,
        "YYYY-MM-DD"
      );
      const isCurrentDayTheItemAvailableDate =
        moment.unix(currentTime).day() === hobbycookItemAvailibilityDate.day();
      const now = isCurrentDayTheItemAvailableDate
        ? currentTime
        : moment().startOf("day").unix();

      const { pickupFromTime, pickupToTime } = hobbyCookItem;
      const fromSplit = pickupFromTime.split(":");
      const toSplit = pickupToTime.split(":");
      const [startHour, startMinute] = fromSplit;
      const [endHour, endMinute] = toSplit;
      const hoursRange = {
        endHour: parseInt(endHour, 10),
        endMinute: parseInt(endMinute, 10),
        startHour: parseInt(startHour, 10),
        startMinute: parseInt(startMinute, 10),
      };
      // for orders with pickupFromTime and pickupToTimes we only use this range for opening hours
      const pickupHours = {
        open: {
          1: [hoursRange],
          2: [hoursRange],
          3: [hoursRange],
          4: [hoursRange],
          5: [hoursRange],
          6: [hoursRange],
          7: [hoursRange],
        },
        timeZone: "Europe/Berlin",
      };

      orderTimes = orderTimesSelector(pickupHours, now);
    } else {
      orderTimes = orderTimesSelector(venue.openingHours, currentTime);
    }
  } catch (error) {
    // added a catch clause just in case hobbyconfig for dish availibility is malformated
    orderTimes = orderTimesSelector(venue.openingHours, currentTime);
  }

  let { orderTime } = order;
  const initialTime = moment().tz(venue.openingHours.timeZone);
  if (order.location === VENUE_LOCATION_DELIVERY) {
    initialTime.add(15, "minutes");
  }
  if (orderTime) {
    initialTime.set({
      hour: parseInt(`${orderTime[0]}${orderTime[1]}`, 10),
      minute: parseInt(`${orderTime[2]}${orderTime[3]}`, 10),
      second: 0,
      millisecond: 0,
    });
  }
  if (
    moment
      .unix(currentTime)
      .tz(venue.openingHours.timeZone)
      .isAfter(initialTime, "minutes")
  ) {
    orderTime = orderTimes[0];
    asapNotAvailable = true;
  }
  // else if (
  //   moment
  //     .unix(currentTime)
  //     .tz(venue.openingHours.timeZone)
  //     .isAfter(moment().tz(venue.openingHours.timeZone))
  // ) {
  //   orderTime = orderTimes[0];
  //   asapNotAvailable = true;
  // } else {
  //   asapNotAvailable = false;
  // }

  return { orderTimes, orderTime, asapNotAvailable };
};

const buildOrderLocationsConfig = (venue) => {
  // get from venue if you want to controll user data collect per venue choice (same for dine-in)
  const sendInvoiceTOGO = true;
  const isAllowedTOGO =
    venue.toGo !== undefined &&
    (venue.toGo.disabled === undefined || venue.toGo.disabled === false);

  // if (isAllowedTOGO) {
  //   sendInvoiceTOGO = venue.toGo.invoicingEmailRequired;
  // }

  const isAllowedDelivery =
    venue.toDelivery !== undefined &&
    venue.deliveryEnabled &&
    (venue.offersDelivery || venue.offersDeliveryBeta) &&
    (venue.toDelivery.disabled === undefined ||
      venue.toDelivery.disabled === false);

  let isAllowedPreOrderForDelivery = true;

  if (isAllowedDelivery) {
    isAllowedPreOrderForDelivery = venue.toDelivery.allowPreorder;
  }

  const sendInvoiceDINEIN = true;
  const isAllowedDINEIN =
    venue.eatIn !== undefined &&
    (venue.eatIn.disabled === false || venue.eatIn.disabled === undefined);

  let isAllowedPreOrderForEatIn = true;
  if (isAllowedDINEIN) {
    // sendInvoiceDINEIN = venue.eatIn.invoicingEmailRequired;
    isAllowedPreOrderForEatIn = venue.eatIn.allowPreorder;
  }

  return {
    sendInvoiceTOGO,
    isAllowedPreOrderForDelivery,
    isAllowedDelivery,
    isAllowedTOGO,
    sendInvoiceDINEIN,
    isAllowedDINEIN,
    isAllowedPreOrderForEatIn,
  };
};

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { venueId },
    },
  } = ownProps;

  const {
    venues: {
      data: { [venueId]: venue },
    },
    order,
    auth: {
      user: authUser,
      syncFinished: authSyncFinished,
      requests: {
        login: { processing: loginProcessing },
      },
    },
    user,
  } = state;

  // localization and strings
  const locale = getUsableLocale();
  const venueStrings = venueStringsSelector(venue, locale);
  // in the case of english string doesn't exist for specific item
  const venueStringsDEFallover = venueStringsSelector(
    venue,
    venue.defaultLocale || "de"
  );
  for (let index = 0; index < order.items.length; index++) {
    const element = order.items[index];
    if (!venueStrings[element.itemId]) {
      venueStrings[element.itemId] = venueStringsDEFallover[element.itemId];
    }
  }

  const { totalPrice, remarks, sectionResults } = groupedItemsSelector(
    venue,
    venueStrings,
    order
  );

  const currentPaymentMethodInternal = user.currentPaymentMethod
    ? user.paymentMethods.find(
        (paymentMethod) => paymentMethod.id === user.currentPaymentMethod
      )
    : null;

  const minimumBasketNotReached = minimumBasketReached(
    order.location,
    order.items,
    venue && venue.menu && venue.menu.items,
    venue,
    get(order, ["preparedOrder", "minimumBasketPrice"], null)
  );
  const minimumBasket = minimumBasketAmount(
    order.location,
    venue,
    get(order, ["preparedOrder", "minimumBasketPrice"], null)
  );
  const {
    sendInvoiceTOGO,
    isAllowedPreOrderForDelivery,
    isAllowedDelivery,
    isAllowedTOGO,
    sendInvoiceDINEIN,
    isAllowedDINEIN,
    isAllowedPreOrderForEatIn,
  } = buildOrderLocationsConfig(venue);

  const { orderTime, orderTimes, asapNotAvailable } = buildOrderTimes(
    venue,
    order
  );

  let paymentOptions = !!venue && venue.paymentOptions;
  if (!paymentOptions || paymentOptions.length <= 0) {
    paymentOptions = paymentOptionsDefault;
  }
  const currentSelectedPaymentMethodInternalMetaData =
    currentPaymentMethodInternal
      ? paymentOptions.find(
          (paymentOption) =>
            paymentOption.id === currentPaymentMethodInternal.type
        )
      : null;
  const tipAllowed =
    currentPaymentMethodInternal &&
    currentSelectedPaymentMethodInternalMetaData.tipAllowed !== false;
  return {
    venueId,
    // final items and benefits
    groupedItems: sectionResults,
    benefits: order.feesBenefits,
    orderTimes,
    // client-side sub total
    subTotal: totalPrice,
    currency: venue.currency,
    // stripe config country origin
    countryCode: get(venue, "countryCode", "DE") || "DE",
    remarks,
    tableId: order.tableId,
    table: order.preparedOrder && order.preparedOrder.table,
    location: order.location,
    userName: order.userName,
    userEmail: order.userEmail,
    phoneNumber: order.phoneNumber,
    orderTime,
    sendInvoiceTOGO,
    sendInvoiceDINEIN,
    // delivery restrictions
    allowedTOGO: isAllowedTOGO && !order.tableId,
    allowedDINEIN: isAllowedDINEIN,
    allowedDelivery: isAllowedDelivery && !order.tableId,
    allowedPreOrderForEatIn: isAllowedPreOrderForEatIn && !order.tableId,
    allowedPreOrderForDelivery: isAllowedPreOrderForDelivery,
    deliveryAddress: order.deliveryAddress,
    deliveryStreetName: order.deliveryStreetName,
    deliveryStreetNumber: order.deliveryStreetNumber,
    deliveryCity: order.deliveryCity,
    deliveryPostalCode: order.deliveryPostalCode,
    deliveryAdditionalInformation: order.deliveryAdditionalInformation,
    // additional strings
    locale,
    venueName: venueStrings[venueId],
    // auth for wiring up with paypal
    authUser,
    authSyncFinished,
    loginProcessing,
    // current payment associated with this user
    currentPaymentMethod: currentPaymentMethodInternal,
    // test to see if we need to display the terms notice
    hasSeenTermsNotice: user.hasSeenTermsNotice,
    // prepare order state
    prepareOrderProcessing: order.requests.prepareOrder.processing,
    prepareOrderError: order.requests.prepareOrder.error,
    preparedOrder: order.preparedOrder,
    // execute order state
    executeOrderProcessing: order.requests.executeOrder.processing,
    // tip
    tip: order.tip,
    // add creditcard processing
    addCreditCardProcessing: user.requests.addCreditCard.processing,
    addPaymentMethodProcessing: user.requests.addPaymentOption.processing,
    asapNotAvailable,
    minimumBasketNotReached,
    minimumBasket,
    paymentOptions,
    tipAllowed,
    paymentRequest: user.paymentRequest,
    label: get(order, ["preparedOrder", "paypalShortDescription"], "Total"),
    deliveryFee: get(order, ["preparedOrder", "deliveryFee"], null),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    match: {
      params: { venueId },
    },
    history,
  } = ownProps;

  return {
    login: () => dispatch(login()),
    createUser: () => dispatch(createUser()),
    prepareOrder: () => dispatch(prepareOrder(venueId)),
    executeOrder: (notifyPromise) => dispatch(executeOrder(notifyPromise)),
    clearOrder: () => dispatch(clearOrder()),
    clearPreparedOrder: () => dispatch(clearPreparedOrder()),
    navigate: (to) => history.push(to),
    setLocationOption: (location) => dispatch(setLocationOption(location)),
    setDeliveryAddress: (deliveryAddress) =>
      dispatch(setDeliveryAddress(deliveryAddress)),
    setOrderTime: (orderTime) => dispatch(setOrderTime(orderTime)),
    setUserName: (userName) => dispatch(setUserName(userName)),
    setUserEmail: (userEmail) => dispatch(setUserEmail(userEmail)),
    setPhoneNumber: (phoneNumber) => dispatch(setPhoneNumber(phoneNumber)),
    setGooglePayToken: (googlePayTokenId) =>
      dispatch(setGooglePayToken(googlePayTokenId)),
    setApplePayToken: (applePayTokenId) =>
      dispatch(setApplePayToken(applePayTokenId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
