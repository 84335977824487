import { useEffect } from "react";

export default function HomeRedirect({ location }) {
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const venueId = searchParams.get("venueId");
    const qr = searchParams.get("~channel");
    if (venueId) {
      window.location.replace(
        qr ? `./venues/${venueId}/menu?qr=${qr}` : `./venues/${venueId}/menu`
      );
    } else {
      window.location.replace("https://www.orda-app.com");
    }
  }, []);
  return null;
}
