import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import OrderLocationSwitcherBar from "../components/OrderLocationSwitcherBar";
import { setLocationOption } from "../redux/actions/order";

const buildOrderLocationsConfig = (venue, tableId) => {
  const isAllowedTOGO =
    venue.toGo !== undefined &&
    (venue.toGo.disabled === undefined || venue.toGo.disabled === false);
  const isAllowedDelivery =
    venue.toDelivery !== undefined &&
    venue.deliveryEnabled &&
    (venue.offersDelivery || venue.offersDeliveryBeta) &&
    (venue.toDelivery.disabled === undefined ||
      venue.toDelivery.disabled === false);

  const isAllowedDINEIN =
    venue.eatIn !== undefined &&
    (venue.eatIn.disabled === false || venue.eatIn.disabled === undefined);

  return {
    isAllowedDelivery: isAllowedDelivery && !tableId,
    isAllowedTOGO: isAllowedTOGO && !tableId,
    isAllowedDINEIN,
  };
};

const mapStateToProps = () => {
  return (state, ownProps) => {
    const {
      match: {
        params: { venueId },
      },
    } = ownProps;

    const {
      venues: {
        data: { [venueId]: venue },
      },
      order: { location, tableId, deliveryStreetName, deliveryStreetNumber },
    } = state;

    const { isAllowedDelivery, isAllowedTOGO, isAllowedDINEIN } =
      buildOrderLocationsConfig(venue, tableId);

    return {
      orderLocations: { isAllowedDelivery, isAllowedTOGO, isAllowedDINEIN },
      location,
      addressEmpty: !deliveryStreetName && !deliveryStreetNumber,
      eatIn: venue.eatIn,
      tableId,
    };
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLocationOption: (location) => dispatch(setLocationOption(location)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderLocationSwitcherBar)
);
